//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { coincelebritycontentSave } from '@/api/celebrity/celebrity'
import { cmsCoinCelebrityFollowQueryPage } from '@/api/celebrity/celebrity';
import { parseTime } from '@/utils';
export default {
  name: 'FollowFamous',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        coinCelebrityId: '',
        status: 1 // keywords: ''

      },
      setting: {
        // 弹窗
        title: '选择名人',
        visible: false
      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      coincelebrityData: [] // 选中数据

    };
  },
  computed: {
    topicCbox: function topicCbox() {
      if (this.listData) {
        return this.listData.list.filter(function (i) {
          return i.checkbox;
        });
      }

      return [];
    }
  },
  mounted: function mounted() {
    // this.getList()
    this.coincelebrityRadio = '';
  },
  methods: {
    parseTime: parseTime,
    // table 选中回调
    handleRowClick: function handleRowClick(row, column, event) {
      this.coincelebrityData = row;
    },
    handerTopicBox: function handerTopicBox(val, index) {
      if (this.topicCbox.length > 2 && !this.listData.list[index].checkbox) {
        this.$message.error('最多只能选三个！');
        return;
      }

      this.listData.list[index].checkbox = !this.listData.list[index].checkbox;
      this.$set(this.listData.list, index, this.listData.list[index]); // 更新dom
      // this.$forceUpdate()
    },
    settingEvent: function settingEvent() {
      this.$emit('handleTopicList', this.topicCbox);
      this.handleClose();
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false;
      this.coincelebrityData = []; // 调用父组件 刷新页面
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    // 列表
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      cmsCoinCelebrityFollowQueryPage(this.tableFrom).then(function (res) {
        _this.listData.list = res.list;
        _this.listData.total = res.totalCount;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.coincelebrityRadio = '';
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};