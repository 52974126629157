var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "150px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图名称:", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入轮播图名称" },
                        model: {
                          value: _vm.formModal.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "title", $$v)
                          },
                          expression: "formModal.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图位置", prop: "apId" } },
                    [
                      _c(
                        "div",
                        { staticClass: "right_list" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请输入轮播图位置" },
                              on: { change: _vm.updapId },
                              model: {
                                value: _vm.formModal.apId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formModal, "apId", $$v)
                                },
                                expression: "formModal.apId",
                              },
                            },
                            _vm._l(_vm.comeFrom, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-link",
                            { attrs: { type: "danger", disabled: "" } },
                            [_vm._v(_vm._s(_vm.comeFromcheck))]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片", prop: "url" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "http-request": _vm.handleUploadForm,
                            "on-change": _vm.imgSaveToUrl,
                            headers: _vm.myHeaders,
                            "show-file-list": false,
                            multiple: "",
                          },
                        },
                        [
                          _vm.formModal.url
                            ? _c("div", { staticClass: "avatar" }, [
                                _c("img", {
                                  attrs: { src: _vm.formModal.url },
                                }),
                              ])
                            : _c("div", { staticClass: "upload" }, [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                                _c("span", [_vm._v("图片地址组合")]),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图链接" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入跳转链接地址" },
                        model: {
                          value: _vm.formModal.resUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "resUrl", $$v)
                          },
                          expression: "formModal.resUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图链接_android" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入跳转链接地址" },
                        model: {
                          value: _vm.formModal.resUrlAndroid,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "resUrlAndroid", $$v)
                          },
                          expression: "formModal.resUrlAndroid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图链接_ios" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入跳转链接地址" },
                        model: {
                          value: _vm.formModal.resUrlIos,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "resUrlIos", $$v)
                          },
                          expression: "formModal.resUrlIos",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图图标" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入轮播图图标" },
                        model: {
                          value: _vm.formModal.icon,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "icon", $$v)
                          },
                          expression: "formModal.icon",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图背景颜色" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入轮播图背景颜色" },
                        model: {
                          value: _vm.formModal.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "color", $$v)
                          },
                          expression: "formModal.color",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "轮播图字体颜色" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入轮播图字体颜色" },
                        model: {
                          value: _vm.formModal.colorText,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "colorText", $$v)
                          },
                          expression: "formModal.colorText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "位置排序", prop: "sort" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入排序" },
                        model: {
                          value: _vm.formModal.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "sort", $$v)
                          },
                          expression: "formModal.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入备注" },
                        model: {
                          value: _vm.formModal.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "remark", $$v)
                          },
                          expression: "formModal.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "跳转类型", prop: "" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formModal.resUrlType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "resUrlType", $$v)
                            },
                            expression: "formModal.resUrlType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("应用跳转"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("外部跳转"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "语言", prop: "" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.formModal.language,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "language", $$v)
                            },
                            expression: "formModal.language",
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "cn", name: "cn" } },
                            [_vm._v("简体")]
                          ),
                          _c(
                            "el-checkbox",
                            { attrs: { label: "tn", name: "tn" } },
                            [_vm._v("繁体")]
                          ),
                          _c(
                            "el-checkbox",
                            { attrs: { label: "en", name: "en" } },
                            [_vm._v("英文")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "isShow" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.formModal.isShow,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "isShow", $$v)
                            },
                            expression: "formModal.isShow",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.formModal.isShow,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "isShow", $$v)
                            },
                            expression: "formModal.isShow",
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }