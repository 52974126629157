//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { coincelebritycontentSave } from '@/api/celebrity/celebrity'
import { fileImageApi } from '@/api/systemSetting';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    return {
      celebrityLink: '',
      // 链接
      celebrityText: '',
      // 文本
      formDisable: false,
      image: '',
      url: '',
      name: '',
      setting: {
        // 弹窗
        title: '添加链接',
        visible: false
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    this.image = '';
    this.url = '';
    this.name = '';
  },
  methods: {
    settingEvent: function settingEvent() {
      if (!this.url || !this.image || !this.name) {
        this.$message.error('链接地址,标签名称或图片不能为空!');
        return;
      }

      var par = {
        image: this.image,
        name: this.name,
        url: this.url
      };
      this.$emit('handleLink', par);
      this.setting.visible = false;
    },
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      console.log(formData, param.file, 'formData');
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this.$message.success('上传成功');

        _this.image = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false;
      this.coincelebrityRadio = ''; // 调用父组件 刷新页面
    }
  }
};