import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
Vue.use(Vuex);
var modules = {};

var files = require.context('./modules', false, /\.(js|ts)$/);

files.keys().forEach(function (key) {
  var name = key.substring(key.lastIndexOf('/') + 1, key.length - 3);
  modules[name] = files(key).default;
});
export default new Vuex.Store({
  state: {},
  getters: getters,
  mutations: {},
  actions: {},
  modules: modules
});