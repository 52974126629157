//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { appVersionQueryPage, appVersionDeleteByIds, appVersionGetById } from '@/api/version';
import addDynamic from './components/addDynamic';
import { parseTime } from '@/utils';
export default {
  name: 'Version',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      value: '',
      input: '',
      comeFrom: [],
      tableFrom: {
        page: 1,
        limit: 20,
        version: '' // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: ''
    };
  },
  mounted: function mounted() {
    // this.spiderSourceList()
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    handleOpen: function handleOpen(row) {
      var _this = this;

      this.$confirm("\u786E\u8BA4".concat(row.isShow ? '关闭' : '开启', "\u5F53\u524D\u5FEB\u8BAF"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.setSave(row);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.isShow ? '开启' : '关闭')
        });
      });
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.version = '';
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      this.tableSelection = row;
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    handleCut: function handleCut(row, key, value) {
      var _this2 = this;

      this.$confirm("\u786E\u8BA4".concat(row[key] ? '关闭' : '开启', "\u5F53\u524D").concat(value), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.setSave(row, key);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row[key] ? '开启' : '关闭')
        });
      });
    },
    setSave: function setSave(row, key) {
      var _this3 = this;

      row[key] = row[key] ^ 1;
      appVersionGetById(row).then(function (res) {
        _this3.$message.success('修改成功');
      }).catch(function (res) {
        row[key] = row[key] ^ 1;

        _this3.$message.error('修改失败');
      });
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.id) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        this.$refs.addDynamic.formModal = par;
        this.$refs.addDynamic.setting.title = '编辑版本';
      } else {
        // 重置弹窗
        this.$refs.addDynamic.formModal = {
          id: '',
          nickname: '',
          // 昵称
          sourceId: '',
          // 来源
          outId: '',
          // 外部Id
          header: '',
          // 头像
          mobile: '',
          // 手机号
          countryCode: '',
          // 手机号区号
          email: '',
          // 邮箱
          sign: '',
          // 个性签名
          likeNumActual: '',
          // 虚拟点赞量
          discussNumActual: '',
          // 虚拟评论量
          forwardNumActual: '',
          // 虚拟转发量
          visitNumActual: '',
          // 虚拟浏览量
          virtualFlag: 0,
          // 虚拟用户
          memberType: 1,
          // 用户类型 默认普通用户
          regType: 0,
          // 注册类型 默认手机注册
          whiteEnable: false,
          // 是否白名单
          hotEnable: 0 // 是否热门

        };
        this.$refs.addDynamic.setting.title = '添加快讯';
      }

      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this4 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      appVersionDeleteByIds(par.ids).then(function (res) {
        _this4.$message.success('删除成功');

        _this4.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this5 = this;

      this.listLoading = true;

      if (this.tableFrom.formatDate) {
        this.tableFrom.formatDate = parseTime(this.tableFrom.formatDate, '{y}-{m}-{d}');
      }

      appVersionQueryPage(this.tableFrom).then(function (res) {
        _this5.listData.list = res.list;
        _this5.listData.total = res.totalCount;
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    // spiderSourceList() {
    //   spiderSourceQueryList({ salfFalg: 1 })
    //     .then((res) => {
    //       this.comeFrom = res
    //     })
    //     .catch(() => {})
    // },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};