import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import axios from 'axios';
import store from '@/store'; // import { getToken } from '@/utils/auth'

import SettingMer from '@/utils/settingMer';
import { isPhone } from '@/libs/wechat';
import { checkString } from '@/utils/string'; // create an axios instance
// eslint-disable-next-line no-undef

var service = axios.create({
  baseURL: SettingMer.apiBaseURL,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // 过期时间

}); // 检定url的变量

var isUrl = null; // 白名单

var dealRequestList = ['updateById', // 更新
'save' // 保存
]; // request interceptor

service.interceptors.request.use(function (config) {
  // 发送请求之前做的
  var token = !store.getters.token ? sessionStorage.getItem('token') : store.getters.token;

  if (token) {
    config.headers['Authorization'] = token;
  } // updateById save 更新 保存接口统一调用loading
  // 判断是否需处理路径


  isUrl = checkString(config.url, '', SettingMer.apiBaseURL);

  if (dealRequestList.find(function (r) {
    return isUrl.indexOf(r) > -1;
  })) {
    store.state.app.loadding = true;
  } else {
    store.state.app.loadding = false;
  }

  if (/get/i.test(config.method)) {
    config.params = config.params || {}; // config.params.temp = Date.parse(new Date()) / 1000
  }

  return config;
}, function (error) {
  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data; // if (store.state.app.loadding) {
  //   debugger
  // }

  store.state.app.loadding = false; // if the custom code is not 20000, it is judged as an error.
  // if the custom code is not 20000, it is judged as an error.

  if (res.code === 401) {
    // to re-login
    var str = '';
    str = res.code === 401 ? '您已经登出，请重新登录' : '无权限，请重新登录';

    _MessageBox.alert(str, '退出', {
      confirmButtonText: '重新登录',
      type: 'warning'
    }).then(function () {
      store.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    });
  }

  if (res instanceof Blob || response.config.url === '/sys/ueditor/todo' || response.config.url === '/sys/menu/list') {
    // 如果返回的是文件流的形式，直接return res
    return res;
  } else if (res.code !== 200 && res.code !== 401) {
    if (isPhone()) {
      // 移动端
      return Promise.reject(res || 'Error');
    } // 请求白名单
    // const xls = '/memberLoginLog/export'
    // if (response.config.url === xls) {
    //   return res
    // }


    _Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    });

    return Promise.reject(); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // return Promise.reject(new Error(res.message || 'Error'))
  } else {
    return res.data;
  }
}, function (error) {
  store.state.app.loadding = false;
  console.log('err' + error); // for debug

  _Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });

  return Promise.reject(error);
});
export default service;