function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

// import WechatJSSDK from 'wechat-jssdk/dist/client.umd'
// import { getWechatConfig, wechatAuth } from '@/api/wxApi'
import { getToken, setToken } from '@/utils/auth'; // import { parseQuery } from '@/utils'

import Cookies from 'js-cookie'; // const STATE_KEY = 'wx_authorize_state'

import store from '@/store';
var WX_AUTH = 'wx_auth'; // const BACK_URL = 'login_back_url'
// const LOGINTYPE = 'loginType'

var instance;
var wechatObj; // const LONGITUDE = 'user_longitude'
// const LATITUDE = 'user_latitude'
// const WECHAT_SCRIPT_URL = '//res.wx.qq.com/open/js/jweixin-1.6.0.js'

/**
 * 是否是微信
 */

export function isWeixin() {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
}
/**
 * 是否是移动端
 */

export function isPhone() {
  return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);
}
export default function wechat() {
  return new Promise(function (resolve, reject) {
    if (instance) return resolve(instance); // eslint-disable-next-line no-undef

    getWechatConfig().then(function (res) {
      // eslint-disable-next-line no-undef
      var _wx = WechatJSSDK(res);

      wechatObj = _wx;

      _wx.initialize().then(function () {
        instance = _wx.wx;
        instance.initConfig = res;
        resolve(instance);
      }).catch(reject);
    }).catch(function (err) {
      reject(err);
    });
  });
}
export function loginByWxCode(code) {
  return new Promise(function (resolve, reject) {
    // eslint-disable-next-line no-undef
    wechatAuth(code).then(function (res) {
      store.commit('SET_TOKEN', res.token);
      setToken(res.token);
      Cookies.set(WX_AUTH, code);
      resolve(res);
    }).catch(function (err) {
      reject(err);
    });
  });
}
export function getWXCodeByUrl(path, step) {
  if (getToken()) return;
  generatorWxUrl(path, step);
}
export function generatorWxUrl(path, step) {
  wechat().then(function (wx) {
    window.location.href = getAuthUrl(wx.initConfig, path, step);
  });
}

function getAuthUrl(config, path, step) {
  var finalUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=".concat(config.appId, "&redirect_uri=").concat(encodeURIComponent(path), "&response_type=code&scope=snsapi_base&state=").concat(step, "#wechat_redirect");
  return finalUrl;
}
/**
 * 公众号事件
 * @param name 事件名
 * @param config 配置
 * @returns {Promise<unknown>}
 */


export function wechatEvevt(name, config) {
  return new Promise(function (resolve, reject) {
    var wx;
    var configDefault = {
      fail: function fail() {
        if (wx) return reject({
          is_ready: true,
          wx: wx
        }); // eslint-disable-next-line no-undef

        getWechatConfig().then(function (res) {
          wechatObj.signSignature({
            nonceStr: res.nonceStr,
            signature: res.signature,
            timestamp: res.timestamp
          });
          wx = wechatObj.getOriginalWx();
          reject({
            is_ready: true,
            wx: wx
          });
        });
      },
      success: function success(res) {
        resolve(res);
      },
      cancel: function cancel(err) {
        reject(err);
      },
      complete: function complete(err) {
        reject(err);
      }
    };
    Object.assign(configDefault, config); // eslint-disable-next-line no-undef

    getWechatConfig().then(function (res) {
      // eslint-disable-next-line no-undef
      var _wx = WechatJSSDK(res);

      _wx.initialize().then(function () {
        instance = _wx.getOriginalWx();
        instance.ready(function () {
          if (_typeof(name) === 'object') {
            name.forEach(function (item) {
              instance[item] && instance[item](configDefault);
            });
          } else instance[name] && instance[name](configDefault);
        });
      });
    });
  });
}
export function ready() {
  return new Promise(function (resolve) {
    if (typeof instance !== 'undefined') {
      instance.ready(function () {
        resolve(instance);
      });
    } else {
      // eslint-disable-next-line no-undef
      getWechatConfig().then(function (res) {
        // eslint-disable-next-line no-undef
        var _wx = WechatJSSDK(res);

        _wx.initialize().then(function () {
          instance = _wx.wx;
          instance.ready(function () {
            resolve(instance);
          });
        });
      });
    }
  });
}