var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "famous" },
    [
      _c(
        "div",
        { staticClass: "famous_content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.listData.list,
                size: "mini",
                "max-height": "500",
                "highlight-current-row": "",
                "tooltip-effect": "dark",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", { attrs: { type: "index" } }),
              _c("el-table-column", {
                attrs: {
                  prop: "activityCode",
                  label: "活动code",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "activityName", label: "活动名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.freezeTime
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getformid(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v(" 活动奖励 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 20, 30, 40],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.listData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "活动奖励列表",
            visible: _vm.dialogIntegral,
            width: "70%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogIntegral = $event
            },
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.addactivity()
                },
              },
            },
            [_vm._v("新增奖励")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger" },
              on: {
                click: function ($event) {
                  return _vm.delgridData()
                },
              },
            },
            [_vm._v("删除")]
          ),
          _c(
            "el-table",
            {
              attrs: { data: _vm.gridData, "max-height": "450" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { property: "title", label: "奖励名称" },
              }),
              _c("el-table-column", {
                attrs: { property: "amount", label: "奖励数额" },
              }),
              _c("el-table-column", {
                attrs: { property: "amountType", label: "奖励类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.amountType == 1
                          ? _c("div", [_vm._v("USDT")])
                          : _vm._e(),
                        scope.row.amountType == 2
                          ? _c("div", [_vm._v("内容奖励")])
                          : _vm._e(),
                        scope.row.amountType == 3
                          ? _c("div", [_vm._v("积分")])
                          : _vm._e(),
                        scope.row.amountType == 999
                          ? _c("div", [_vm._v("谢谢参与")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { property: "lotteryCount", label: "已中奖数量" },
              }),
              _c("el-table-column", {
                attrs: { property: "maxCount", label: "奖励最大数量" },
              }),
              _c("el-table-column", {
                attrs: { property: "pic", label: "奖励图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "photo",
                          attrs: {
                            src: scope.row.pic,
                            "preview-src-list": [scope.row.pic],
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "是否弹窗" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.popUpFlag == 0
                          ? _c("div", [_vm._v("否")])
                          : _vm._e(),
                        scope.row.popUpFlag == 1
                          ? _c("div", [_vm._v("是")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { property: "remark", label: "奖励描述" },
              }),
              _c("el-table-column", {
                attrs: { property: "sort", label: "排序" },
              }),
              _c("el-table-column", {
                attrs: { property: "weight", label: "权重(中奖率)" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.freezeTime
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updgridData(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 20, 30, 40],
                  "page-size": _vm.Integraldata.limit,
                  "current-page": _vm.Integraldata.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.Integraldata.total,
                },
                on: {
                  "size-change": _vm.IntegralSizeChange,
                  "current-change": _vm.IntegralpageChange,
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogIntegral = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "新增奖励", visible: _vm.dialogTableVisible2 },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.addform, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖励名称" } },
                [
                  _c("el-input", {
                    attrs: { label: "请输入奖励名称" },
                    model: {
                      value: _vm.addform.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "title", $$v)
                      },
                      expression: "addform.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "奖励类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择注册类型" },
                      model: {
                        value: _vm.addform.amountType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "amountType", $$v)
                        },
                        expression: "addform.amountType",
                      },
                    },
                    _vm._l(_vm.amountTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "奖励金额单位" } },
                [
                  _c("el-input", {
                    attrs: { label: "请输入奖励金额单位" },
                    model: {
                      value: _vm.addform.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "unit", $$v)
                      },
                      expression: "addform.unit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "奖励数额" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, label: "请输入奖励数额" },
                    model: {
                      value: _vm.addform.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "amount", $$v)
                      },
                      expression: "addform.amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "最大奖励数量" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, label: "请输入最大奖励数量" },
                    model: {
                      value: _vm.addform.maxCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "maxCount", $$v)
                      },
                      expression: "addform.maxCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "已中奖数量" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 0, label: "请输入已中奖数量" },
                    model: {
                      value: _vm.addform.lotteryCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "lotteryCount", $$v)
                      },
                      expression: "addform.lotteryCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权重(中奖率)" } },
                [
                  _c("el-input-number", {
                    attrs: { label: "请输入权重(中奖率)" },
                    model: {
                      value: _vm.addform.weight,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "weight", $$v)
                      },
                      expression: "addform.weight",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    attrs: { label: "请输入排序" },
                    model: {
                      value: _vm.addform.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "sort", $$v)
                      },
                      expression: "addform.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动奖励规则满足条件类型ID" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "全部" },
                      model: {
                        value: _vm.addform.conditionTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "conditionTypeId", $$v)
                        },
                        expression: "addform.conditionTypeId",
                      },
                    },
                    _vm._l(_vm.conditionTypeIdList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "满足条件值" } },
                [
                  _c("el-input-number", {
                    attrs: { label: "请输入满足条件值" },
                    model: {
                      value: _vm.addform.conditionValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "conditionValue", $$v)
                      },
                      expression: "addform.conditionValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否弹窗" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.addform.popUpFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "popUpFlag", $$v)
                        },
                        expression: "addform.popUpFlag",
                      },
                    },
                    [_vm._v("否")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.addform.popUpFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "popUpFlag", $$v)
                        },
                        expression: "addform.popUpFlag",
                      },
                    },
                    [_vm._v("是")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否每日任务" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.addform.dayFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "dayFlag", $$v)
                        },
                        expression: "addform.dayFlag",
                      },
                    },
                    [_vm._v("否")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.addform.dayFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "dayFlag", $$v)
                        },
                        expression: "addform.dayFlag",
                      },
                    },
                    [_vm._v("是")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否循环" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.addform.circulateFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "circulateFlag", $$v)
                        },
                        expression: "addform.circulateFlag",
                      },
                    },
                    [_vm._v("否")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.addform.circulateFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "circulateFlag", $$v)
                        },
                        expression: "addform.circulateFlag",
                      },
                    },
                    [_vm._v("是")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        "http-request": _vm.handleUploadForm2,
                        "on-change": _vm.imgSaveToUrl,
                        headers: _vm.myHeaders,
                        "show-file-list": false,
                        multiple: "",
                      },
                    },
                    [
                      _vm.addform.pic
                        ? _c("div", { staticClass: "avatar" }, [
                            _c("img", { attrs: { src: _vm.addform.pic } }),
                          ])
                        : _c("div", { staticClass: "upload" }, [
                            _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                            _c("span", [_vm._v("图片地址组合")]),
                          ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "奖励描述" } },
                [
                  _c("el-input", {
                    attrs: { label: "请输入奖励描述" },
                    model: {
                      value: _vm.addform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "remark", $$v)
                      },
                      expression: "addform.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogTableVisible2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addformsave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑奖励", visible: _vm.dialogTableVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "最大奖励数量" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, label: "请输入最大奖励数量" },
                    model: {
                      value: _vm.form.maxCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "maxCount", $$v)
                      },
                      expression: "form.maxCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权重(中奖率)" } },
                [
                  _c("el-input-number", {
                    attrs: { label: "请输入最大权重" },
                    model: {
                      value: _vm.form.weight,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "weight", $$v)
                      },
                      expression: "form.weight",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序" } },
                [
                  _c("el-input-number", {
                    attrs: { label: "请输入排序" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        "http-request": _vm.handleUploadForm,
                        "on-change": _vm.imgSaveToUrl,
                        headers: _vm.myHeaders,
                        "show-file-list": false,
                        multiple: "",
                      },
                    },
                    [
                      _vm.form.pic
                        ? _c("div", { staticClass: "avatar" }, [
                            _c("img", { attrs: { src: _vm.form.pic } }),
                          ])
                        : _c("div", { staticClass: "upload" }, [
                            _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                            _c("span", [_vm._v("图片地址组合")]),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogTableVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addIntegralDia },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }