//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { labelqueryPage, labelupdateById, labelsave } from '@/api/member/member_zh';
import { parseTime } from '@/utils';
export default {
  name: 'UserControl',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20
      },
      listData: {
        list: [],
        total: 0
      },
      //标签弹窗
      dialogIntegral: false,
      diaTitle: "标签新增",
      type: '',
      label: {
        id: '',
        labelName: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    getlableid: function getlableid(data) {
      this.label = data;
      this.type = 'upd';
      this.dialogIntegral = true;
    },
    updLabel: function updLabel() {
      var _this = this;

      if (this.type == 'upd') {
        labelupdateById(this.label).then(function (res) {
          _this.$message({
            message: '修改成功',
            type: 'success'
          });

          _this.dialogIntegral = false;
          _this.label = {
            id: '',
            labelName: ''
          };

          _this.getList();
        });
      }

      if (this.type == 'add') {
        labelsave(this.label).then(function (res) {
          _this.$message({
            message: '新增成功',
            type: 'success'
          });

          _this.dialogIntegral = false;
          _this.label = {
            id: '',
            labelName: ''
          };

          _this.getList();
        });
      }
    },
    showdio: function showdio() {
      this.label = {
        id: '',
        labelName: ''
      };
      this.type = 'add';
      this.dialogIntegral = true;
    },
    getList: function getList() {
      var _this2 = this;

      labelqueryPage(this.tableFrom).then(function (res) {
        _this2.listData.list = res.list;
        _this2.listData.total = res.totalCount;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    }
  }
};