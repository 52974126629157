function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/**
 * @file 权限相关
 */
import store from '@/store';
import { isEmpty } from './types';
/**
 * 判断是否拥有权限
 * @param {string | Array} ruleAilas 权限别名或权限别名列表
 * @param { boolean } exactlyMatch 权限列表是否完全匹配
 * @returns {boolean} 是否拥有权限
 */

export var hasRule = function hasRule(ruleAilas) {
  var exactlyMatch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (isEmpty(ruleAilas) || !ruleAilas.length) {
    return false;
  } // 获取权限列表


  var ruleList = store.getters['ruleList'] || [];

  if (ruleAilas instanceof Array) {
    var len = Array.from(new Set([].concat(_toConsumableArray(ruleAilas), _toConsumableArray(ruleList)))).length;
    if (exactlyMatch) return len === ruleList.length;
    return len !== ruleList.length + ruleAilas.length;
  }

  return ruleList.includes(ruleAilas);
};