//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { communityTopicQueryPage, communityTopicDeleteByIds, communityTopicSave } from '@/api/community/community';
import addDynamic from './components/addDynamic';
import { parseTime } from '@/utils';
export default {
  name: 'DynamicMen',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        hotEnable: '',
        title: '' // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerScreen: function handlerScreen() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.hotEnable = '';
      this.tableFrom.title = '';
      this.getList();
    },
    handleOpen: function handleOpen(row) {
      var _this = this;

      this.$confirm("\u786E\u8BA4".concat(row.hotEnable ? '关闭' : '开启', "\u5F53\u524D\u70ED\u95E8\u52A8\u6001"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.setSave(row);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.hotEnable ? '开启' : '关闭')
        });
      });
    },
    setSave: function setSave(row) {
      var _this2 = this;

      row.hotEnable = !row.hotEnable;
      communityTopicSave(row).then(function (res) {
        _this2.$message.success('修改成功');
      }).catch(function (res) {
        row.hotEnable = !row.hotEnable;

        _this2.$message.error('修改失败');
      });
    },
    handlerState: function handlerState() {
      this.$message.error('暂未开发!');
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.id) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        this.$refs.addDynamic.formModal = par;
        this.$refs.addDynamic.setting.title = '编辑话题';
      } else {
        this.$refs.addDynamic.formModal = {
          id: '',
          title: '',
          // 话题
          sourceType: '',
          // 来自
          pic: '',
          // 头像
          des: '',
          // 内容
          visitNum: 0,
          // 总获赞量
          discussNum: '',
          // 总评论量
          forwardNum: '',
          // 总被分享量
          keyword: '',
          // 关键字
          sort: null,
          // 排序
          hotEnable: true // 状态

        };
      }

      this.$refs.addDynamic.setting.visible = true;
      this.$refs.addDynamic.setting.title = '添加话题';
    },
    handlerDelete: function handlerDelete() {
      var _this3 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      communityTopicDeleteByIds(par.ids).then(function (res) {
        _this3.$message.success('删除成功');

        _this3.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      communityTopicQueryPage(this.tableFrom).then(function (res) {
        _this4.listData.list = res.list;
        _this4.listData.total = res.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};