//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { advPositionQueryPage, advPositionDeleteByIds, advPositionUpdateById } from '@/api/slideShowPlace';
import addDynamic from './components/addDynamic';
import { parseTime } from '@/utils';
export default {
  name: 'SlideShowPlace',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      value1: '',
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        name: '',
        // 标题
        code: '' // 是否显示

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: [] // 选中row

    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    // handleUpdate(status) {
    //   if (this.listLoading) {
    //     return
    //   }
    //   this.listLoading = true
    //   if (this.tableSelection.length === 0) {
    //     this.$message.error('选中不能为空!')
    //     return
    //   }
    //   this.tableSelection.forEach(val => {
    //     val.isShow = status
    //   })
    //   advBatchUpdateById(this.tableSelection).then(res => {
    //     this.$refs.multipleTable.clearSelection()
    //     this.$message.success('修改成功')
    //     this.listLoading = false
    //     this.tableSelection = []
    //   }).catch(res => {
    //     this.$refs.multipleTable.clearSelection()
    //     this.$message.error('修改失败')
    //     this.listLoading = false
    //   })
    // },
    handleOpen: function handleOpen(row) {
      var _this = this;

      this.$confirm("\u786E\u8BA4".concat(row.isShow ? '关闭' : '开启', "\u5F53\u524D\u8F6E\u64AD\u56FE"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.setSave(row);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.isShow ? '关闭' : '开启')
        });
      });
    },
    setSave: function setSave(row) {
      var _this2 = this;

      row.isShow = !row.isShow;
      advPositionUpdateById(row).then(function (res) {
        _this2.$message.success('修改成功');
      }).catch(function (res) {
        row.isShow = !row.isShow;

        _this2.$message.error('修改失败');
      });
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.name = '';
      this.tableFrom.code = '';
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      this.tableSelection = row;
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.id) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        this.$refs.addDynamic.formModal = par; // if (this.$refs.addDynamic.formModal.selfIntroduction) {
        //   this.$refs.addDynamic.formModal.selfIntroduction = par.selfIntroduction.split(',')
        // } else {
        //   this.$refs.addDynamic.formModal.selfIntroduction = []
        // }

        this.$refs.addDynamic.setting.title = '编辑轮播图';
      } else {
        // 重置弹窗
        this.$refs.addDynamic.formModal = {
          id: '',
          // 广告id
          name: '',
          // 轮播图名称
          code: '',
          // 取值键
          display: '',
          // 广告展示方式
          type: 0 // 广告类别

        };
        this.$refs.addDynamic.setting.title = '添加轮播图';
      } // // 重置弹窗
      // this.$refs.addDynamic.formModal = {
      //   apId: 1, // 广告id
      //   title: '', // 轮播图名称
      //   url: '', // 头像
      //   resUrl: '', // 轮播图链接
      //   sort: '', // 位置排序
      //   isShow: true // 状态
      // }


      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this3 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      advPositionDeleteByIds(par.ids).then(function (res) {
        _this3.$message.success('删除成功');

        _this3.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      advPositionQueryPage(this.tableFrom).then(function (res) {
        _this4.listData.list = res.list;
        _this4.listData.total = res.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};