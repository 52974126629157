//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { eventQueryPage, eventDel } from '@/api/newsFlash/newsFlash';
import addDynamic from './components/addDynamic';
import { parseTime } from '@/utils';
import { languageType } from '@/utils/enum';
import { categoryQueryList } from '@/api/article/article';
export default {
  name: 'DynamicMen',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      languageType: languageType,
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      articleType: [],
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        content: '',
        title: '',
        categoryId: '',
        language: 'cn'
      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: ''
    };
  },
  mounted: function mounted() {
    this.getList();
    this.articleList();
  },
  methods: {
    parseTime: parseTime,
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20,
        content: '',
        title: '',
        categoryId: '',
        language: 'cn'
      }, this.getList();
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    articleList: function articleList() {
      var _this = this;

      var par = {
        type: 5,
        language: this.tableFrom.language
      };
      categoryQueryList(par).then(function (res) {
        _this.articleType = res;

        _this.$forceUpdate();
      }).catch(function () {// this.listLoading = false
      });
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      eventQueryPage(this.tableFrom).then(function (res) {
        _this2.listData.list = res.list;
        _this2.listData.total = res.totalCount;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    delCelebrity: function delCelebrity(id) {
      var _this3 = this;

      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var ids = [];
        ids.push(id);
        eventDel(ids).then(function (res) {
          _this3.$message({
            message: '删除成功',
            type: 'success'
          });

          _this3.getList();
        }).catch(function () {
          _this3.$message.error('删除错误');
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.id) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        par.categoryId = par.categoryId.split(',').map(function (id) {
          return parseInt(id);
        });
        par.categoryName = par.categoryName.split(','); // par.categoryId = JSON.parse(par.categoryId)
        // par.categoryName = JSON.parse(par.categoryName)

        this.$refs.addDynamic.formModal = par;

        if (this.$refs.addDynamic.formModal.selfIntroduction) {
          this.$refs.addDynamic.formModal.selfIntroduction = par.selfIntroduction.split(',');
        } else {
          this.$refs.addDynamic.formModal.selfIntroduction = [];
        }

        this.$refs.addDynamic.setting.title = '编辑内容';
      } else {
        // 重置弹窗
        this.$refs.addDynamic.formModal = {
          id: '',
          title: '',
          content: '',
          categoryId: '',
          currencyId: '',
          dataStatus: '',
          eventTime: '',
          language: 'cn'
        };
        this.$refs.addDynamic.setting.title = '添加内容';
      }

      this.$refs.addDynamic.setting.visible = true;
    }
  }
};