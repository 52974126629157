var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    disabled: _vm.formDisable,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "昵称", prop: "nickname" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入昵称" },
                        model: {
                          value: _vm.formModal.nickname,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "nickname", $$v)
                          },
                          expression: "formModal.nickname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "简介", prop: "columnIntroduction" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "请输入简介" },
                        model: {
                          value: _vm.formModal.columnIntroduction,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "columnIntroduction", $$v)
                          },
                          expression: "formModal.columnIntroduction",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专栏头像", prop: "columnAvatar" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "http-request": _vm.handleUploadForm,
                            "on-change": _vm.imgSaveToUrl,
                            headers: _vm.myHeaders,
                            "show-file-list": false,
                            multiple: "",
                          },
                        },
                        [
                          _vm.formModal.columnAvatar
                            ? _c("div", { staticClass: "avatar" }, [
                                _c("img", {
                                  attrs: { src: _vm.formModal.columnAvatar },
                                }),
                              ])
                            : _c("div", { staticClass: "upload" }, [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                                _c("span", [_vm._v("上传图片")]),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "专栏名称", prop: "columnName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入专栏名称" },
                        model: {
                          value: _vm.formModal.columnName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "columnName", $$v)
                          },
                          expression: "formModal.columnName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系方式", prop: "contact" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入联系方式" },
                        model: {
                          value: _vm.formModal.contact,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "contact", $$v)
                          },
                          expression: "formModal.contact",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目介绍", prop: "describe" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入项目介绍",
                        },
                        model: {
                          value: _vm.formModal.describe,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "describe", $$v)
                          },
                          expression: "formModal.describe",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱", prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入邮箱" },
                        model: {
                          value: _vm.formModal.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "email", $$v)
                          },
                          expression: "formModal.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "粉丝", prop: "fansNumber" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入粉丝" },
                        model: {
                          value: _vm.formModal.fansNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "fansNumber", $$v)
                          },
                          expression: "formModal.fansNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述", prop: "introduction" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入描述信息",
                        },
                        model: {
                          value: _vm.formModal.introduction,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "introduction", $$v)
                          },
                          expression: "formModal.introduction",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "链接", prop: "mediaLinks" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入社交媒体完整的链接" },
                        model: {
                          value: _vm.formModal.mediaLinks,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "mediaLinks", $$v)
                          },
                          expression: "formModal.mediaLinks",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "形容", prop: "type" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入形容您的信息",
                        },
                        model: {
                          value: _vm.formModal.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "type", $$v)
                          },
                          expression: "formModal.type",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.formDisable },
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.formDisable, type: "primary" },
                  on: { click: _vm.settingEvent },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }