function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * @file 数组相关api
 */
import { isNotEmpty } from './types';
import { deepClone } from './object';
/**
 * 给定四个参数，类型分别为,String,String,，若第四个参数存 在，则会在回调中传入替换好的字符串，否则直接返回替换好的字符串
 * @param   { String }   str      原始字符串
 * @param   { String }   newStr   想替换的值
 * @param   { String }   regStr   目标字符串
 * @param   { Function } cb       回调，执行接受了替换完的字符串的回调
 * @returns { String } 若无回调参数，则返回替换完的字符串
 */

export var checkString = function checkString(str, newStr, regStr, cb) {
  if (typeof str !== 'string' && typeof newStr !== 'string' && typeof regStr !== 'string') {
    console.log('参数一或参数二必须为字符串');
    return false;
  }

  var val = str.replace(new RegExp(regStr, 'g'), newStr);
  if (cb) cb(val);
  return val;
};
/**
 * 树形结构 ==> 扁平化数组。 会删除 childKey 的键名
 * @param {Array} array 数组
 * @param {String} childKey 子集数组名
 * @param {Number} level 层级，不传代表支持无线层级
 * @param {Boolean} isDeleteChild 是否删除 childKey 键，默认删除
 * @returns {array} 不影响原数组，返回处理后的数组
 *
 * @example
 * treeToFlat([{"id":"2","rank":1,"children":[{"id":"2.1","rank":2}]}], 'children')
 * // => [{"id":"2","rank":1},{"id":"2.1","rank":2}]
 */

export var treeToFlat = function treeToFlat(arr, childKey, level) {
  var isDeleteChild = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var newArr = [];
  var floor = 1;

  function getData(list, _pid) {
    list.forEach(function (item) {
      var newItem = deepClone(item);

      if (isDeleteChild) {
        delete newItem[childKey];
      }

      newArr.push(_objectSpread(_objectSpread({}, newItem), {}, {
        _pid: _pid
      }));

      if (isNotEmpty(item[childKey])) {
        floor += 1;

        if (level && floor > level) {
          return;
        }

        getData(item[childKey], item.id);
      }
    });
  }

  getData(arr);
  return newArr;
};