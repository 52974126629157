var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "createBy" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择用户" },
                          model: {
                            value: _vm.formModal.createBy,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "createBy", $$v)
                            },
                            expression: "formModal.createBy",
                          },
                        },
                        _vm._l(_vm.memberList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.nickname, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "contentType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择分类" },
                          model: {
                            value: _vm.formModal.contentType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "contentType", $$v)
                            },
                            expression: "formModal.contentType",
                          },
                        },
                        _vm._l(_vm.comeFrom, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "话题", prop: "topicId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择话题" },
                          model: {
                            value: _vm.formModal.topicId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "topicId", $$v)
                            },
                            expression: "formModal.topicId",
                          },
                        },
                        _vm._l(_vm.topicList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.createBy, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容", prop: "content" } },
                    [
                      _c("ueditor-from", {
                        attrs: { content: _vm.formModal.content },
                        model: {
                          value: _vm.formModal.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "content", $$v)
                          },
                          expression: "formModal.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头像", prop: "image" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "http-request": _vm.handleUploadForm,
                            "on-change": _vm.imgSaveToUrl,
                            headers: _vm.myHeaders,
                            "show-file-list": false,
                            multiple: "",
                          },
                        },
                        [
                          _vm.formModal.image
                            ? _c("div", { staticClass: "avatar" }, [
                                _c("img", {
                                  attrs: { src: _vm.formModal.image },
                                }),
                              ])
                            : _c("div", { staticClass: "upload" }, [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                                _c("span", [_vm._v("图片地址组合")]),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "超链接", prop: "url" } },
                    [
                      _vm._l(_vm.formModal.url, function (tag) {
                        return _c(
                          "el-tag",
                          {
                            key: tag,
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              closable: "",
                              "disable-transitions": false,
                            },
                            on: {
                              close: function ($event) {
                                return _vm.handleTagClose(tag)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(tag) + " ")]
                        )
                      }),
                      _vm.inputVisible
                        ? _c("el-input", {
                            ref: "saveTagInput",
                            staticClass: "input-new-tag",
                            staticStyle: { width: "100px" },
                            attrs: { size: "small" },
                            on: { blur: _vm.handleInputConfirm },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleInputConfirm($event)
                              },
                            },
                            model: {
                              value: _vm.inputValue,
                              callback: function ($$v) {
                                _vm.inputValue = $$v
                              },
                              expression: "inputValue",
                            },
                          })
                        : _c(
                            "el-button",
                            {
                              staticClass: "button-new-tag",
                              attrs: { size: "small" },
                              on: { click: _vm.showInput },
                            },
                            [_vm._v("添加标签 +")]
                          ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "虚拟点赞量", prop: "likeNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.likeNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "likeNum", $$v)
                          },
                          expression: "formModal.likeNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "虚拟转发量", prop: "forwardNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.forwardNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "forwardNum", $$v)
                          },
                          expression: "formModal.forwardNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "showEnable" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.formModal.showEnable,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "showEnable", $$v)
                            },
                            expression: "formModal.showEnable",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.formModal.showEnable,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "showEnable", $$v)
                            },
                            expression: "formModal.showEnable",
                          },
                        },
                        [_vm._v("隐藏")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "操作", prop: "auditStatus" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formModal.auditStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "auditStatus", $$v)
                            },
                            expression: "formModal.auditStatus",
                          },
                        },
                        [_vm._v("审核通过")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.formModal.auditStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "auditStatus", $$v)
                            },
                            expression: "formModal.auditStatus",
                          },
                        },
                        [_vm._v("审核不通过")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }