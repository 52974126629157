//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { currencyQueryPage, currencyDeleteByIds, currencyUpdateById } from '@/api/currency/currency'; // import AddFamous from './components/addFamous'

export default {
  name: 'Amonnt',
  components: {},
  data: function data() {
    return {
      options: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        abbreviation: '' // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      celebrityData: {
        data: [],
        loading: false,
        total: 0
      }
    };
  },
  watch: {
    $route: {
      handler: function handler(newRouter) {
        if (newRouter.name === 'Amonnt') {
          this.getList();
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {},
  methods: {
    filterTag: function filterTag(value, row) {
      console.log(value, row);
    },
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handleScreen: function handleScreen() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.abbreviation = '';
      this.getList();
    },
    handlerState: function handlerState(statue, val) {
      var formDisable = false;
      var formModal = {
        id: '',
        // id
        nameEn: '',
        // 币种英文简称
        nameZh: '',
        // 币种中文简称
        abbreviation: '',
        // 币种名称缩写
        icon: '',
        // 币种图片
        sort: '',
        // 序列
        sorts: '',
        // 排名
        priceUsd: '',
        // 价格
        circulationMarket: '',
        // 流通市值
        synopsis: '',
        // 基本信息
        // 发行信息
        // 排名先不要
        initialIssueDate: '',
        // 首次发行时间
        initialOpenPrice: '',
        // 首次开盘价
        historyPriceMax: '',
        // 历史最高价格 美元
        historyPriceMaxDate: '',
        // 历史最高价格 日期
        historyPriceMin: '',
        // 历史最低价格 美元
        historyPriceMinDate: '',
        // 历史最低价格 日期
        initialDistribution: '',
        // 首次发行方式
        exchangeCount: '',
        // 上架交易所数量
        dataStatus: '1',
        // 状态
        // 供应信息
        supplyMax: '',
        // 最大供应量
        supplyMarketMax: '',
        // 最大供应市场
        supplyCurrent: '',
        // 当前供应量
        supplyMarketCurrent: '',
        // 当前供应市场
        circulation: '',
        // 流通量
        circulationRate: '',
        // 流通率
        proportionOfGlobalTotal: '' // 全球

      };

      if (statue === 3) {
        formDisable = true; // 禁止编辑状态
      }

      if (statue !== 1) {
        formModal = val;
      }

      this.$router.push({
        name: 'AddAmonnt',
        params: {
          statue: statue,
          // 状态1 新增 2 编辑 3 查看
          formModal: formModal,
          formDisable: formDisable
        }
      });
    },
    handleOpen: function handleOpen(row) {
      var _this = this;

      this.$confirm("\u786E\u8BA4".concat(row.dataStatus ? '隐藏' : '开启', "\u5F53\u524D\u5E01\u79CD"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.setSave(row);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.dataStatus ? '隐藏' : '开启')
        });
      });
    },
    setSave: function setSave(row) {
      var _this2 = this;

      if (row.dataStatus) {
        row.dataStatus = 0;
      } else {
        row.dataStatus = 1;
      }

      currencyUpdateById(row).then(function (res) {
        _this2.$message.success('修改成功');
      }).catch(function (res) {
        if (row.dataStatus) {
          row.dataStatus = 0;
        } else {
          row.dataStatus = 1;
        }

        _this2.$message.error('修改失败');
      });
    },
    handlerDelete: function handlerDelete() {
      var _this3 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      currencyDeleteByIds(par.ids).then(function (res) {
        _this3.$message.success('删除成功');

        _this3.getList();
      });
    },
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity() {
      // 重置弹窗
      this.$refs.addFamous.formModal = {
        id: '',
        // id
        nameEn: '',
        // 币种英文简称
        nameZh: '',
        // 币种中文简称
        icon: '',
        // 币种图片
        sort: '',
        // 序列
        sorts: '',
        // 排名
        priceUsd: '',
        // 价格
        circulationMarket: '',
        // 流通市值
        synopsis: '',
        // 基本信息
        // 发行信息
        // 排名先不要
        initialIssueDate: '',
        // 首次发行时间
        initialOpenPrice: '',
        // 首次开盘价
        historyPriceMax: '',
        // 历史最高价格 美元
        historyPriceMaxDate: '',
        // 历史最高价格 日期
        historyPriceMin: '',
        // 历史最低价格 美元
        historyPriceMinDate: '',
        // 历史最低价格 日期
        initialDistribution: '',
        // 首次发行方式
        exchangeCount: '',
        // 上架交易所数量
        dataStatus: '1',
        // 状态
        // 供应信息
        supplyMax: '',
        // 最大供应量
        supplyMarketMax: '',
        // 最大供应市场
        supplyCurrent: '',
        // 当前供应量
        supplyMarketCurrent: '',
        // 当前供应市场
        circulation: '',
        // 流通量
        circulationRate: '',
        // 流通率
        proportionOfGlobalTotal: '' // 全球

      };
      this.$refs.addFamous.setting.visible = true;
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      currencyQueryPage(this.tableFrom).then(function (res) {
        _this4.listData.list = res.list;
        _this4.listData.total = res.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};