//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { currencySave, currencyUpdateById } from '@/api/currency/currency';
import { parseTime } from '@/utils';
import ueditorFrom from '@/components/ueditorFrom';
export default {
  name: 'AddAmonnt',
  components: {
    ueditorFrom: ueditorFrom
  },
  data: function data() {
    return {
      formModal: {
        id: '',
        // id
        nameEn: '',
        // 币种英文简称
        nameZh: '',
        // 币种中文简称
        abbreviation: '',
        // 币种名称缩写
        icon: '',
        // 币种图片
        sort: '',
        // 序列
        sorts: '',
        // 排名
        priceUsd: '',
        // 价格
        circulationMarket: '',
        // 流通市值
        synopsis: '',
        // 基本信息
        // 发行信息
        // 排名先不要
        initialIssueDate: '',
        // 首次发行时间
        initialOpenPrice: '',
        // 首次开盘价
        historyPriceMax: '',
        // 历史最高价格 美元
        historyPriceMaxDate: '',
        // 历史最高价格 日期
        historyPriceMin: '',
        // 历史最低价格 美元
        historyPriceMinDate: '',
        // 历史最低价格 日期
        initialDistribution: '',
        // 首次发行方式
        exchangeCount: '',
        // 上架交易所数量
        dataStatus: '1',
        // 状态
        // 供应信息
        supplyMax: '',
        // 最大供应量
        supplyMarketMax: '',
        // 最大供应市场
        supplyCurrent: '',
        // 当前供应量
        supplyMarketCurrent: '',
        // 当前供应市场
        circulation: '',
        // 流通量
        circulationRate: '',
        // 流通率
        proportionOfGlobalTotal: '' // 全球

      },
      formDisable: false,
      // 禁用状态
      statue: '',
      rules: {
        nameEn: [{
          required: true,
          message: '请输入币种英文简称',
          trigger: 'blur'
        }],
        nameZh: [{
          required: true,
          message: '请输入币种中文简称',
          trigger: 'blur'
        }],
        priceUsd: [{
          required: true,
          message: '请输入价格',
          trigger: 'blur'
        }],
        icon: [{
          required: true,
          message: '请上传币种图片',
          trigger: 'blur'
        }],
        sort: [{
          required: true,
          message: '请输入排名',
          trigger: 'blur'
        }],
        circulationMarket: [{
          required: true,
          message: '请输入流通市值',
          trigger: 'blur'
        }],
        synopsis: [{
          required: true,
          message: '请输入简介',
          trigger: 'blur'
        }],
        initialIssueDate: [{
          required: true,
          message: '请输入首次发行时间',
          trigger: 'blur'
        }],
        initialOpenPrice: [{
          required: true,
          message: '请输入首次开盘价',
          trigger: 'blur'
        }],
        historyPriceMax: [{
          required: true,
          message: '请输入历史最高价格',
          trigger: 'blur'
        }],
        historyPriceMaxDate: [{
          required: true,
          message: '请输入历史最高价格日期',
          trigger: 'blur'
        }],
        historyPriceMin: [{
          required: true,
          message: '请输入历史最低价格',
          trigger: 'blur'
        }],
        historyPriceMinDate: [{
          required: true,
          message: '请输入历史最低价格日期',
          trigger: 'blur'
        }],
        initialDistribution: [{
          required: true,
          message: '请输入首次发行方式',
          trigger: 'blur'
        }],
        exchangeCount: [{
          required: true,
          message: '请输入上架交易所数量',
          trigger: 'blur'
        }],
        supplyMax: [{
          required: true,
          message: '请输入最大供应量',
          trigger: 'blur'
        }],
        supplyMarketMax: [{
          required: true,
          message: '请输入最大供应市场',
          trigger: 'blur'
        }],
        supplyCurrent: [{
          required: true,
          message: '请输入当前供应量',
          trigger: 'blur'
        }],
        supplyMarketCurrent: [{
          required: true,
          message: '请输入当前供应市场',
          trigger: 'blur'
        }],
        circulation: [{
          required: true,
          message: '请输入流通量',
          trigger: 'blur'
        }],
        circulationRate: [{
          required: true,
          message: '请输入流通率',
          trigger: 'blur'
        }],
        proportionOfGlobalTotal: [{
          required: true,
          message: '请输入全球总市值占比',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.formDisable = this.$route.params.formDisable;
    this.formModal = this.$route.params.formModal;
    this.statue = this.$route.params.statue;

    if (this.statue !== 1) {
      this.$route.meta.alias = '编辑币种';
    }
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      this.formModal.selfIntroduction.splice(this.formModal.selfIntroduction.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this = this;

      if (this.formModal.selfIntroduction.length > 2) {
        this.$message.error('标签最多可以添加3个');
        return;
      }

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this2 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      console.log(formData, param.file, 'formData');
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this2.$message.success('上传成功');

        _this2.formModal.icon = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      var formModal = JSON.parse(JSON.stringify(this.formModal));
      currencyUpdateById(formModal).then(function (res) {
        _this3.$message.success('修改成功'); // 重置
        // this.$refs.formModal.resetFields()


        _this3.$router.push({
          name: 'Amonnt'
        });
      }).catch(function (res) {
        _this3.$message.error('修改失败');
      });
    },
    settingEvent: function settingEvent() {
      var _this4 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          if (_this4.formModal.id) {
            _this4.handleUpdate();
          } else {
            var formModal = JSON.parse(JSON.stringify(_this4.formModal));
            console.log(_this4.formModal.historyPriceMaxDate.getTime(), 'this.formModal.historyPriceMaxDate.getTime()');
            formModal.historyPriceMaxDate = _this4.formModal.historyPriceMaxDate.getTime();
            formModal.historyPriceMinDate = _this4.formModal.historyPriceMinDate.getTime();
            formModal.initialIssueDate = parseTime(_this4.formModal.initialIssueDate.getTime(), '{y}-{m}-{d}');
            currencySave(formModal).then(function (res) {
              _this4.$message.success('添加成功'); // 重置


              _this4.$refs.formModal.resetFields(); // this.handleClose()

            }).catch(function (res) {
              _this4.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};