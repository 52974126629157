// 请求接口地址 如果没有配置自动获取当前网址路径
var VUE_APP_API_URL = process.env.VUE_APP_BASE_API || "".concat(location.origin);
var VUE_APP_WS_URL = process.env.VUE_APP_WS_URL || (location.protocol === 'https' ? 'wss' : 'ws') + ':' + location.hostname; // const url = {
//   //ip替换
//   cwz: 'http://192.168.10.101:18090/xhj-gather-admin/',
//   swag: 'http://192.168.10.101:18090/xhj-gather-admin/',
//   pro: 'https://api.582btc.com/xhj-gather-admin/',
//   test: 'http://192.168.10.101:18090/xhj-gather-admin/',
//   dev: 'https://dev.admin.api.xiaohongjiu.com/api/',
//   extranetIp: 'http://39.108.230.224:29088/api/',
//   build: VUE_APP_API_URL + '/api/'
// }
//tangjian
// const url = {
//   //ip替换
//   cwz: 'http://192.168.10.48:18090/xhj-gather-admin/',
//   swag: 'http://192.168.10.48:18090/xhj-gather-admin/',
//   pro: 'https://api.582btc.com/xhj-gather-admin/',
//   test: 'http://192.168.10.48:18090/xhj-gather-admin/',
//   dev: 'https://dev.admin.api.xiaohongjiu.com/api/',
//   extranetIp: 'http://39.108.230.224:29088/api/',
//   build: VUE_APP_API_URL + '/api/'
// }
//生产

var url = {
  //ip替换
  cwz: 'https://api-dev.582btc.com/xhj-gather-admin/',
  swag: 'https://api-dev.582btc.com/xhj-gather-admin/',
  pro: 'https://api.lianpucom.com/xhj-gather-admin/',
  test: 'https://api-dev.582btc.com/xhj-gather-admin/',
  dev: 'https://dev.admin.api.xiaohongjiu.com/api/',
  extranetIp: 'http://39.108.230.224:29088/api/',
  build: VUE_APP_API_URL + '/api/'
}; //本地服务器
// const url = {
//   //ip替换
//   cwz: 'http://192.168.10.254:18090/xhj-gather-admin/',
//   swag: 'http://192.168.10.254:18090/xhj-gather-admin/',
//   pro: 'https://api.lianpucom.com/xhj-gather-admin/',
//   test: 'http://192.168.10.254:18090/xhj-gather-admin/',
//   dev: 'https://dev.admin.api.xiaohongjiu.com/api/',
//   extranetIp: 'http://39.108.230.224:29088/api/',
//   build: VUE_APP_API_URL + '/api/'
// }
// const url = {
//   //ip替换
//   cwz: 'http://lwku27yc.528btc.com/xhj-gather-admin/',
//   swag: 'http://lwku27yc.528btc.com/xhj-gather-admin/',
//   pro: 'http://lwku27yc.528btc.com/xhj-gather-admin/',
//   test: 'http://lwku27yc.528btc.com/xhj-gather-admin/',
//   dev: 'https://dev.admin.api.xiaohongjiu.com/api/',
//   extranetIp: 'http://39.108.230.224:29088/api/',
//   build: VUE_APP_API_URL + '/api/'
// }

var URL = '';
var domainm = document.domain;

switch (domainm) {
  case '192.168.10.45':
    URL = url.test; // 23

    break;

  case 'localhost':
    URL = url.test; //  cwz

    break;

  case '192.168.10.34':
    URL = url.cwz; // 本地ip

    break;

  case 'ywyuny.582btc.com':
    URL = url.pro; // 正式

    break;

  case 'testadmin.xiaohongjiu.com':
    URL = url.test; // 测试

    break;

  case 'devadmin.xiaohongjiu.com':
    URL = url.dev; // 外网

    break;
}

var SettingMer = {
  // 服务器地址
  httpUrl: VUE_APP_API_URL,
  // 接口请求地址
  apiBaseURL: URL,
  // socket连接
  wsSocketUrl: VUE_APP_WS_URL
};
export default SettingMer;