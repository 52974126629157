//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { communityTagQueryPage, communityTopicSave } from '@/api/community/community';
export default {
  name: 'AddFamous',
  data: function data() {
    return {
      formModal: {
        id: '',
        title: '',
        // 关联名人
        contentTagId: '',
        // 来自
        image: '',
        // 头像
        des: '',
        // 内容
        visitNum: 0,
        // 总获赞量
        discussNum: '',
        // 总评论量
        forwardNum: '',
        // 总被分享量
        hotEnable: true // 状态

      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      rules: {
        title: [{
          required: true,
          message: '请输入话题人',
          trigger: 'blur'
        }],
        // contentTagId: [
        //   { required: true, message: '请选择内容来自位置', trigger: 'blur' }
        // ],
        // content: [
        //   { required: true, message: '内容不能为空', trigger: 'blur' }
        // ],
        pic: [{
          required: true,
          message: '请上传图片',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.getCommunityTagQueryPage();
  },
  methods: {
    // 社区标签
    getCommunityTagQueryPage: function getCommunityTagQueryPage() {
      var _this = this;

      var par = {
        page: 1,
        limit: 20 // keywords: ''

      };
      communityTagQueryPage(par).then(function (res) {
        _this.comeFrom = res.list;
      }).catch(function () {});
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      this.formModal.selfIntroduction.splice(this.formModal.selfIntroduction.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this2 = this;

      if (this.formModal.selfIntroduction.length > 2) {
        this.$message.error('标签最多可以添加3个');
        return;
      }

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this2.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this3 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      console.log(formData, param.file, 'formData');
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this3.$message.success('上传成功');

        _this3.formModal.pic = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this4 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          communityTopicSave(_this4.formModal).then(function (res) {
            _this4.$message.success('添加成功'); // 重置


            _this4.$refs.formModal.resetFields();

            _this4.handleClose();
          }).catch(function (res) {
            _this4.$message.error('添加失败');
          });
        }
      });
    }
  }
};