var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "800",
            modal: _vm.modalState,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "celebrity" }, [
            _c("div", { staticClass: "celebrity_top" }, [
              _c("div", { staticClass: "celebrity_box" }, [
                _c(
                  "div",
                  { staticClass: "celebrity_lt" },
                  [
                    _c("span", [_vm._v("用户姓名:")]),
                    _c("el-input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.tableFrom.nickname,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "nickname", $$v)
                        },
                        expression: "tableFrom.nickname",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "celebrity_lt" },
                  [
                    _c("span", [_vm._v("手机号:")]),
                    _c("el-input", {
                      attrs: { placeholder: "请输入手机号" },
                      model: {
                        value: _vm.tableFrom.mobile,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "mobile", $$v)
                        },
                        expression: "tableFrom.mobile",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "celebrity_lt" },
                  [
                    _c("span", [_vm._v("邮箱:")]),
                    _c("el-input", {
                      attrs: { placeholder: "请输入邮箱" },
                      model: {
                        value: _vm.tableFrom.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.tableFrom, "email", $$v)
                        },
                        expression: "tableFrom.email",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "celebrity_lt" },
                  [
                    _c("span", [_vm._v("用户类型:")]),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择用户类型" },
                        model: {
                          value: _vm.tableFrom.memberType,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "memberType", $$v)
                          },
                          expression: "tableFrom.memberType",
                        },
                      },
                      _vm._l(_vm.dnamicSelect, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "celebrity_lt" },
                  [
                    _c("span", [_vm._v("虚拟用户:")]),
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择虚拟用户" },
                        model: {
                          value: _vm.tableFrom.virtualFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableFrom, "virtualFlag", $$v)
                          },
                          expression: "tableFrom.virtualFlag",
                        },
                      },
                      _vm._l(_vm.virtualFlagType, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "celebrity_rt" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: { click: _vm.handleReset },
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.lookRow } },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.settingEvent },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "celebrity_table" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.listData.list,
                      size: "mini",
                      "max-height": "500",
                      "highlight-current-row": "",
                      "tooltip-effect": "dark",
                      "header-cell-style": { "text-align": "center" },
                      "cell-style": { "text-align": "center" },
                    },
                    on: { "row-click": _vm.handleRowClick },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "nickname", label: "姓名", width: "100" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "mobile", label: "手机号", width: "150" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "email", label: "邮箱", width: "150" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "memberType",
                        label: "用户类型",
                        "min-width": "178",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.memberType == 1
                                      ? "普通用户"
                                      : scope.row.memberType == 2
                                      ? "官方用户"
                                      : "名人用户"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "virtualFlag",
                        label: "虚拟用户",
                        "min-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                attrs: {
                                  value: Boolean(scope.row.virtualFlag),
                                  disabled: "",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "header",
                        label: "头像",
                        "min-width": "178",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-image", {
                                staticClass: "photo",
                                attrs: {
                                  src: scope.row.header,
                                  "preview-src-list": [scope.row.header],
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "选择", width: "120", fixed: "right" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: scope.row.id, border: "" },
                                  model: {
                                    value: _vm.coincelebrityRadio,
                                    callback: function ($$v) {
                                      _vm.coincelebrityRadio = $$v
                                    },
                                    expression: "coincelebrityRadio",
                                  },
                                },
                                [_vm._v("选中")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "block mb20" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [5, 10, 20, 30, 40],
                        "page-size": _vm.tableFrom.limit,
                        "current-page": _vm.tableFrom.page,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.listData.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.pageChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }