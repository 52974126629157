//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { categoryQuerySave, categoryQueryUpdateById } from '@/api/article/article';
import { languageType } from '@/utils/enum';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    return {
      languageType: languageType,
      formModal: {
        id: '',
        name: '',
        // 分类名称
        sort: '',
        // 来自
        keywords: '',
        language: '',
        selfFlag: '',
        status: 1 // 状态

      },
      selfflagList: [{
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      rules: {
        name: [{
          required: true,
          message: '请输入分类名称',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // input Number
    handleChange: function handleChange(value) {
      console.log(value);
    },
    handleUpdate: function handleUpdate() {
      var _this = this;

      categoryQueryUpdateById(this.formModal).then(function (res) {
        _this.$message.success('修改成功'); // 重置


        _this.$refs.formModal.resetFields();

        _this.handleClose();
      }).catch(function (res) {
        _this.$message.error('修改失败');
      });
    },
    settingEvent: function settingEvent() {
      var _this2 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          if (_this2.formModal.id) {
            _this2.handleUpdate();
          } else {
            categoryQuerySave(_this2.formModal).then(function (res) {
              _this2.$message.success('添加成功'); // 重置


              _this2.$refs.formModal.resetFields();

              _this2.handleClose();
            }).catch(function (res) {
              _this2.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};