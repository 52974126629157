/* eslint-disable no-undef */

/* eslint-disable no-unused-vars */
// import Vue from 'vue'
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import '@/styles/index.scss'; // global css
// import '@/assets/iconfont/iconfont'

import '@/assets/iconfont/iconfont.css'; // import Element from 'element-ui'
// import './styles/element-variables.scss'
// import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖

import '@/styles/index.scss'; // global css

import App from './App';
import store from './store';
import router from './router';
import './icons'; // icon

import './permission'; // permission control

import './utils/error-log'; // error log

import * as filters from './filters'; // global filters

Vue.config.devtools = false;
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

process.env.NODE_ENV === 'development';
Vue.prototype.$updurl = 'pro/'; // Vue.prototype.$updurl = 'dev/'
// Vue.use(Element, {
//   size: Cookies.get('size') || 'medium' // set element-ui default size
//   // locale: enLang // 如果使用中文，无需设置，请删除
// })
// register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});