export var registerType = [// 注册类型
{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '邮箱注册'
}, {
  value: 0,
  label: '手机注册'
}, {
  value: 2,
  label: '苹果注册'
}];
export var virtualFlagType = [// 虚拟账号
{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '是'
}, {
  value: 0,
  label: '否'
}];
export var whiteEnableType = [// 虚拟账号
{
  value: '',
  label: '全部'
}, {
  value: true,
  label: '是'
}, {
  value: false,
  label: '否'
}];
export var languageType = {
  // 语言类型
  'cn': '简体',
  'tn': '繁体',
  'en': '英文'
};
export var contentType = [// 虚拟账号
{
  value: '',
  label: '全部'
}, {
  value: 1,
  label: '文字'
}, {
  value: 2,
  label: '图文'
}, {
  value: 3,
  label: '视频'
}];
export var authorityTree = [{
  id: 1,
  label: '名人',
  children: [{
    id: 2,
    label: '名人管理'
  }, {
    id: 3,
    label: '动态管理'
  }, {
    id: 4,
    label: '评论管理'
  }]
}, {
  id: 5,
  label: '动态',
  children: [{
    id: 6,
    label: '话题管理',
    children: [{
      id: 7,
      label: '话题管理首页'
    }, {
      id: 8,
      label: '话题标签'
    }, {
      id: 9,
      label: '问答标签'
    }]
  }, {
    id: 10,
    label: '社区动态',
    children: [{
      id: 11,
      label: '社区动态首页'
    }, {
      id: 12,
      label: '评论管理'
    }]
  }]
}, {
  id: 13,
  label: '讯息',
  children: [{
    id: 14,
    label: '资讯管理',
    children: [{
      id: 15,
      label: '资讯首页'
    }, {
      id: 16,
      label: '轮播图'
    }, {
      id: 17,
      label: '轮播图位置'
    }, {
      id: 18,
      label: '分类'
    }]
  }, {
    id: 19,
    label: '快讯管理'
  }]
}, {
  id: 20,
  label: '币圈',
  children: [{
    id: 21,
    label: '币种'
  }, {
    id: 22,
    label: '交易所'
  }, {
    id: 23,
    label: '版块'
  }]
}, {
  id: 24,
  label: '邀请记录'
}, {
  id: 25,
  label: '创作者记录'
}, {
  id: 26,
  label: '用户管理'
}, {
  id: 27,
  label: '系统设置',
  children: [{
    id: 28,
    label: '权限管理'
  }, {
    id: 29,
    label: '管理员管理'
  }, {
    id: 30,
    label: '版本控制'
  }]
}];
export default {
  registerType: registerType,
  virtualFlagType: virtualFlagType,
  whiteEnableType: whiteEnableType,
  contentType: contentType,
  languageType: languageType,
  authorityTree: authorityTree
};