import request from '@/utils/request'; // 账户版块
// 会员账户表分页列表

export function memberAccount(data) {
  return request({
    url: '/memberAccount/auth/queryPage',
    method: 'post',
    data: data
  });
} // 会员账户表主键查询

export function memberAccountgetById(data) {
  return request({
    url: '/memberAccount/auth/getById',
    method: 'post',
    data: data
  });
} //群聊版块

export function memberqueryPage(data) {
  return request({
    url: '/group/auth/queryPage',
    method: 'post',
    data: data
  });
} //群消息列表

export function groupMessagequeryPage(data) {
  return request({
    url: '/groupMessage/auth/queryPage',
    method: 'post',
    data: data
  });
} //群成员列表

export function groupMemberqueryPage(data) {
  return request({
    url: '/groupMember/auth/queryPage',
    method: 'post',
    data: data
  });
} //群成员审核

export function setgroupMemberqueryPage(data) {
  return request({
    url: '/groupMember/auth/examine',
    method: 'post',
    data: data
  });
} //用户标签查询

export function labelqueryPage(data) {
  return request({
    url: '/label/auth/queryPage',
    method: 'post',
    data: data
  });
} //用户标签修改

export function labelupdateById(data) {
  return request({
    url: '/label/auth/updateById',
    method: 'post',
    data: data
  });
} //用户标签新增

export function labelsave(data) {
  return request({
    url: '/label/auth/save',
    method: 'post',
    data: data
  });
} // 用户活动审核表

export function memberActivityCommentRecord(data) {
  return request({
    url: '/memberActivityCommentRecord/auth/queryPage',
    method: 'post',
    data: data
  });
} // 用户评论审核

export function memberActivityCommentexamine(data) {
  return request({
    url: '/memberActivityCommentRecord/auth/examine',
    method: 'post',
    data: data
  });
} // 用户审核记录

export function memberActivityCommentRecordHistory(data) {
  return request({
    url: '/memberActivityCommentRecord/auth/queryHistory',
    method: 'post',
    data: data
  });
} // 信息记录表分页列表

export function memberModifyRecordHistory(data) {
  return request({
    url: '/memberModifyRecord/auth/queryPage',
    method: 'post',
    data: data
  });
} // 修改信息记录表分页列表

export function memberModifyRecordUpd(data) {
  return request({
    url: '/memberModifyRecord/auth/examine',
    method: 'post',
    data: data
  });
}