//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { memberQuerySave, memberUpdateById, memberFollow } from '@/api/member/member';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    var _this = this;

    return {
      formModal: {
        id: '',
        nickname: '',
        // 昵称
        sourceId: '',
        // 来源
        outId: '',
        // 外部Id
        header: '',
        // 头像
        mobile: '',
        // 手机号
        countryCode: '',
        // 手机号区号
        email: '',
        // 邮箱
        sign: '',
        // 个性签名
        regType: '',
        // 注册类型
        likeNumActual: '',
        // 虚拟点赞量
        discussNumActual: '',
        // 虚拟评论量
        forwardNumActual: '',
        // 虚拟转发量
        visitNumActual: '',
        // 虚拟浏览量
        whiteEnable: false,
        // 是否白名单
        virtualFlag: 0,
        // 虚拟用户
        creatorFlag: 0,
        // 是否创作者  0否 1是
        creatorRank: 0,
        // 创作者排序权重  越大越靠前
        creatorSelectedFlag: 0,
        // 是否创作者精选  0否 1是
        creatorRecommendFlag: 0,
        // 是否创作者推荐  0否 1是
        creatorArticleLimit: 1,
        // 创作者文章上限
        creatorArticleLimitDay: 1,
        // 创作者文章上限周期
        creatorWhiteEnable: 0 // 创作者是否免审  0否 1是

      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      rules: {
        nickname: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        header: [{
          required: true,
          message: '请上传头像',
          trigger: 'blur'
        }],
        countryCode: [{
          required: true,
          message: '请选择手机区号',
          trigger: 'change'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (/^1[3-9]\d{9}$/.test(value) || !value) {
              if (!_this.formModal.countryCode) {
                callback(new Error('请输入手机区号'));
              } else {
                callback();
              }
            } else {
              callback(new Error('请输入有效的手机号码'));
            }
          },
          trigger: 'blur'
        }],
        email: [{
          required: false,
          trigger: 'blur',
          message: '请输入邮箱'
        }, {
          validator: function validator(rule, value, callback) {
            if (!value) {
              if (_this.rules.email[0].required) {
                return callback(new Error('邮箱不能为空'));
              }

              return callback();
            } else {
              var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

              if (reg.test(value)) {
                callback();
              } else {
                return callback(new Error('邮箱格式不正确！'));
              }
            }
          },
          trigger: 'blur'
        }]
      },
      phoneCodes: [{
        value: '86',
        label: '中国大陆'
      }, {
        value: '852',
        label: '中国香港'
      }, {
        value: '853',
        label: '中国澳门'
      }, {
        value: '886',
        label: '中国台湾'
      }, {
        value: '1',
        label: '美国'
      }, {
        value: '44',
        label: '英国'
      }, {
        value: '81',
        label: '日本'
      }, {
        value: '82',
        label: '韩国'
      }, {
        value: '65',
        label: '新加坡'
      }, {
        value: '60',
        label: '马来西亚'
      }, {
        value: '61',
        label: '澳大利亚'
      }, {
        value: '64',
        label: '新西兰'
      }, {
        value: '7',
        label: '俄罗斯'
      }],
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '',
      fansList: [] // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.spiderSourceList();
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    handleRegTypeChange: function handleRegTypeChange() {
      // 控制是否必填 mobile email rules
      if (this.formModal.regType === 1) {
        this.rules.mobile[0].required = false;
        this.rules.email[0].required = true;
      } else if (this.formModal.regType === 2) {
        this.rules.mobile[0].required = false;
        this.rules.email[0].required = false;
      } else {
        this.rules.email[0].required = false;
        this.rules.mobile[0].required = true;
      }
    },
    handleVirtualFlag: function handleVirtualFlag() {
      // 控制是否必填 mobile email rules 全部
      if (this.formModal.virtualFlag === 1) {
        this.rules.mobile[0].required = false;
        this.rules.email[0].required = false;
      } else {
        this.handleRegTypeChange();
      }
    },
    // 手机号码区号改变
    handlePhoneCodeChange: function handlePhoneCodeChange() {
      this.$refs.formModal.validateField('countryCode');
    },
    // 获取粉丝列表
    memberPostFollow: function memberPostFollow() {
      var _this2 = this;

      memberFollow({
        id: this.formModal.id,
        status: 1
      }).then(function (res) {}).catch(function (res) {
        _this2.$message.error(res.msg);
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      memberUpdateById(this.formModal).then(function (res) {
        _this3.$message.success('修改成功'); // 重置


        _this3.$refs.formModal.resetFields();

        _this3.handleClose();
      }).catch(function (res) {
        _this3.$message.error('修改失败');
      });
    },
    // 上传头像
    handleUploadForm: function handleUploadForm(param) {
      var _this4 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this4.$message.success('上传成功');

        _this4.formModal.header = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    // 添加名人
    settingEvent: function settingEvent() {
      var _this5 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          if (_this5.formModal.id) {
            // 修改名人
            _this5.handleUpdate();
          } else {
            // 新增名人
            memberQuerySave(_this5.formModal).then(function (res) {
              _this5.$message.success('添加成功'); // 重置


              _this5.$refs.formModal.resetFields();

              _this5.handleClose();
            }).catch(function (res) {
              _this5.$message.error('添加失败');
            });
          }
        }
      });
    },
    // 获取爬虫来源列表
    spiderSourceList: function spiderSourceList() {
      var _this6 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this6.comeFrom = res;
      }).catch(function () {});
    }
  }
};