var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "300",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "celebrity" }, [
            _c("div", { staticClass: "celebrity_item" }, [
              _c("div", { staticClass: "lt" }, [_vm._v("logo图片:")]),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        disabled: _vm.formDisable,
                        "http-request": _vm.handleUploadForm,
                        "show-file-list": false,
                        multiple: "",
                      },
                    },
                    [
                      _vm.image
                        ? _c("div", { staticClass: "avatar" }, [
                            _c("img", { attrs: { src: _vm.image } }),
                          ])
                        : _c("div", { staticClass: "upload" }, [
                            _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                            _c("span", [_vm._v("图片地址组合")]),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "celebrity_item" }, [
              _c("div", { staticClass: "lt" }, [_vm._v("标签名称:")]),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "celebrity_item" }, [
              _c("div", { staticClass: "lt" }, [_vm._v("链接地址:")]),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.url,
                      callback: function ($$v) {
                        _vm.url = $$v
                      },
                      expression: "url",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }