var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modoul" }, [
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "formModal",
            attrs: {
              model: _vm.formModal,
              rules: _vm.rules,
              disabled: _vm.formDisable,
              "label-width": "120px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "币种英文简称", prop: "nameEn" } },
              [
                _c("el-input", {
                  staticStyle: { width: "325px" },
                  attrs: { placeholder: "请输入币种英文简称" },
                  model: {
                    value: _vm.formModal.nameEn,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "nameEn", $$v)
                    },
                    expression: "formModal.nameEn",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "币种中文简称", prop: "nameZh" } },
              [
                _c("el-input", {
                  staticStyle: { width: "325px" },
                  attrs: { placeholder: "请输入币种中文简称" },
                  model: {
                    value: _vm.formModal.nameZh,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "nameZh", $$v)
                    },
                    expression: "formModal.nameZh",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "币种英文缩写" } },
              [
                _c("el-input", {
                  staticStyle: { width: "325px" },
                  attrs: { placeholder: "请输入币种名称缩写" },
                  model: {
                    value: _vm.formModal.abbreviation,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "abbreviation", $$v)
                    },
                    expression: "formModal.abbreviation",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "头像", prop: "icon" } },
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      disabled: _vm.formDisable,
                      "http-request": _vm.handleUploadForm,
                      "on-change": _vm.imgSaveToUrl,
                      headers: _vm.myHeaders,
                      "show-file-list": false,
                      multiple: "",
                    },
                  },
                  [
                    _vm.formModal.icon
                      ? _c("div", { staticClass: "avatar" }, [
                          _c("img", { attrs: { src: _vm.formModal.icon } }),
                        ])
                      : _c("div", { staticClass: "upload" }, [
                          _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                          _c("span", [_vm._v("图片地址组合")]),
                        ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "排名", prop: "sort" } },
              [
                _c("el-input", {
                  staticStyle: { width: "325px" },
                  attrs: { placeholder: "请输入排名" },
                  model: {
                    value: _vm.formModal.sort,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "sort", $$v)
                    },
                    expression: "formModal.sort",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "价格", prop: "priceUsd" } },
              [
                _c("el-input", {
                  staticStyle: { width: "325px" },
                  attrs: { placeholder: "请输入价格" },
                  model: {
                    value: _vm.formModal.priceUsd,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "priceUsd", $$v)
                    },
                    expression: "formModal.priceUsd",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "流通市值", prop: "circulationMarket" } },
              [
                _c("el-input", {
                  staticStyle: { width: "325px" },
                  attrs: { placeholder: "请输入流通市值" },
                  model: {
                    value: _vm.formModal.circulationMarket,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "circulationMarket", $$v)
                    },
                    expression: "formModal.circulationMarket",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "基本信息", prop: "synopsis" } },
              [
                _c("ueditor-from", {
                  attrs: { content: _vm.formModal.synopsis },
                  model: {
                    value: _vm.formModal.synopsis,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "synopsis", $$v)
                    },
                    expression: "formModal.synopsis",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form_amonnt clearfix" },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("div", { staticClass: "amonnt_title" }, [
                      _vm._v("发行信息"),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "首次发行时间",
                          prop: "initialIssueDate",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "325px" },
                          attrs: {
                            type: "date",
                            placeholder: "请输入首次发行时间",
                          },
                          model: {
                            value: _vm.formModal.initialIssueDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "initialIssueDate", $$v)
                            },
                            expression: "formModal.initialIssueDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "首次开盘价",
                          prop: "initialOpenPrice",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: { placeholder: "请输入首次开盘价" },
                          model: {
                            value: _vm.formModal.initialOpenPrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "initialOpenPrice", $$v)
                            },
                            expression: "formModal.initialOpenPrice",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "历史最高", prop: "historyPriceMax" } },
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "90px",
                            "margin-right": "10px",
                          },
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formModal.historyPriceMax,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "historyPriceMax", $$v)
                            },
                            expression: "formModal.historyPriceMax",
                          },
                        }),
                        _c("el-date-picker", {
                          staticStyle: { width: "224px" },
                          attrs: {
                            type: "date",
                            placeholder: "请选择历史最高日期",
                          },
                          model: {
                            value: _vm.formModal.historyPriceMaxDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formModal,
                                "historyPriceMaxDate",
                                $$v
                              )
                            },
                            expression: "formModal.historyPriceMaxDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "历史最低", prop: "historyPriceMin" } },
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "90px",
                            "margin-right": "10px",
                          },
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formModal.historyPriceMin,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "historyPriceMin", $$v)
                            },
                            expression: "formModal.historyPriceMin",
                          },
                        }),
                        _c("el-date-picker", {
                          staticStyle: { width: "224px" },
                          attrs: {
                            type: "date",
                            placeholder: "请选择历史最低日期",
                          },
                          model: {
                            value: _vm.formModal.historyPriceMinDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formModal,
                                "historyPriceMinDate",
                                $$v
                              )
                            },
                            expression: "formModal.historyPriceMinDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "首次发行方式",
                          prop: "initialDistribution",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: {
                            placeholder: "请输入首次发行方式 1挖矿 0非挖矿",
                          },
                          model: {
                            value: _vm.formModal.initialDistribution,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formModal,
                                "initialDistribution",
                                $$v
                              )
                            },
                            expression: "formModal.initialDistribution",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "上架交易所数量",
                          prop: "exchangeCount",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: { placeholder: "请输入上架交易所数量" },
                          model: {
                            value: _vm.formModal.exchangeCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "exchangeCount", $$v)
                            },
                            expression: "formModal.exchangeCount",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c("div", { staticClass: "amonnt_title" }, [
                      _vm._v("供应信息"),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "最大供应量", prop: "supplyMax" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: { placeholder: "请输入最大供应量" },
                          model: {
                            value: _vm.formModal.supplyMax,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "supplyMax", $$v)
                            },
                            expression: "formModal.supplyMax",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "最大供应市场",
                          prop: "supplyMarketMax",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: { placeholder: "请输入最大供应市场" },
                          model: {
                            value: _vm.formModal.supplyMarketMax,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "supplyMarketMax", $$v)
                            },
                            expression: "formModal.supplyMarketMax",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "当前供应量", prop: "supplyCurrent" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: { placeholder: "请输入当前供应量" },
                          model: {
                            value: _vm.formModal.supplyCurrent,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "supplyCurrent", $$v)
                            },
                            expression: "formModal.supplyCurrent",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "当前供应市场",
                          prop: "supplyMarketCurrent",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: { placeholder: "请输入当前供应市场" },
                          model: {
                            value: _vm.formModal.supplyMarketCurrent,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formModal,
                                "supplyMarketCurrent",
                                $$v
                              )
                            },
                            expression: "formModal.supplyMarketCurrent",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "流通量", prop: "circulation" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: { placeholder: "请输入流通量" },
                          model: {
                            value: _vm.formModal.circulation,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "circulation", $$v)
                            },
                            expression: "formModal.circulation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "流通率", prop: "circulationRate" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: { placeholder: "请输入流通率" },
                          model: {
                            value: _vm.formModal.circulationRate,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "circulationRate", $$v)
                            },
                            expression: "formModal.circulationRate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "全球总市值占比",
                          prop: "proportionOfGlobalTotal",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "325px" },
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formModal.proportionOfGlobalTotal,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formModal,
                                "proportionOfGlobalTotal",
                                $$v
                              )
                            },
                            expression: "formModal.proportionOfGlobalTotal",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "span",
      {
        staticClass: "dialog-footer clearfix",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", disabled: _vm.formDisable },
            on: { click: _vm.settingEvent },
          },
          [_vm._v("确 定")]
        ),
        _c(
          "el-button",
          {
            attrs: { disabled: _vm.formDisable },
            on: {
              click: function ($event) {
                _vm.setting.visible = false
                _vm.$router.push({
                  name: "Amonnt",
                })
              },
            },
          },
          [_vm._v("取 消")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }