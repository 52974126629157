//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { advPositionQueryList } from '@/api/slideShowPlace';
import { advQuerySave, advQueryUpdateById } from '@/api/article/article';
import { languageType } from '@/utils/enum';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    return {
      languageType: languageType,
      formModal: {
        id: '',
        // 广告id
        apId: '',
        // 广告位置
        title: '',
        // 轮播图名称
        url: '',
        // 头像
        resUrl: '',
        // 轮播图链接
        resUrlAndroid: '',
        // 轮播图链接
        remark: '',
        // 轮播图备注
        resUrlIos: '',
        // 轮播图链接
        language: ['cn'],
        // 语言
        sort: '',
        // 位置排序
        resUrlType: '1',
        // 跳转类型 默认 1 应用跳转 2 外部跳转
        isShow: true,
        // 状态
        icon: '',
        color: '',
        colorText: ''
      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      rules: {
        title: [{
          required: true,
          message: '请输入轮播图名称',
          trigger: 'blur'
        }],
        resUrl: [{
          required: true,
          message: '请输入跳转链接地址',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请输入图片',
          trigger: 'blur'
        }],
        apId: [{
          required: true,
          message: '请输入轮播图位置',
          trigger: 'blur'
        }],
        sort: [{
          required: true,
          message: '请输入排序',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      tableFrom: {
        page: 1,
        limit: 10000,
        name: '',
        // 标题
        code: '' // 是否显示

      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '',
      comeFromcheck: '' // tag 标签结束

    };
  },
  methods: {
    //修改下标
    updapId: function updapId(id) {
      var _this = this;

      this.comeFrom.forEach(function (e) {
        if (e.id == id && e.remark != undefined) {
          _this.comeFromcheck = e.remark; // console.log(e.remark)
        }
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      this.formModal.selfIntroduction.splice(this.formModal.selfIntroduction.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this2 = this;

      if (this.formModal.selfIntroduction.length > 2) {
        this.$message.error('标签最多可以添加3个');
        return;
      }

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this2.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this3 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this3.$message.success('上传成功');

        _this3.formModal.url = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      advPositionQueryList({}).then(function (res) {
        // console.log(res)
        _this4.comeFrom = res;
        console.log(_this4.comeFrom);
      }).catch(function () {});
    },
    handleUpdate: function handleUpdate(formModal) {
      var _this5 = this;

      advQueryUpdateById(formModal).then(function (res) {
        _this5.$message.success('修改成功'); // 重置


        _this5.$refs.formModal.resetFields();

        _this5.handleClose();
      }).catch(function (res) {
        _this5.$message.error('修改失败');
      });
    },
    settingEvent: function settingEvent() {
      var _this6 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var formModal = JSON.parse(JSON.stringify(_this6.formModal));
          formModal.language = "".concat(formModal.language);

          if (formModal.id) {
            _this6.handleUpdate(formModal);
          } else {
            advQuerySave(formModal).then(function (res) {
              _this6.$message.success('添加成功'); // 重置


              _this6.$refs.formModal.resetFields();

              _this6.handleClose();
            }).catch(function (res) {
              _this6.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};