var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialog = true
                },
              },
            },
            [_vm._v("一键关注")]
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.gridData } },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "nickname", label: "昵称", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "header", label: "头像", "min-width": "178" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticClass: "photo",
                          attrs: {
                            src: scope.row.header,
                            "preview-src-list": [scope.row.header],
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [5, 10, 20, 30, 40],
              "page-size": _vm.Integraldata.limit,
              "current-page": _vm.Integraldata.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.Integraldata.total,
            },
            on: {
              "size-change": _vm.IntegralSizeChange,
              "current-change": _vm.IntegralpageChange,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.freezeLoading, type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "一键关注", visible: _vm.dialog },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.fans } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "粉丝数量", "label-width": "120px" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1 },
                    on: { change: _vm.toInteger },
                    model: {
                      value: _vm.fans.count,
                      callback: function ($$v) {
                        _vm.$set(_vm.fans, "count", $$v)
                      },
                      expression: "fans.count",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addloading },
                  on: { click: _vm.addfans },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }