function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { aicurrencyAiqueryPageout, aicurrencyAisave, aicurrencyAiputCurrencyByIds } from '@/api/ai/index';
import addCurrency from './addCurrency';
import addCurrency2 from './addCurrency2';
import { currencyQueryPage } from '@/api/currency/currency';
import { parseTime, handleExport } from '@/utils';
import { languageType } from '@/utils/enum';
export default {
  name: 'CrawlerControl',
  components: {
    addCurrency: addCurrency,
    addCurrency2: addCurrency2
  },
  data: function data() {
    return {
      languageType: languageType,
      formModal: {
        currencyList: [] // 币种列表

      },
      formModal2: {
        currencyList: [] // 币种列表

      },
      tableFrom: {
        page: 1,
        limit: 20,
        currencyId: '',
        type: 1
      },
      translateFlagSelect: [{
        value: 1,
        label: '推送'
      }, {
        value: 2,
        label: '快讯'
      }],
      typeList: [{
        value: 1,
        label: '价格变动生成快讯 '
      }, {
        value: 2,
        label: '生成预测'
      }, {
        value: 3,
        label: '交易所大额交易'
      }, {
        value: 4,
        label: '主流币种'
      }, {
        value: 5,
        label: 'ai预测币种'
      }],
      listData: {
        list: [],
        total: 0
      },
      diaform: {
        title: '编辑'
      },
      crawkerData: {
        currencyId: '',
        newsThresholdUp: '',
        newsThresholdDown: '',
        pushThresholdUp: '',
        pushThresholdDown: ''
      },
      dialogFormVisible: false,
      formLabelWidth: '120px',
      addtype: 0,
      multipleSelection: [],
      options: [],
      //tabs
      activeName: '1',
      currencyList: [],
      loading: false,
      closable: true
    };
  },
  mounted: function mounted() {
    // this.getoptions()
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    handleTopicClose: function handleTopicClose(index, val, id) {
      this.formModal[val].splice(index, 1);

      if (this.formModal[id]) {
        if (Array.isArray(this.formModal[id])) {
          this.formModal[id].splice(index, 1);
        } else {
          var currencyId = this.formModal[id].split(',');
          currencyId.splice(index, 1);
          this.formModal[id] = currencyId;
        }
      }
    },
    handleTopicClose2: function handleTopicClose2(index, val, id) {
      var _this = this;

      console.log(this.formModal2[val][index]);
      var par = {
        ids: []
      };
      par.ids.push(this.formModal2[val][index].id);
      aicurrencyAiputCurrencyByIds(par.ids).then(function (res) {
        _this.$message.success('删除成功');

        _this.getList();
      }); // this.formModal2[val].splice(index, 1)
      // if (this.formModal2[id]) {
      //   if (Array.isArray(this.formModal2[id])) {
      //     this.formModal2[id].splice(index, 1)
      //   } else {
      //     const currencyId = this.formModal2[id].split(',')
      //     currencyId.splice(index, 1)
      //     this.formModal2[id] = currencyId
      //   }
      // }
    },
    handleCurrencyList: function handleCurrencyList(val) {
      // const newArray = [...this.formModal.currencyList, ...val]
      this.formModal.currencyList = val;
      this.formModal.currencyId = this.formModal.currencyList.map(function (item) {
        return item.id;
      });
    },
    handleCurrencyList2: function handleCurrencyList2(val) {
      this.getList(); // const newArray = [...this.formModal.currencyList, ...val]
      // this.formModal2.currencyList = val
      // this.formModal2.currencyId = this.formModal2.currencyList.map((item) => {
      //   return item.id
      // })
    },
    showCurrency: function showCurrency() {
      this.$refs.addCurrency.setting.visible = true;
      this.$refs.addCurrency.setting.title = '选择关联币种';
      this.$refs.addCurrency.coincelebrityRadio = '';
      this.$refs.addCurrency.coincelebrityData = JSON.parse(JSON.stringify(this.formModal.currencyList)) ? JSON.parse(JSON.stringify(this.formModal.currencyList)) : [];
      this.$refs.addCurrency.getList();
    },
    showCurrency2: function showCurrency2() {
      this.$refs.addCurrency2.setting.visible = true;
      this.$refs.addCurrency2.type = this.tableFrom.type;
      this.$refs.addCurrency2.setting.title = '选择关联币种';
      this.$refs.addCurrency2.coincelebrityRadio = '';
      this.$refs.addCurrency2.coincelebrityData = JSON.parse(JSON.stringify(this.formModal2.currencyList)) ? JSON.parse(JSON.stringify(this.formModal2.currencyList)) : [];
      this.$refs.addCurrency2.getList();
    },
    delCelebrity: function delCelebrity(row) {
      var _this2 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      aicurrencyAiputCurrencyByIds(par.ids).then(function (res) {
        _this2.$message.success('删除成功');

        _this2.getList();
      });
    },
    changebianji: function changebianji(e) {
      var foundCurrency = this.currencyList.find(function (currency) {
        return currency.id == e;
      });

      if (foundCurrency) {
        this.crawkerData.abbreviation = foundCurrency.abbreviation;
        console.log(this.crawkerData);
      } else {
        console.log('未找到对象');
      }
    },
    remoteMethod: function remoteMethod(query) {
      var _this3 = this;

      if (query !== '') {
        this.loading = true;
        setTimeout(function () {
          _this3.loading = false;
          var par = {
            abbreviation: query
          };
          currencyQueryPage(par).then(function (res) {
            _this3.loading = false;
            _this3.currencyList = res.list;
            console.log(res);
          }).catch(function () {
            _this3.loading = false;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    formatDateTime3: function formatDateTime3(dateString) {
      var date = new Date(dateString);
      var year = date.getFullYear();
      var month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始

      var day = String(date.getDate()).padStart(2, '0');
      var hours = String(date.getHours()).padStart(2, '0');
      var minutes = String(date.getMinutes()).padStart(2, '0');
      var seconds = String(date.getSeconds()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day, " ").concat(hours, ":").concat(minutes, ":").concat(seconds);
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    deriveList: function deriveList() {
      spiderVideoderiveList(this.tableFrom).then(function (res) {
        handleExport(res, '爬虫列表');
      });
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    getoptions: function getoptions() {
      var _this4 = this;

      spiderSourceQueryList({}).then(function (res) {
        _this4.options = res;
      }).catch(function () {});
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20,
        language: 'cn',
        type: 1
      };
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      this.tableSelection = row;
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    handleFreeze: function handleFreeze(row) {
      console.log(row);
      this.diaform.title = '编辑';
      this.dialogFormVisible = true;
      this.crawkerData = row;
      this.addtype = 1; // this.crawkerData.currencyId = row.abbreviation

      this.formModal.currencyList = [];
      this.formModal.currencyList.push({
        id: row.currencyId,
        abbreviation: row.abbreviation
      });
      this.closable = false;
      console.log(this.formModal.currencyList);
    },
    // 新增
    addCelebrity: function addCelebrity() {
      this.diaform.title = '新增';
      this.closable = true;
      this.formModal.currencyList = [];
      this.crawkerData = {
        newsThresholdUp: 0,
        newsThresholdDown: 0,
        pushThresholdUp: 0,
        pushThresholdDown: 0
      };
      this.dialogFormVisible = true;
      this.addtype = 0;
    },
    // 列表
    getList: function getList() {
      var _this5 = this;

      aicurrencyAiqueryPageout(this.tableFrom).then(function (res) {
        _this5.listData = res;

        if (_this5.tableFrom.type != 1) {
          _this5.formModal2.currencyList = [];
          res.list.forEach(function (e) {
            _this5.formModal2.currencyList.push(e);
          });
        }
      }).catch(function () {});
    },
    crawlerupd2: function crawlerupd2() {
      var _this6 = this;

      var par = _objectSpread(_objectSpread({}, crawkerDataCopy), {}, {
        currencyId: this.formModal.currencyList[0].id,
        abbreviation: this.formModal.currencyList[0].abbreviation
      });

      aicurrencyAisave(par).then(function (res) {
        _this6.dialogFormVisible = false;
        _this6.crawkerData = {};

        _this6.getList();

        _this6.$message.success('修改成功');
      }).catch(function () {
        _this6.$message.error('修改失败');
      });
    },
    // 更新
    crawlerupd: function crawlerupd() {
      var _this7 = this;

      if (this.addtype === 1) {
        this.crawkerData.dataStatus = 1;

        var _crawkerDataCopy = _objectSpread({}, this.crawkerData);

        delete _crawkerDataCopy.currencyId;
        delete _crawkerDataCopy.abbreviation;

        var par = _objectSpread(_objectSpread({}, _crawkerDataCopy), {}, {
          currencyId: this.formModal.currencyList[0].id,
          abbreviation: this.formModal.currencyList[0].abbreviation
        });

        aicurrencyAisave(par).then(function (res) {
          _this7.dialogFormVisible = false;
          _this7.crawkerData = {};

          _this7.getList();

          _this7.$message.success('修改成功');
        }).catch(function () {
          _this7.$message.error('修改失败');
        });
      } else {
        this.crawkerData.dataStatus = 1;

        var _crawkerDataCopy2 = _objectSpread({}, this.crawkerData);

        delete _crawkerDataCopy2.currencyId;
        delete _crawkerDataCopy2.abbreviation;

        var _par = _objectSpread(_objectSpread({}, _crawkerDataCopy2), {}, {
          type: 1,
          dataStatus: 1,
          updateTime: this.getCurrentTime(),
          currencyId: this.formModal.currencyList[0].id,
          abbreviation: this.formModal.currencyList[0].abbreviation
        });

        aicurrencyAisave(_par).then(function (res) {
          _this7.dialogFormVisible = false;
          _this7.crawkerData = {};

          _this7.getList();
        });
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.listData.total = val;
      console.log(this.tableFrom);
      this.getList();
    },
    pageChange: function pageChange(val) {
      this.tableFrom.page = val;
      this.getList();
    },
    getCurrentTime: function getCurrentTime() {
      var now = new Date();
      var year = now.getFullYear();
      var month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始

      var day = String(now.getDate()).padStart(2, '0');
      var hours = String(now.getHours()).padStart(2, '0');
      var minutes = String(now.getMinutes()).padStart(2, '0');
      var seconds = String(now.getSeconds()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hours, ":").concat(minutes, ":").concat(seconds);
    }
  }
};