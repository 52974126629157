var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.image.length <= 8
        ? _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                "http-request": _vm.handleUploadForm,
                "on-change": _vm.imgSaveToUrl,
                headers: _vm.myHeaders,
                "show-file-list": false,
                multiple: "",
              },
            },
            [
              _c("div", { staticClass: "upload" }, [
                _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
                _c("span", [_vm._v("图片地址组合")]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.image
        ? _c(
            "div",
            { staticClass: "image" },
            _vm._l(_vm.image, function (tag, index) {
              return _c("div", { key: index, staticClass: "image-item" }, [
                _c("img", { attrs: { src: tag, alt: "" } }),
                _c(
                  "div",
                  {
                    staticClass: "close",
                    on: {
                      click: function ($event) {
                        return _vm.close(index)
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-close " })]
                ),
              ])
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }