var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "formModal",
              attrs: {
                model: _vm.formModal,
                rules: _vm.rules,
                disabled: _vm.formDisable,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "交易所名称", prop: "nameZh" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: { placeholder: "请输入币种中文简称" },
                    model: {
                      value: _vm.formModal.nameZh,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "nameZh", $$v)
                      },
                      expression: "formModal.nameZh",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "交易对数量", prop: "pairNum" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: { placeholder: "请输入交易对数量" },
                    model: {
                      value: _vm.formModal.pairNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "pairNum", $$v)
                      },
                      expression: "formModal.pairNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "24H成交额", prop: "volUsd" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: { placeholder: "请输入24H成交额" },
                    model: {
                      value: _vm.formModal.volUsd,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "volUsd", $$v)
                      },
                      expression: "formModal.volUsd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "交易所图片", prop: "logo" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        disabled: _vm.formDisable,
                        "http-request": _vm.handleUploadForm,
                        "on-change": _vm.imgSaveToUrl,
                        headers: _vm.myHeaders,
                        "show-file-list": false,
                        multiple: "",
                      },
                    },
                    [
                      _vm.formModal.logo
                        ? _c("div", { staticClass: "avatar" }, [
                            _c("img", { attrs: { src: _vm.formModal.logo } }),
                          ])
                        : _c("div", { staticClass: "upload" }, [
                            _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                            _c("span", [_vm._v("图片地址组合")]),
                          ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排名", prop: "sort" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: { placeholder: "请输入排名" },
                    model: {
                      value: _vm.formModal.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "sort", $$v)
                      },
                      expression: "formModal.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "综合评分", prop: "score" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: { placeholder: "请输入综合评分" },
                    model: {
                      value: _vm.formModal.score,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "score", $$v)
                      },
                      expression: "formModal.score",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "网址", prop: "url" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: { placeholder: "请输入网址" },
                    model: {
                      value: _vm.formModal.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "url", $$v)
                      },
                      expression: "formModal.url",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标签", prop: "label" } },
                [
                  _vm._l(_vm.formModal.label, function (tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        staticStyle: { "margin-right": "10px" },
                        attrs: { closable: "", "disable-transitions": false },
                        on: {
                          close: function ($event) {
                            return _vm.handleTagClose(tag)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(tag) + " ")]
                    )
                  }),
                  _vm.inputVisible
                    ? _c("el-input", {
                        ref: "saveTagInput",
                        staticClass: "input-new-tag",
                        staticStyle: { width: "100px" },
                        attrs: { size: "small" },
                        on: { blur: _vm.handleInputConfirm },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleInputConfirm($event)
                          },
                        },
                        model: {
                          value: _vm.inputValue,
                          callback: function ($$v) {
                            _vm.inputValue = $$v
                          },
                          expression: "inputValue",
                        },
                      })
                    : _c(
                        "el-button",
                        {
                          staticClass: "button-new-tag",
                          attrs: { size: "small" },
                          on: { click: _vm.showInput },
                        },
                        [_vm._v("添加标签 +")]
                      ),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "基本信息", prop: "synopsis" } },
                [
                  _c("ueditor-from", {
                    attrs: { content: _vm.formModal.synopsis },
                    model: {
                      value: _vm.formModal.synopsis,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "synopsis", $$v)
                      },
                      expression: "formModal.synopsis",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "成立时间", prop: "launchedTime" } },
                [
                  _c("el-date-picker", {
                    attrs: { type: "date", placeholder: "请选择成立时间" },
                    model: {
                      value: _vm.formModal.launchedTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "launchedTime", $$v)
                      },
                      expression: "formModal.launchedTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册地", prop: "country" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "325px" },
                    attrs: { placeholder: "请输入注册地" },
                    model: {
                      value: _vm.formModal.country,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "country", $$v)
                      },
                      expression: "formModal.country",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer clearfix",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.formDisable },
              on: { click: _vm.settingEvent },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.formDisable },
              on: {
                click: function ($event) {
                  _vm.setting.visible = false
                  _vm.$router.push({
                    name: "Bourse",
                  })
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _c("addLink", { ref: "addLink", on: { handleLink: _vm.handleLink } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }