//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { advPositionQuerySave, advPositionUpdateById } from '@/api/slideShowPlace';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    return {
      formModal: {
        id: '',
        // 广告id
        name: '',
        // 轮播图名称
        code: '',
        // 取值键
        display: '',
        // 广告展示方式
        type: 0 // 广告类别

      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      displayType: [{
        value: 0,
        label: '幻灯片'
      }, {
        value: 1,
        label: '多广告展示'
      }, {
        value: 2,
        label: '单广告展示'
      }],
      rules: {
        name: [{
          required: true,
          message: '请输入轮播图名称',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入取值键',
          trigger: 'blur'
        }],
        display: [{
          required: true,
          message: '请选择广告展示方式',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      this.formModal.selfIntroduction.splice(this.formModal.selfIntroduction.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this = this;

      if (this.formModal.selfIntroduction.length > 2) {
        this.$message.error('标签最多可以添加3个');
        return;
      }

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this2 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      console.log(formData, param.file, 'formData');
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this2.$message.success('上传成功');

        _this2.formModal.url = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      advPositionUpdateById(this.formModal).then(function (res) {
        _this3.$message.success('修改成功'); // 重置


        _this3.$refs.formModal.resetFields();

        _this3.handleClose();
      }).catch(function (res) {
        _this3.$message.error('修改失败');
      });
    },
    settingEvent: function settingEvent() {
      var _this4 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          if (_this4.formModal.id) {
            _this4.handleUpdate();
          } else {
            advPositionQuerySave(_this4.formModal).then(function (res) {
              _this4.$message.success('添加成功'); // 重置


              _this4.$refs.formModal.resetFields();

              _this4.handleClose();
            }).catch(function (res) {
              _this4.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};