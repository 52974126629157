var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modoul" }, [
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "formModal",
            attrs: {
              model: _vm.formModal,
              rules: _vm.rules,
              disabled: _vm.formDisable,
              "label-width": "120px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "板块名称", prop: "nameZh" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入币种中文简称" },
                  model: {
                    value: _vm.formModal.nameZh,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "nameZh", $$v)
                    },
                    expression: "formModal.nameZh",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "热门", prop: "hotFlag" } },
              [
                _c("el-switch", {
                  attrs: { value: Boolean(_vm.formModal.hotFlag) },
                  on: { change: _vm.handleHotFlag },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "热门推荐日期", prop: "hotDate" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "datetime",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    placeholder: "请输入热门推荐日期",
                  },
                  model: {
                    value: _vm.formModal.hotDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "hotDate", $$v)
                    },
                    expression: "formModal.hotDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "热门推荐理由", prop: "hotReason" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 2,
                    placeholder: "请输入热门推荐理由",
                  },
                  model: {
                    value: _vm.formModal.hotReason,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "hotReason", $$v)
                    },
                    expression: "formModal.hotReason",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "币种总量", prop: "curNum" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入币种总量" },
                  model: {
                    value: _vm.formModal.curNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "curNum", $$v)
                    },
                    expression: "formModal.curNum",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "基本信息", prop: "synopsis" } },
              [
                _c("ueditor-from", {
                  attrs: { content: _vm.formModal.synopsis },
                  model: {
                    value: _vm.formModal.synopsis,
                    callback: function ($$v) {
                      _vm.$set(_vm.formModal, "synopsis", $$v)
                    },
                    expression: "formModal.synopsis",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "span",
      {
        staticClass: "dialog-footer clearfix",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", disabled: _vm.formDisable },
            on: { click: _vm.settingEvent },
          },
          [_vm._v("确 定")]
        ),
        _c(
          "el-button",
          {
            attrs: { disabled: _vm.formDisable },
            on: {
              click: function ($event) {
                _vm.setting.visible = false
                _vm.$router.push({
                  name: "Plate",
                })
              },
            },
          },
          [_vm._v("取 消")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }