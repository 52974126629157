import request from '@/utils/request'; // 内容文章版块 === 讯息 资讯管理版块
// 内容文章删除

export function articleDeleteByIds(data) {
  return request({
    url: '/cms/content/article/auth/article/deleteByIds',
    method: 'post',
    data: data
  });
} // 内容文章主键查询

export function articleGetById(data) {
  return request({
    url: '/cms/content/article/auth/article/getById',
    method: 'post',
    data: data
  });
} // 内容文章列表查询

export function articleQueryList(data) {
  return request({
    url: '/cms/content/article/auth/article/queryList',
    method: 'post',
    data: data
  });
} // 内容文章分页列表

export function articleQueryPage(data) {
  return request({
    url: '/cms/content/article/auth/article/queryPage',
    method: 'post',
    data: data
  });
} // 内容文章保存

export function articleQuerySave(data) {
  return request({
    url: '/cms/content/article/auth/article/save',
    method: 'post',
    data: data
  });
} // 内容文章修改

export function articleQueryUpdateById(data) {
  return request({
    url: '/cms/content/article/auth/article/updateById',
    method: 'post',
    data: data
  });
} // 内容文章修改

export function articleBatchUpdateById(data) {
  return request({
    url: '/cms/content/article/auth/article/batchUpdateById',
    method: 'post',
    data: data
  });
} // 内容文章版块 === 讯息 资讯管理版块
// 内容标签删除

export function authByIds(data) {
  return request({
    url: '/cms/content/tag/auth/tag/deleteByIds',
    method: 'post',
    data: data
  });
} // 内容标签主键查询

export function authGetById(data) {
  return request({
    url: '/cms/content/tag/auth/tag/getById',
    method: 'post',
    data: data
  });
} // 内容标签列表查询

export function authQueryList(data) {
  return request({
    url: '/cms/content/tag/auth/tag/queryList',
    method: 'post',
    data: data
  });
} // 内容标签分页列表

export function authQueryPage(data) {
  return request({
    url: '/cms/content/tag/auth/tag/queryPage',
    method: 'post',
    data: data
  });
} // 内容标签保存

export function authQuerySave(data) {
  return request({
    url: '/cms/content/tag/auth/tag/save',
    method: 'post',
    data: data
  });
} // 内容标签修改

export function authQueryUpdateById(data) {
  return request({
    url: '/cms/content/tag/auth/tag/updateById',
    method: 'post',
    data: data
  });
} // 内容文章版块 === 讯息 资讯管理轮播图版块
// 广告批量删除

export function advBatchUpdateById(data) {
  return request({
    url: '/cms/content/auth/adv/batchUpdateById',
    method: 'post',
    data: data
  });
} // 广告删除

export function advDeleteByIds(data) {
  return request({
    url: '/cms/content/auth/adv/deleteByIds',
    method: 'post',
    data: data
  });
} // 广告主键查询

export function advGetById(data) {
  return request({
    url: '/cms/content/auth/adv/getById',
    method: 'post',
    data: data
  });
} // 广告列表查询

export function advQueryList(data) {
  return request({
    url: '/cms/content/auth/adv/queryList',
    method: 'post',
    data: data
  });
} // 广告分页列表

export function advQueryPage(data) {
  return request({
    url: '/cms/content/auth/adv/queryPage',
    method: 'post',
    data: data
  });
} // 广告保存

export function advQuerySave(data) {
  return request({
    url: '/cms/content/auth/adv/save',
    method: 'post',
    data: data
  });
} // 广告修改

export function advQueryUpdateById(data) {
  return request({
    url: '/cms/content/auth/adv/updateById',
    method: 'post',
    data: data
  });
} // 内容文章版块 === 讯息 资讯管理轮播图版块
// 资讯管理分类删除

export function categoryDeleteByIds(data) {
  return request({
    url: '/cms/content/category/auth/category/deleteByIds',
    method: 'post',
    data: data
  });
} // 资讯管理分类主键查询

export function categoryGetById(data) {
  return request({
    url: '/cms/content/category/auth/category/getById',
    method: 'post',
    data: data
  });
} // 资讯管理分类列表查询

export function categoryQueryList(data) {
  return request({
    url: '/cms/content/category/auth/category/queryList',
    method: 'post',
    data: data
  });
} // 资讯管理分类分页列表

export function categoryQueryPage(data) {
  return request({
    url: '/cms/content/category/auth/category/queryPage',
    method: 'post',
    data: data
  });
} // 资讯管理分类保存

export function categoryQuerySave(data) {
  return request({
    url: '/cms/content/category/auth/category/save',
    method: 'post',
    data: data
  });
} // 资讯管理分类修改

export function categoryQueryUpdateById(data) {
  return request({
    url: '/cms/content/category/auth/category/updateById',
    method: 'post',
    data: data
  });
}