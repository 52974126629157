import request from '@/utils/request'; // 看板统计

export function memberLoginLogStat(data) {
  return request({
    url: '/memberLoginLog/auth/stat',
    method: 'post',
    data: data
  });
} // 看板统计

export function memberLoginLogQueryPage(data) {
  return request({
    url: '/memberLoginLog/auth/queryPage',
    method: 'post',
    data: data
  });
} // 看板统计

export function memberLoginLogCharts(data) {
  return request({
    url: '/memberLoginLog/auth/charts',
    method: 'post',
    data: data
  });
} // 看板导出

export function memberLoginLogExport(data) {
  return request({
    url: '/memberLoginLog/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}