var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "famous" }, [
    _c("div", { staticClass: "famous_header" }, [
      _c("div", { staticClass: "famous_lt" }, [
        _c(
          "div",
          { staticClass: "famous_item", staticStyle: { display: "none" } },
          [
            _c("div", { staticClass: "text" }, [_vm._v("版本：")]),
            _c("el-input", {
              attrs: { placeholder: "请输入相关昵称" },
              model: {
                value: _vm.tableFrom.version,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "version", $$v)
                },
                expression: "tableFrom.version",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "famous_btn" }, [
      _c(
        "div",
        { staticClass: "famouts_btn_lt" },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handlerDelete } },
            [_vm._v("删除")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "famouts_btn_rt" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addCelebrity } },
            [_vm._v("添加菜单")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "famous_content" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.listData,
              size: "mini",
              "max-height": "500",
              "highlight-current-row": "",
              "tooltip-effect": "dark",
              "header-cell-style": { "text-align": "center" },
              "cell-style": { "text-align": "center" },
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _c("el-table-column", {
              attrs: { type: "index", index: _vm.indexMethod, label: "排序" },
            }),
            _c("el-table-column", { attrs: { prop: "menuId", label: "ID" } }),
            _c("el-table-column", { attrs: { prop: "name", label: "名称" } }),
            _c("el-table-column", {
              attrs: { prop: "parentName", label: "父菜单路由" },
            }),
            _c("el-table-column", {
              attrs: { prop: "url", label: "路由地址" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "120", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addCelebrity(scope.row)
                            },
                          },
                        },
                        [_vm._v(" 编辑 ")]
                      ),
                      scope.row.type == 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showdio(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 按钮管理 ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: { title: "按钮管理", visible: _vm.dialogTableVisible },
            on: {
              "update:visible": function ($event) {
                _vm.dialogTableVisible = $event
              },
            },
          },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.addCelebrity } },
              [_vm._v("添加按钮")]
            ),
            _c(
              "el-table",
              { attrs: { data: _vm.dialist } },
              [
                _c("el-table-column", {
                  attrs: { prop: "menuId", label: "ID" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "name", label: "名称" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "perms", label: "按钮权限" },
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "120", fixed: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.addCelebrity(scope.row, 1)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.dellist(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("addDynamic", { ref: "addDynamic", on: { getList: _vm.getList } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }