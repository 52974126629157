var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "famous" }, [
    _c("div", { staticClass: "famous_header" }, [
      _c("div", { staticClass: "famous_lt" }, [
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("时间：")]),
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                "range-separator": "至",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期",
                format: "yyyy-MM-dd",
                "value-format": "yyyy-MM-dd",
              },
              on: { change: _vm.settimes },
              model: {
                value: _vm.value1,
                callback: function ($$v) {
                  _vm.value1 = $$v
                },
                expression: "value1",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("用户名：")]),
            _c("el-input", {
              model: {
                value: _vm.tableFrom.nickname,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "nickname", $$v)
                },
                expression: "tableFrom.nickname",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("是否有用：")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.tableFrom.usefulFlag,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "usefulFlag", $$v)
                  },
                  expression: "tableFrom.usefulFlag",
                },
              },
              _vm._l(_vm.translateFlagSelect, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "famous_rt" },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerState } },
            [_vm._v("筛选")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "famous_btn" }, [
      _c(
        "div",
        { staticClass: "famouts_btn_rt" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.deriveList } },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "famous_content" },
      [
        _c(
          "el-table",
          {
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.listData.list,
              size: "mini",
              "highlight-current-row": "",
              "tooltip-effect": "dark",
              "header-cell-style": { "text-align": "center" },
              "cell-style": { "text-align": "center" },
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
            _c("el-table-column", {
              attrs: { label: "时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDateTime3(scope.row.createTime)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "nickname", label: "用户名" },
            }),
            _c("el-table-column", {
              attrs: { prop: "question", label: "用户提问" },
            }),
            _c("el-table-column", {
              attrs: { prop: "answer", label: "AI回答" },
            }),
            _c("el-table-column", {
              attrs: { label: "是否有用" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.usefulFlag == 1
                        ? _c("div", [_vm._v("是")])
                        : _vm._e(),
                      scope.row.usefulFlag == 0
                        ? _c("div", [_vm._v("否")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "usefulFlagText", label: "反馈内容" },
            }),
            _c("el-table-column", {
              attrs: { label: "客户端" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.terminal == 0
                        ? _c("div", [_vm._v("安卓")])
                        : _vm._e(),
                      scope.row.terminal == 1
                        ? _c("div", [_vm._v("IOS")])
                        : _vm._e(),
                      scope.row.terminal == 2
                        ? _c("div", [_vm._v("小程序")])
                        : _vm._e(),
                      scope.row.terminal == 3
                        ? _c("div", [_vm._v("H5")])
                        : _vm._e(),
                      scope.row.terminal == 4
                        ? _c("div", [_vm._v("PC")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "120", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      !scope.row.freezeTime
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleFreeze(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 详情 ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: { title: _vm.diaform.title, visible: _vm.dialogFormVisible },
            on: {
              "update:visible": function ($event) {
                _vm.dialogFormVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              { attrs: { model: _vm.crawkerData } },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "时间：",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatDateTime3(_vm.crawkerData.createTime)
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "用户名：",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.crawkerData.nickname) + " ")]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "用户提问：",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.crawkerData.question) + " ")]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "AI回答：",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.crawkerData.answer) + " ")]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "是否有用：",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _vm.crawkerData.usefulFlag == 1
                      ? _c("div", [_vm._v("是")])
                      : _vm._e(),
                    _vm.crawkerData.usefulFlag == 0
                      ? _c("div", [_vm._v("否")])
                      : _vm._e(),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "反馈内容：",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.crawkerData.usefulFlagText) + " ")]
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "客户端：",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _vm.crawkerData.terminal == 0
                      ? _c("div", [_vm._v("安卓")])
                      : _vm._e(),
                    _vm.crawkerData.terminal == 1
                      ? _c("div", [_vm._v("IOS")])
                      : _vm._e(),
                    _vm.crawkerData.terminal == 2
                      ? _c("div", [_vm._v("小程序")])
                      : _vm._e(),
                    _vm.crawkerData.terminal == 3
                      ? _c("div", [_vm._v("H5")])
                      : _vm._e(),
                    _vm.crawkerData.terminal == 4
                      ? _c("div", [_vm._v("PC")])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogFormVisible = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.crawlerupd } },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "block mb20" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [5, 10, 20, 30, 40],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.listData.totalCount,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }