var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "话题", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入话题名称" },
                        model: {
                          value: _vm.formModal.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "title", $$v)
                          },
                          expression: "formModal.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.formModal.des,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "des", $$v)
                          },
                          expression: "formModal.des",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头像", prop: "pic" } },
                    [
                      _c(
                        "div",
                        { staticClass: "right_list" },
                        [
                          _c(
                            "el-link",
                            { attrs: { type: "danger", disabled: "" } },
                            [_vm._v("图片比例1:1")]
                          ),
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "http-request": _vm.handleUploadForm,
                                "on-change": _vm.imgSaveToUrl,
                                headers: _vm.myHeaders,
                                "show-file-list": false,
                                multiple: "",
                              },
                            },
                            [
                              _vm.formModal.pic
                                ? _c("div", { staticClass: "avatar" }, [
                                    _c("img", {
                                      attrs: { src: _vm.formModal.pic },
                                    }),
                                  ])
                                : _c("div", { staticClass: "upload" }, [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                                    _c("span", [_vm._v("图片地址组合")]),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字", prop: "keyword" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入关键字" },
                        model: {
                          value: _vm.formModal.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "keyword", $$v)
                          },
                          expression: "formModal.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入排序" },
                        model: {
                          value: _vm.formModal.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "sort", $$v)
                          },
                          expression: "formModal.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "虚拟浏览量", prop: "visitNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.visitNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "visitNum", $$v)
                          },
                          expression: "formModal.visitNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "hotEnable" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.formModal.hotEnable,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "hotEnable", $$v)
                            },
                            expression: "formModal.hotEnable",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.formModal.hotEnable,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "hotEnable", $$v)
                            },
                            expression: "formModal.hotEnable",
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }