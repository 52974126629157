//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { plateSave, plateUpdateById } from '@/api/plate/plate';
import ueditorFrom from '@/components/ueditorFrom';
export default {
  name: 'AddPlate',
  components: {
    ueditorFrom: ueditorFrom
  },
  data: function data() {
    return {
      formModal: {
        id: '',
        // id
        nameZh: '',
        // 交易所名称
        pairNum: '',
        // 交易对数量
        volUsd: '',
        // 24H成交额
        logo: '',
        // 交易所图片
        // sortByRank: '', // 序号
        sort: '',
        // 排名
        score: '',
        // 综合评分
        url: '',
        // 网址
        label: [],
        // 标签
        synopsis: '',
        // 基本信息
        launchedTime: '',
        // 成立时间
        country: '',
        // 注册地
        dataStatus: 1 // 状态

      },
      formDisable: false,
      // 禁用状态
      statue: '',
      rules: {
        nameZh: [{
          required: true,
          message: '请输入板块名称',
          trigger: 'blur'
        }],
        pairNum: [{
          required: true,
          message: '请输入币种总量',
          trigger: 'blur'
        }],
        hotReason: [{
          required: true,
          message: '请输入热门推荐理由',
          trigger: 'blur'
        }],
        hotDate: [{
          required: true,
          message: '请输入热门推荐日期',
          trigger: 'blur'
        }],
        synopsis: [{
          required: true,
          message: '请输入基本信息',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.formDisable = JSON.parse(decodeURIComponent(this.$route.query.formDisable));
    this.formModal = JSON.parse(decodeURIComponent(this.$route.query.formModal));
    this.statue = this.$route.query.statue;

    if (this.statue !== 1) {
      this.$route.meta.alias = '编辑板块'; // this.formModal.label = this.formModal.label.split(',')
    } else {
      this.$route.meta.alias = '新增板块';
    } // this.handleHotFlag()

  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    handleHotFlag: function handleHotFlag() {
      this.formModal.hotFlag = this.formModal.hotFlag ^ 1;

      if (this.formModal.hotFlag) {
        this.rules.hotDate[0].required = true;
        this.rules.hotReason[0].required = true;
      } else {
        this.rules.hotDate[0].required = false;
        this.rules.hotReason[0].required = false;
      }
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      this.formModal.label.splice(this.formModal.label.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this = this;

      if (this.formModal.label.length > 2) {
        this.$message.error('标签最多可以添加3个');
        return;
      }

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.label.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this2 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      console.log(formData, param.file, 'formData');
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this2.$message.success('上传成功');

        _this2.formModal.logo = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this3 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var formModal = JSON.parse(JSON.stringify(_this3.formModal));

          if (_this3.statue !== 1) {
            // 编辑
            plateUpdateById(formModal).then(function (res) {
              _this3.$message.success('编辑成功'); // 重置
              // this.$refs.formModal.resetFields()
              // this.handleClose()


              _this3.$router.push({
                name: 'Plate'
              });
            }).catch(function (res) {
              _this3.$message.error('编辑失败');
            }); // this.formModal.label = this.formModal.label.split(',')
          } else {
            // 新增
            plateSave(formModal).then(function (res) {
              _this3.$message.success('添加成功'); // 重置


              _this3.$refs.formModal.resetFields();

              _this3.$router.push({
                name: 'Plate'
              }); // this.handleClose()

            }).catch(function (res) {
              _this3.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};