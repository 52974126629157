//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { menuList } from '@/api/menu'; // import { validUsername } from '@/utils/validate'

import SocialSign from './components/SocialSignin';
export default {
  name: 'Login',
  components: {
    SocialSign: SocialSign
  },
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入用户名'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };

    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {}
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;

        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {// window.addEventListener('storage', this.afterQRScan)
  },
  mounted: function mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  destroyed: function destroyed() {// window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      // console.log(String(this.loginForm.username))
      // console.log(String(this.loginForm.password))
      // return false
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _this2.$store.dispatch('user/login', _this2.loginForm).then(function () {
            menuList({}).then(function (res) {
              // var routerList = []
              res.data[0].list.forEach(function (e) {
                // console.log(e.routeName.indexOf('famousPerson') !== -1)
                if (e.routeName !== undefined) {
                  if (e.routeName.indexOf('famousPerson') !== -1) {// this.$router.addRout({
                    //   name: e.routeName,
                    //   path: e.routeName,
                    //   meta: {
                    //     title: e.name
                    //   },
                    //   component: () => import(e.url)
                    // })
                    // routerList.push({
                    //   path: e.routeName,
                    //   name: e.routeName,
                    //   component: e.url,
                    //   meta: [{
                    //     title: e.name
                    //   }]
                    // })
                  }
                } // if (e.routeName.indexOf('famousPerson')) {
                // }

              });
            });

            _this2.$router.push({
              path: _this2.redirect || '/',
              query: _this2.otherQuery
            });

            _this2.loading = false;
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          console.log(valid, 'error submit!!');
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }

        return acc;
      }, {});
    } // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }

  }
};