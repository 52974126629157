var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.formModal.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "name", $$v)
                          },
                          expression: "formModal.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签", prop: "selfIntroduction" } },
                    [
                      _vm._l(_vm.formModal.selfIntroduction, function (tag) {
                        return _c(
                          "el-tag",
                          {
                            key: tag,
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              closable: "",
                              "disable-transitions": false,
                            },
                            on: {
                              close: function ($event) {
                                return _vm.handleTagClose(tag)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(tag) + " ")]
                        )
                      }),
                      _vm.inputVisible
                        ? _c("el-input", {
                            ref: "saveTagInput",
                            staticClass: "input-new-tag",
                            staticStyle: { width: "100px" },
                            attrs: { size: "small" },
                            on: { blur: _vm.handleInputConfirm },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleInputConfirm($event)
                              },
                            },
                            model: {
                              value: _vm.inputValue,
                              callback: function ($$v) {
                                _vm.inputValue = $$v
                              },
                              expression: "inputValue",
                            },
                          })
                        : _c(
                            "el-button",
                            {
                              staticClass: "button-new-tag",
                              attrs: { size: "small" },
                              on: { click: _vm.showInput },
                            },
                            [_vm._v("添加标签 +")]
                          ),
                    ],
                    2
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头像", prop: "profilePhoto" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "http-request": _vm.handleUploadForm,
                            "on-change": _vm.imgSaveToUrl,
                            headers: _vm.myHeaders,
                            "show-file-list": false,
                            multiple: "",
                          },
                        },
                        [
                          _vm.formModal.profilePhoto
                            ? _c("div", { staticClass: "avatar" }, [
                                _c("img", {
                                  attrs: { src: _vm.formModal.profilePhoto },
                                }),
                              ])
                            : _c("div", { staticClass: "upload" }, [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                                _c("span", [_vm._v("图片地址组合")]),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "简介", prop: "introduction" } },
                    [
                      _c("el-input", {
                        staticClass: "textarea",
                        attrs: {
                          "show-word-limit": "",
                          rows: "4",
                          maxlength: "200",
                          type: "textarea",
                          placeholder: "请输入该名人介绍信息",
                        },
                        model: {
                          value: _vm.formModal.introduction,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "introduction", $$v)
                          },
                          expression: "formModal.introduction",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "虚拟总获赞量", prop: "likeNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.likeNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "likeNum", $$v)
                          },
                          expression: "formModal.likeNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "虚拟总获评量", prop: "discussNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.discussNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "discussNum", $$v)
                          },
                          expression: "formModal.discussNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "虚拟总被分享量", prop: "forwardNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.forwardNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "forwardNum", $$v)
                          },
                          expression: "formModal.forwardNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "榜单排序", prop: "sort" } },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "根据输入值的大小进行排序,输入的值越小排在越前面",
                            placement: "top-start",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.formModal.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModal, "sort", $$v)
                              },
                              expression: "formModal.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推荐状态", prop: "isGood" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.formModal.isGood,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "isGood", $$v)
                            },
                            expression: "formModal.isGood",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.formModal.isGood,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "isGood", $$v)
                            },
                            expression: "formModal.isGood",
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }