import request from '@/utils/request'; // 会员版块
// 会员修改

export function memberDeleteByIds(data) {
  return request({
    url: '/ums/member/auth/member/deleteByIds',
    method: 'post',
    data: data
  });
} // 会员主键查询

export function memberGetById(data) {
  return request({
    url: '/ums/member/auth/member/getById',
    method: 'get',
    data: data
  });
} // 会员列表查询

export function memberQueryList(data) {
  return request({
    url: '/ums/member/auth/member/queryList',
    method: 'post',
    data: data
  });
} // 会员分页列表

export function memberQueryPage(data) {
  return request({
    url: '/ums/member/auth/member/queryPage',
    method: 'post',
    data: data
  });
} // 会员保存

export function memberQuerySave(data) {
  return request({
    url: '/ums/member/auth/member/save',
    method: 'post',
    data: data
  });
} // 会员修改

export function memberUpdateById(data) {
  return request({
    url: '/ums/member/auth/member/updateById',
    method: 'post',
    data: data
  });
} // 会员导出

export function memberExport(data) {
  return request({
    url: '/ums/member/auth/member/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 会员冻结

export function memberFreeze(data) {
  return request({
    url: '/ums/member/auth/member/freeze',
    method: 'post',
    data: data
  });
} // 会员生成抽奖

export function memberCreateDraw(data) {
  return request({
    url: '/ums/member/auth/member/createDraw',
    method: 'post',
    data: data
  });
} // 会员粉丝列表

export function memberFollow(data) {
  return request({
    url: '/memberFollow/auth/memberFollow/queryPage',
    method: 'post',
    data: data
  });
} // 会员新增粉丝

export function addMemberFollow(data) {
  return request({
    url: '/memberFollow/auth/memberFollow/batchAdd',
    method: 'post',
    data: data
  });
} // 会员积分列表

export function integralFollow(data) {
  return request({
    url: '/memberAccountJournal/auth/queryPage',
    method: 'post',
    data: data
  });
} // 会员积分查询

export function userintegral(data) {
  return request({
    url: '/memberAccountJournal/auth/getById',
    method: 'post',
    data: data
  });
} // 会员发放积分

export function userintegraladd(data) {
  return request({
    url: '/memberAccountJournal/auth/sendPoints',
    method: 'post',
    data: data
  });
} // 会员账户流水汇总

export function userintegrallist(data) {
  return request({
    url: '/memberAccountJournal/auth/summary',
    method: 'post',
    data: data
  });
} // 更新提现状态

export function updateCash(data) {
  return request({
    url: '/ums/member/auth/member/updateCash',
    method: 'post',
    data: data
  });
} // 会员活动奖励提现申请汇总
// export function summary(data) {
//   return request({
//     url: `/memberActivityRewardCash/auth/summary?${data}`,
//     method: 'get'
//   })
// }

export function summary(params) {
  return request({
    url: '/memberActivityRewardCash/auth/summary',
    method: 'get',
    params: params
  });
}
export function withdrawal(data) {
  return request({
    url: '/memberActivityRewardCash/auth/withdrawal',
    method: 'post',
    data: data
  });
}
export function unlock(data) {
  return request({
    url: '/ums/member/auth/member/unlock',
    method: 'post',
    data: data
  });
}
export function restoreByIds(data) {
  return request({
    url: '/ums/member/auth/member/restoreByIds',
    method: 'post',
    data: data
  });
} // 用户标签列表

export function memberLabelqueryPage(data) {
  return request({
    url: 'memberLabel/auth/queryPage',
    method: 'post',
    data: data
  });
} // 用户标签列表

export function savequeryPage(data) {
  return request({
    url: '/memberLabel/auth/save',
    method: 'post',
    data: data
  });
}