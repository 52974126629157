//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberAccount, memberAccountgetById } from '@/api/member/member_zh';
import { integralFollow } from '@/api/member/member';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
import { parseTime } from '@/utils';
export default {
  name: 'UserControl',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        accountType: 1,
        nickname: '',
        memberId: '',
        sortByBalance: '',
        sortByBalanceRule: '' // keywords: ''

      },
      registerType: [{
        value: 1,
        label: '积分'
      }, {
        value: 2,
        label: 'chatgpt'
      }, {
        value: 3,
        label: '翻倍卡'
      }],
      gridData: [],
      dialogIntegral: false,
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: '',
      form: {
        memberId: 0,
        amount: 0,
        remark: ''
      },
      Integraldata: {
        accountId: 0,
        page: 1,
        limit: 20,
        total: 0,
        // accountType: 1,
        remark: ''
      },
      status: 0
    };
  },
  mounted: function mounted() {
    this.spiderSourceList();
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    yesort: function yesort(column) {
      var sortingType = column.order;

      if (sortingType == null) {
        this.tableFrom.sortByBalance = false;
        this.tableFrom.sortByBalanceRule = '';
        this.getList();
      } else if (sortingType == 'ascending') {
        this.tableFrom.sortByBalance = true;
        this.tableFrom.sortByBalanceRule = true;
        this.getList();
      } else {
        this.tableFrom.sortByBalance = true;
        this.tableFrom.sortByBalanceRule = false;
        this.getList();
      }
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20,
        accountType: 1,
        nickname: '',
        memberId: ''
      };
      this.getList();
    },
    getformid: function getformid(id) {
      this.Integraldata.remark = '';
      this.dialogIntegral = true;
      this.Integraldata.accountId = id;
      this.showIntegralList();
    },
    getintegralFollow: function getintegralFollow() {
      var _this = this;

      integralFollow(this.Integraldata).then(function (res) {
        _this.Integraldata.list = res.list;
        _this.Integraldata.total = res.totalCount;
        _this.gridData = res.list;
      }).catch(function () {});
    },
    showIntegralList: function showIntegralList() {
      var _this2 = this;

      integralFollow(this.Integraldata).then(function (res) {
        _this2.gridData = res.list;
        _this2.Integraldata.total = res.totalCount;
        _this2.gridData = res.list;
      }).catch(function () {});
    },
    IntegralSizeChange: function IntegralSizeChange(val) {
      this.Integraldata.limit = val;
      this.showIntegralList();
    },
    IntegralpageChange: function IntegralpageChange(page) {
      this.Integraldata.page = page;
      this.showIntegralList();
    },
    // 列表
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;

      if (this.tableFrom.formatDate) {
        this.tableFrom.formatDate = parseTime(this.tableFrom.formatDate, '{y}-{m}-{d}');
      }

      memberAccount(this.tableFrom).then(function (res) {
        _this3.listData.list = res.list;
        _this3.listData.total = res.totalCount;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    spiderSourceList: function spiderSourceList() {
      var _this4 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this4.comeFrom = res;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleFreeze: function handleFreeze(data) {
      console.log(data);
      var par = {
        id: data.memberId
      };
      memberAccountgetById(par).then(function (res) {});
      this.dialogTableVisible = true;
    }
  }
};