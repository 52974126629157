var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "famous" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "famous_content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.listData.list,
                size: "mini",
                "max-height": "500",
                "highlight-current-row": "",
                "tooltip-effect": "dark",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "sort-change": _vm.yesort,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "title", label: "名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "群头像", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: scope.row.logo, fit: _vm.fit },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "owner", label: "群主" },
              }),
              _c("el-table-column", {
                attrs: { prop: "maxNum", label: "群成员最大数量" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.freezeTime
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getformid(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v(" 查看消息列表 ")]
                            )
                          : _vm._e(),
                        !scope.row.freezeTime
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getformid2(scope.row.id)
                                  },
                                },
                              },
                              [_vm._v(" 查看用户列表 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 20, 30, 40],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.listData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "群聊消息列表", visible: _vm.dialogIntegral },
          on: {
            "update:visible": function ($event) {
              _vm.dialogIntegral = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.gridData, "max-height": "400" } },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { property: "nickname", label: "用户名称" },
              }),
              _c("el-table-column", {
                attrs: { property: "content", label: "聊天内容" },
              }),
              _c("el-table-column", {
                attrs: { label: "时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.parseTime(
                                scope.row.createTime,
                                "{y}-{m}-{d} {h}:{i}:{s}"
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 20, 30, 40],
                  "page-size": _vm.Integraldata.limit,
                  "current-page": _vm.Integraldata.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.Integraldata.total,
                },
                on: {
                  "size-change": _vm.IntegralSizeChange,
                  "current-change": _vm.IntegralpageChange,
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogIntegral = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "群用户列表",
            visible: _vm.dialogIntegral2,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogIntegral2 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.Integraldata } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择用户状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择用户状态" },
                      model: {
                        value: _vm.Integraldata2.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.Integraldata2, "status", $$v)
                        },
                        expression: "Integraldata2.status",
                      },
                    },
                    _vm._l(_vm.userStatus, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "选择用户身份" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择用户身份" },
                      model: {
                        value: _vm.Integraldata2.memberType,
                        callback: function ($$v) {
                          _vm.$set(_vm.Integraldata2, "memberType", $$v)
                        },
                        expression: "Integraldata2.memberType",
                      },
                    },
                    _vm._l(_vm.memberTypeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.showIntegralList2()
                },
              },
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-table",
            { attrs: { data: _vm.gridData2, "max-height": "400" } },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { property: "nickname", label: "名称" },
              }),
              _c("el-table-column", {
                attrs: { property: "memberId", label: "用户ID" },
              }),
              _c("el-table-column", {
                attrs: { label: "用户身份" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.memberType == 1
                          ? _c("div", [_vm._v("管理员")])
                          : _vm._e(),
                        scope.row.memberType == 2
                          ? _c("div", [_vm._v("群主")])
                          : _vm._e(),
                        scope.row.memberType == 0
                          ? _c("div", [_vm._v("普通用户")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _vm._v(_vm._s(_vm.parseTime(scope.row.createTime))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { property: "reason", label: "申请原因" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showdio(
                                  scope.row.memberId,
                                  scope.row.groupId,
                                  scope.row.id
                                )
                              },
                            },
                          },
                          [_vm._v(" 审核 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 20, 30, 40],
                  "page-size": _vm.Integraldata2.limit,
                  "current-page": _vm.Integraldata2.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.Integraldata2.total,
                },
                on: {
                  "size-change": _vm.Integral2SizeChange,
                  "current-change": _vm.Integral2pageChange,
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.upddio2Status()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "用户审核", visible: _vm.dialogIntegral3 },
          on: {
            "update:visible": function ($event) {
              _vm.dialogIntegral3 = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.Integraldata } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择用户状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择用户状态" },
                      model: {
                        value: _vm.UserInfo.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.UserInfo, "status", $$v)
                        },
                        expression: "UserInfo.status",
                      },
                    },
                    _vm._l(_vm.userStatus, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogIntegral3 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.setUserStatus()
                    },
                  },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "famous_header" }, [
      _c("div", { staticClass: "famous_lt" }),
      _c("div", { staticClass: "famous_rt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }