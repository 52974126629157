//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { roleSave, roleUpdate, getRoleInfo } from '@/api/role';
import { menuList } from '@/api/menu/index';
import { authorityTree } from '@/utils/enum';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    return {
      formModal: {
        roleId: '',
        roleName: '',
        // 昵称
        menuIdList: []
      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      authorityTree: authorityTree,
      rules: {
        roleName: [{
          required: true,
          message: '请输入身份名称',
          trigger: 'blur'
        }]
      },
      defaultProps: {
        children: 'list',
        label: 'name'
      },
      phoneCodes: [{
        value: '86',
        label: '中国大陆'
      }, {
        value: '852',
        label: '中国香港'
      }, {
        value: '853',
        label: '中国澳门'
      }, {
        value: '886',
        label: '中国台湾'
      }, {
        value: '1',
        label: '美国'
      }, {
        value: '44',
        label: '英国'
      }, {
        value: '81',
        label: '日本'
      }, {
        value: '82',
        label: '韩国'
      }, {
        value: '65',
        label: '新加坡'
      }, {
        value: '60',
        label: '马来西亚'
      }, {
        value: '61',
        label: '澳大利亚'
      }, {
        value: '64',
        label: '新西兰'
      }, {
        value: '7',
        label: '俄罗斯'
      }],
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '',
      // tag 标签结束
      // 权限列表
      newHorityTree: []
    };
  },
  computed: {},
  mounted: function mounted() {// this.spiderSourceList()
    // this.$refs.tree.setCheckedKeys([])
    // this.getMetuList()
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    handlePhoneCodeChange: function handlePhoneCodeChange() {
      this.$refs.formModal.validateField('countryCode');
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    handleUpdate: function handleUpdate() {
      var _this = this;

      console.log(this.formModal.menuIdList);
      roleUpdate(this.formModal).then(function (res) {
        _this.$message.success('修改成功'); // 重置


        _this.$refs.formModal.resetFields();

        _this.handleClose();
      }).catch(function (res) {
        _this.$message.error('修改失败');
      });
    },
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this2 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this2.$message.success('上传成功');

        _this2.formModal.header = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this3 = this;

      // valid是查看是否设置身份 false是设置身份，true是设置权限
      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          _this3.formModal.menuIdList = _this3.$refs.tree.getCheckedKeys();

          var ban = _this3.$refs.tree.getHalfCheckedKeys();

          ban.forEach(function (e) {
            _this3.formModal.menuIdList.push(e);
          }); // if (this.formModal.menuIdList.length !== 0) {
          //   this.formModal.menuIdList.push(1)
          // }

          if (_this3.formModal.roleId) {
            _this3.handleUpdate();
          } else {
            console.log(_this3.$refs.tree.getCheckedNodes());
            console.log(_this3.$refs.tree.getCheckedKeys());
            roleSave(_this3.formModal).then(function (res) {
              _this3.$message.success('添加成功'); // 重置


              _this3.$refs.formModal.resetFields();

              _this3.handleClose();

              console.log(res, 'res');
            }).catch(function (res) {
              _this3.$message.error('添加失败');
            });
          }
        }
      });
    },
    getMetuList: function getMetuList() {
      var _this4 = this;

      menuList({}).then(function (res) {
        _this4.newHorityTree = res.data;
      }).catch(function () {});
    },
    spiderSourceList: function spiderSourceList() {
      var _this5 = this;

      // console.log(this.formModal.roleId, 'this.formModal.roleId')
      getRoleInfo(this.formModal.roleId).then(function (res) {
        _this5.formModal = Object.assign(_this5.formModal, res.role);

        _this5.$refs.tree.setCheckedKeys(_this5.formModal.menuIdList);
      }).catch(function () {});
    }
  }
};