var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关联名人", prop: "celebrityName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入关联名人" },
                        model: {
                          value: _vm.formModal.celebrityName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "celebrityName", $$v)
                          },
                          expression: "formModal.celebrityName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "来自", prop: "sourceType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formModal.sourceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "sourceType", $$v)
                            },
                            expression: "formModal.sourceType",
                          },
                        },
                        _vm._l(_vm.comeFrom, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容", prop: "content" } },
                    [
                      _c("ueditor-from", {
                        attrs: { content: _vm.formModal.content },
                        model: {
                          value: _vm.formModal.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "content", $$v)
                          },
                          expression: "formModal.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头像", prop: "image" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "http-request": _vm.handleUploadForm,
                            "on-change": _vm.imgSaveToUrl,
                            headers: _vm.myHeaders,
                            "show-file-list": false,
                            multiple: "",
                          },
                        },
                        [
                          _vm.formModal.image
                            ? _c("div", { staticClass: "avatar" }, [
                                _c("img", {
                                  attrs: { src: _vm.formModal.image },
                                }),
                              ])
                            : _c("div", { staticClass: "upload" }, [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                                _c("span", [_vm._v("图片地址组合")]),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "虚拟点赞量", prop: "likeNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.likeNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "likeNum", $$v)
                          },
                          expression: "formModal.likeNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "虚拟转发量", prop: "forwardNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.forwardNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "forwardNum", $$v)
                          },
                          expression: "formModal.forwardNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "dataStatus" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formModal.dataStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "dataStatus", $$v)
                            },
                            expression: "formModal.dataStatus",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.formModal.dataStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "dataStatus", $$v)
                            },
                            expression: "formModal.dataStatus",
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }