import request from '@/utils/request';
/**
 * 文件上传
 * @param data
 */

export function fileFileApi(data, params) {
  return request({
    url: '/admin/upload/file',
    method: 'POST',
    params: params,
    data: data
  });
}
/**
 * 图片上传
 * @param data
 */

export function fileImageApi(data, params, uploadType) {
  if (uploadType === undefined) uploadType = true;
  return request({
    url: uploadType ? '/sys/oss/open/oss/upload' : '/admin/upload/video',
    method: 'POST',
    params: params,
    data: data
  });
}