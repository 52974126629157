import request from '@/utils/request'; // 菜单信息
// 删除

export function menuDelete(menuId) {
  return request({
    url: "/sys/menu/delete/".concat(menuId),
    method: 'post'
  });
} // 菜单信息

export function menuInfo(menuId) {
  return request({
    url: "sys/menu/info/".concat(menuId),
    method: 'get'
  });
} // 所有菜单信息

export function menuList(data) {
  return request({
    url: '/sys/menu/list',
    method: 'post',
    data: data
  });
} // 导航菜单

export function menuNav(params) {
  return request({
    url: '/sys/menu/nav',
    method: 'get'
  });
} // 菜单信息保存

export function menuSave(data) {
  return request({
    url: '/sys/menu/save',
    method: 'post',
    data: data
  });
} // 选择菜单(添加、修改菜单)

export function menuSelect(params) {
  return request({
    url: '/sys/menu/select',
    method: 'get',
    params: params
  });
} // 修改菜单

export function menuUpdate(data) {
  return request({
    url: '/sys/menu/update',
    method: 'post',
    data: data
  });
}