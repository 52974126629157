//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { coincelebritycontentSave } from '@/api/celebrity/celebrity'
import { currencyQueryPage } from '@/api/currency/currency';
import { aicurrencyAiqueryPageout, aicurrencyAisave, aicurrencyAiputCurrencyByIds } from '@/api/ai/index';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        abbreviation: '' // keywords: ''

      },
      setting: {
        // 弹窗
        title: '选择名人',
        visible: false
      },
      type: 2,
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      coincelebrityData: [] // 选中数据

    };
  },
  computed: {
    topicCbox: function topicCbox() {
      if (this.listData) {
        return this.listData.list.filter(function (i) {
          return i.checkbox;
        });
      }

      return [];
    }
  },
  mounted: function mounted() {},
  methods: {
    // table 选中回调
    handleRowClick: function handleRowClick(row, column, event) {// this.coincelebrityData = row
    },
    handerTopicBox: function handerTopicBox(val, index) {
      this.listData.list.forEach(function (e) {
        e.checkbox = false;
      });
      this.listData.list[index].checkbox = !this.listData.list[index].checkbox;
      this.$set(this.listData.list, index, this.listData.list[index]); // 新增

      if (this.listData.list[index].checkbox) {
        // 合并
        this.coincelebrityData = [];
        this.coincelebrityData.push(val); // 去重

        var nArr = this.coincelebrityData.filter(function (currentValue, currentIndex, selfArr) {
          return selfArr.findIndex(function (x) {
            return x.id === currentValue.id;
          }) === currentIndex;
        });
        this.coincelebrityData = nArr;
      } else {
        // 删除
        var idx = this.coincelebrityData.findIndex(function (item) {
          return item.id === val.id;
        });

        if (idx !== -1) {
          this.coincelebrityData.splice(idx, 1);
        }
      }

      this.$forceUpdate();
    },
    settingEvent: function settingEvent() {
      var _this = this;

      var par = {
        currencyId: this.coincelebrityData[0].id,
        newsThresholdUp: 0,
        newsThresholdDown: 0,
        pushThresholdUp: 0,
        pushThresholdDown: 0,
        type: this.type,
        dataStatus: 1,
        updateTime: this.getCurrentTime(),
        abbreviation: this.coincelebrityData[0].abbreviation
      };
      aicurrencyAisave(par).then(function (res) {
        _this.dialogFormVisible = false;
        _this.crawkerData = {};

        _this.$emit('handleTopicList2', _this.coincelebrityData);

        _this.handleClose();

        _this.$message.success('新增成功');
      }).catch(function () {});
    },
    getCurrentTime: function getCurrentTime() {
      var now = new Date();
      var year = now.getFullYear();
      var month = String(now.getMonth() + 1).padStart(2, '0'); // 月份从0开始

      var day = String(now.getDate()).padStart(2, '0');
      var hours = String(now.getHours()).padStart(2, '0');
      var minutes = String(now.getMinutes()).padStart(2, '0');
      var seconds = String(now.getSeconds()).padStart(2, '0');
      return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hours, ":").concat(minutes, ":").concat(seconds);
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false;
      this.coincelebrityData = []; // 调用父组件 刷新页面
    },
    lookRow: function lookRow() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      currencyQueryPage(this.tableFrom).then(function (res) {
        _this2.listData.list = res.list;
        _this2.listData.total = res.totalCount;
        _this2.listLoading = false;

        _this2.listData.list.forEach(function (v) {
          v.checkbox = false;
        });

        console.log(_this2.coincelebrityData);
        if (!_this2.coincelebrityData) return false;
        var indexArray = [];

        _this2.coincelebrityData.forEach(function (v) {
          var idx = _this2.listData.list.findIndex(function (item) {
            return item.id === v.currencyId;
          });

          if (idx !== -1) {
            indexArray.push(idx);
          }
        });

        console.log(indexArray);

        if (indexArray.length) {
          indexArray.forEach(function (v) {
            _this2.listData.list[v].checkbox = true;
          });
        }
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.coincelebrityRadio = '';
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};