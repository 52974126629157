var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "div",
        [
          _c("span", { staticClass: "radio_type" }, [_vm._v("类型：")]),
          _c(
            "el-radio-group",
            {
              on: { input: _vm.handerTpye },
              model: {
                value: _vm.formModal.type,
                callback: function ($$v) {
                  _vm.$set(_vm.formModal, "type", $$v)
                },
                expression: "formModal.type",
              },
            },
            [
              _c("el-radio", { attrs: { label: 2, border: "" } }, [
                _vm._v("问答"),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "formModal",
              attrs: {
                model: _vm.formModal,
                rules: _vm.rules,
                disabled: _vm.formDisable,
                "label-width": "120px",
              },
            },
            [
              _vm.formModal.type === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入问题并以问号结尾" },
                        model: {
                          value: _vm.formModal.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "title", $$v)
                          },
                          expression: "formModal.title",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "发布人", prop: "createBy" } },
                [
                  _vm.formModal.createBy
                    ? _c(
                        "el-tag",
                        {
                          key: _vm.coincelebrityData.id,
                          staticStyle: { "margin-right": "10px" },
                          attrs: { closable: "", "disable-transitions": false },
                          on: {
                            close: function ($event) {
                              return _vm.handleTagClose(_vm.tag)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.coincelebrityData.nickname) + " "
                          ),
                        ]
                      )
                    : _c(
                        "el-button",
                        {
                          staticClass: "button-new-tag",
                          attrs: { size: "small" },
                          on: { click: _vm.showInput },
                        },
                        [_vm._v("添加发布人 +")]
                      ),
                ],
                1
              ),
              _vm.formModal.type === 1
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "话题",
                        prop: _vm.formModal.type === 1 ? "topicId" : "",
                      },
                    },
                    [
                      _vm.formModal.topicList.length < 4 &&
                      _vm.formModal.topicList.length
                        ? _vm._l(
                            _vm.formModal.topicList,
                            function (tag, index) {
                              return _c(
                                "el-tag",
                                {
                                  key: tag.id,
                                  staticStyle: { "margin-right": "10px" },
                                  attrs: {
                                    closable: "",
                                    "disable-transitions": false,
                                  },
                                  on: {
                                    close: function ($event) {
                                      return _vm.handleTopicClose(
                                        index,
                                        "topicList",
                                        "topicId"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(tag.title) + " ")]
                              )
                            }
                          )
                        : _vm._e(),
                      _vm.formModal.topicList.length < 3
                        ? _c(
                            "el-button",
                            {
                              staticClass: "button-new-tag",
                              attrs: { size: "small" },
                              on: { click: _vm.showTopic },
                            },
                            [_vm._v("添加话题 +")]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "币种" } },
                [
                  _vm._l(_vm.formModal.currencyList, function (tag, index) {
                    return _c(
                      "el-tag",
                      {
                        key: tag.id,
                        staticStyle: { "margin-right": "10px" },
                        attrs: { closable: "", "disable-transitions": false },
                        on: {
                          close: function ($event) {
                            return _vm.handleTopicClose(
                              index,
                              "currencyList",
                              "currencyId"
                            )
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(tag.abbreviation) + " ")]
                    )
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "button-new-tag",
                      attrs: { size: "small" },
                      on: { click: _vm.showCurrency },
                    },
                    [_vm._v("添加币种 +")]
                  ),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自营", prop: "selfFlag" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formModal.selfFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "selfFlag", $$v)
                        },
                        expression: "formModal.selfFlag",
                      },
                    },
                    _vm._l(_vm.articleSelect, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "置顶", prop: "topFlag" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formModal.topFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "topFlag", $$v)
                        },
                        expression: "formModal.topFlag",
                      },
                    },
                    _vm._l(_vm.articleSelect, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formModal.contentType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "contentType", $$v)
                        },
                        expression: "formModal.contentType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { disabled: "", label: 3 } }, [
                        _vm._v("视频"),
                      ]),
                      _c("el-radio", { attrs: { disabled: "", label: 2 } }, [
                        _vm._v("图文结合"),
                      ]),
                      _c("el-radio", { attrs: { disabled: "", label: 1 } }, [
                        _vm._v("文字"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "内容", prop: "content" } },
                [
                  _vm.formModal.type !== 1
                    ? _c("div", { staticClass: "ueditorTip" }, [
                        _vm._v("对问题补充说明，可以更快获得解答（选填）"),
                      ])
                    : _vm._e(),
                  _vm.formModal.type !== 1
                    ? _c("ueditor-from", {
                        attrs: { content: _vm.formModal.content },
                        model: {
                          value: _vm.formModal.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "content", $$v)
                          },
                          expression: "formModal.content",
                        },
                      })
                    : _vm._e(),
                  _vm.formModal.type === 1
                    ? _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.formModal.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "content", $$v)
                          },
                          expression: "formModal.content",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.formModal.type === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "视频", prop: "video" } },
                    [
                      _c("upload-by-oss", {
                        attrs: {
                          "file-size": 0,
                          limit: 0,
                          "file-u-r-l": _vm.formModal.video,
                          "file-array": [],
                          "file-list": _vm.companyUrlList
                            ? _vm.companyUrlList
                            : [],
                        },
                        on: {
                          handleVideoList: _vm.handleVideoList,
                          handleVideo: _vm.handleVideo,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formModal.type === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "头像", prop: "" } },
                    [
                      _c("uploadImage", {
                        attrs: { image: _vm.formModal.image },
                        on: { handleImage: _vm.handleImage },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "超链接", prop: "linkList" } },
                [
                  _vm._l(_vm.linkList, function (tag) {
                    return _c(
                      "el-tag",
                      {
                        key: tag,
                        staticStyle: { "margin-right": "10px" },
                        attrs: { closable: "", "disable-transitions": false },
                        on: {
                          close: function ($event) {
                            return _vm.handleLinkClose(tag)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(tag) + " ")]
                    )
                  }),
                  _vm.linkList.length < 2
                    ? _c(
                        "el-button",
                        {
                          staticClass: "button-new-tag",
                          attrs: { size: "small" },
                          on: { click: _vm.showLink },
                        },
                        [_vm._v("添加超链接 +")]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "虚拟点赞量", prop: "likeNum" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formModal.likeNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "likeNum", $$v)
                      },
                      expression: "formModal.likeNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "虚拟转发量", prop: "forwardNum" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formModal.forwardNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "forwardNum", $$v)
                      },
                      expression: "formModal.forwardNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "虚拟浏览量", prop: "visitNum" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formModal.visitNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "visitNum", $$v)
                      },
                      expression: "formModal.visitNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "语言", prop: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formModal.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "language", $$v)
                        },
                        expression: "formModal.language",
                      },
                    },
                    _vm._l(Object.keys(_vm.languageType), function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: _vm.languageType[item], value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类", prop: "selfFlag" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formModal.categoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "categoryId", $$v)
                        },
                        expression: "formModal.categoryId",
                      },
                    },
                    _vm._l(_vm.articleType, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "showEnable" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: true },
                      model: {
                        value: _vm.formModal.showEnable,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "showEnable", $$v)
                        },
                        expression: "formModal.showEnable",
                      },
                    },
                    [_vm._v("开启")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: false },
                      model: {
                        value: _vm.formModal.showEnable,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "showEnable", $$v)
                        },
                        expression: "formModal.showEnable",
                      },
                    },
                    [_vm._v("隐藏")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否推荐" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.formModal.recommendFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "recommendFlag", $$v)
                        },
                        expression: "formModal.recommendFlag",
                      },
                    },
                    [_vm._v("是")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.formModal.recommendFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "recommendFlag", $$v)
                        },
                        expression: "formModal.recommendFlag",
                      },
                    },
                    [_vm._v("否")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间", prop: "showEnable" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "timestamp",
                      type: "datetime",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.formModal.createTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModal, "createTime", $$v)
                      },
                      expression: "formModal.createTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "none" },
                  attrs: { label: "操作", prop: "auditStatus" },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.formModal.auditStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "auditStatus", $$v)
                        },
                        expression: "formModal.auditStatus",
                      },
                    },
                    [_vm._v("审核通过")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.formModal.auditStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModal, "auditStatus", $$v)
                        },
                        expression: "formModal.auditStatus",
                      },
                    },
                    [_vm._v("审核不通过")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer clearfix",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.formDisable },
              on: { click: _vm.settingEvent },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.formDisable },
              on: {
                click: function ($event) {
                  _vm.setting.visible = false
                  _vm.$router.push({
                    name: "communityQuestion",
                    params: {
                      screening: _vm.tableFrom,
                    },
                  })
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _c("addTopic", {
        ref: "addTopic",
        on: { handleTopicList: _vm.handleTopicList },
      }),
      _c("addIssuer", { ref: "addIssuer", on: { handleList: _vm.handleList } }),
      _c("addLink", { ref: "addLink", on: { handleLink: _vm.handleLink } }),
      _c("addCurrency", {
        ref: "addCurrency",
        on: { handleTopicList: _vm.handleCurrencyList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }