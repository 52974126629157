/* eslint-disable operator-linebreak */

/**
 * @file 类型相关api
 */

/**
 * 判断传入数据是否为 null
 * @param value
 * @returns {boolean}
 */
export var isNull = function isNull(value) {
  return Object.prototype.toString.call(value) === '[object Null]';
};
/**
 * 判断传入数据是否为 undefined
 * @param value
 * @returns {boolean}
 */

export var isUndefined = function isUndefined(value) {
  return Object.prototype.toString.call(value) === '[object Undefined]';
};
/**
 * 判断传入数据是否为 Number
 * @param value
 * @returns {boolean}
 */

export var isNumber = function isNumber(value) {
  return Object.prototype.toString.call(value) === '[object Number]';
};
/**
 * 判断传入数据是否为 Boolean
 * @param value
 * @returns {boolean}
 */

export var isBoolean = function isBoolean(value) {
  return Object.prototype.toString.call(value) === '[object Boolean]';
};
/**
 * 判断传入数据是否为 String
 * @param value
 * @returns {boolean}
 */

export var isString = function isString(value) {
  return Object.prototype.toString.call(value) === '[object String]';
};
/**
 * 判断传入数据是否为 Object
 * @param value
 * @returns {boolean}
 */

export var isObject = function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
};
/**
 * 判断传入数据是否为 Symbol
 * @param value
 * @returns {boolean}
 */

export var isSymbol = function isSymbol(value) {
  return Object.prototype.toString.call(value) === '[object Symbol]';
};
/**
 * 判断传入数据是否为 Array
 * @param value
 * @returns {boolean}
 */

export var isArray = function isArray(value) {
  return Object.prototype.toString.call(value) === '[object Array]';
};
/**
 * 判断传入数据是否为 Function
 * @param value
 * @returns {boolean}
 */

export var isFunction = function isFunction(value) {
  return Object.prototype.toString.call(value) === '[object Function]';
};
/**
 * 判断传入数据是否为 Date
 * @param value
 * @returns {boolean}
 */

export var isDate = function isDate(value) {
  return Object.prototype.toString.call(value) === '[object Date]';
};
/**
 * 判断传入数据是否为 Math
 * @param value
 * @returns {boolean}
 */

export var isMath = function isMath(value) {
  return Object.prototype.toString.call(value) === '[object Math]';
};
/**
 * 判断传入数据是否为 Set
 * @param value
 * @returns {boolean}
 */

export var isSet = function isSet(value) {
  return Object.prototype.toString.call(value) === '[object Set]';
};
/**
 * 判断传入数据是否为 WeakSet
 * @param value
 * @returns {boolean}
 */

export var isWeakSet = function isWeakSet(value) {
  return Object.prototype.toString.call(value) === '[object WeakSet]';
};
/**
 * 判断传入数据是否为 Map
 * @param value
 * @returns {boolean}
 */

export var isMap = function isMap(value) {
  return Object.prototype.toString.call(value) === '[object Map]';
};
/**
 * 判断传入数据是否为 WeakMap
 * @param value
 * @returns {boolean}
 */

export var isWeakMap = function isWeakMap(value) {
  return Object.prototype.toString.call(value) === '[object WeakMap]';
};
/**
 * 获取传入数据的类型
 * @example getType(null) // => "null"
 * @param {any} value
 * @returns {String} 返回传入数据所属类型的小写
 */

export var getType = function getType(value) {
  return Object.prototype.toString.call(value).match(/\s([a-z]+)/i)[1].toLocaleLowerCase();
};
/**
 * 判断传入数据是否为空，包含 undefined、null、空字符串（只有空格的字符串）、空数组、空对象
 * @example isEmpty('   ') // => true
 * @param {*} value 要判断的数据
 * @returns {boolean}
 */

export var isEmpty = function isEmpty(value) {
  if (isUndefined(value) || isNull(value) || isString(value) && value.replace(/(^\s*)|(\s*$)/g, '').length === 0 || isObject(value) && !Object.keys(value).length || isArray(value) && !value.length) {
    return true;
  }

  return false;
};
/**
 * 判断值是否不为空，与isEmpty取反
 * @param {unknown} value 要判断的数据
 * @returns {boolean}
 *
 * @example
 * isNotEmpty(0) // => true
 * isNotEmpty('   ') // => false
 * isNotEmpty({}) // => false
 */

export var isNotEmpty = function isNotEmpty(value) {
  return !isEmpty(value);
};
/**
 * 判断是否为 JSON 可解析的对象格式的字符串
 * @param {*} value
 */

export var isJsonObject = function isJsonObject(value) {
  try {
    return isObject(JSON.parse(value));
  } catch (error) {
    return false;
  }
};