//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { communityContentCommentQueryPage, communityContentCommentDeleteByIds, communityContentCommentAddFeaturedSelection } from '@/api/community/community';
import addComments from './addComments';
export default {
  name: 'AddFamous',
  components: {
    addComments: addComments
  },
  data: function data() {
    return {
      formModal: {
        newsFlashTitle: '',
        // 快讯标题
        flashSourceName: '',
        // 来源名称
        newsFlashContent: '',
        // 内容
        flashUp: '',
        // 虚拟利好量
        flashDown: '',
        // 虚拟利空量
        isShow: true // 状态

      },
      tableFrom: {
        page: 1,
        limit: 20,
        parentId: 0,
        // 父id
        communityContentId: '' // 社区动态 ID
        // keywords: ''

      },
      memberId: '',
      listData: {
        list: [],
        total: 0
      },
      setting: {
        // 弹窗
        title: '查看回答',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  computed: {
    visible: function visible() {
      return this.setting.visible;
    }
  },
  // watch: {
  //   visible(newValue, oldValue) {
  //     if (newValue) {
  //       console.log(newValue, 'Jinlai')
  //       this.getList()
  //     }
  //   }
  // },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    handlerState: function handlerState(statue, val) {
      var _this = this;

      var formDisable = '0';
      var formModal = {
        id: '',
        // id
        // nickname: '', // 昵称
        // header: '', // 头像
        createBy: '',
        // 发布人
        content: '',
        // 内容
        contentMemberId: '',
        likeNum: '' // 点赞数

      };

      if (statue === 3) {
        formDisable = '1'; // 禁止编辑状态
      }

      if (statue !== 1) {
        formModal = val;
      }

      formModal.communityContentId = this.tableFrom.communityContentId;
      formModal.memberId = this.memberId;
      formModal.contentMemberId = this.memberId; // 重置弹窗

      this.$refs.addComments.setting.visible = true;
      this.$nextTick().then(function () {
        _this.$refs.addComments.formModal = formModal;
        _this.$refs.addComments.statue = statue;
        _this.$refs.addComments.formDisable = formDisable;
      });
    },
    premium: function premium(data) {
      var _this2 = this;

      var operationType = 1;

      if (data.selectedFlag == 0) {
        operationType = 2;
      }

      var par = {
        communityContentId: data.communityContentId,
        id: data.id,
        operationType: operationType
      };
      communityContentCommentAddFeaturedSelection(par).then(function (res) {
        _this2.$message.success('设置成功');

        _this2.getList();
      });
    },
    handlerDelete: function handlerDelete(id) {
      var _this3 = this;

      this.$confirm("\u662F\u5426\u5220\u9664\u8BE5\u8BC4\u8BBA", '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '不删除',
        type: 'warning'
      }).then(function () {
        var par = {
          ids: [id]
        };
        communityContentCommentDeleteByIds(par.ids).then(function (res) {
          _this3.$message.success('删除成功');

          _this3.getList();
        });
      }).catch(function () {});
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      console.log(this.tableFrom, 'tableFrom');
      communityContentCommentQueryPage(this.tableFrom).then(function (res) {
        _this4.listData.list = res.list;
        _this4.listData.total = res.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};