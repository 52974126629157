//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { summary, withdrawal } from '@/api/member/member';
import { parseTime } from '@/utils';
export default {
  name: 'UserControl',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        nickname: '',
        account: '',
        unit: '' // keywords: ''

      },
      gridData: [],
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: '',
      form: {
        memberId: 0,
        amount: 0,
        remark: ''
      },
      status: 0
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    withdrawalupd: function withdrawalupd() {
      var _this = this;

      this.$confirm("\u786E\u5B9A\u5168\u90E8\u6279\u91CF\u63D0\u73B0\u4E48\uFF1F", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        withdrawal({}).then(function (res) {
          _this.$message.success('提现成功');
        }).catch(function () {
          _this.$message.error('提现失败');
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88\u63D0\u73B0"
        });
      });
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.gettimedate = '';
      this.tableFrom = {
        page: 1,
        limit: 20,
        accountType: 1,
        nickname: '',
        memberId: '',
        startDate: '',
        endDate: ''
      };
      this.getList();
    },
    IntegralSizeChange: function IntegralSizeChange(val) {
      this.Integraldata.limit = val;
      this.showIntegralList();
    },
    IntegralpageChange: function IntegralpageChange(page) {
      this.Integraldata.page = page;
      this.showIntegralList();
    },
    // 列表
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true; // if (this.tableFrom.formatDate) {
      //   this.tableFrom.formatDate = parseTime(
      //     this.tableFrom.formatDate,
      //     '{y}-{m}-{d}'
      //   )
      // }
      // 'page=1&limit=20'

      summary(this.tableFrom).then(function (res) {
        _this2.listData.list = res.list;
        _this2.listLoading = false;
        _this2.listData.total = res.totalCount;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};