var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "celebrity" }, [
            _c("div", { staticClass: "celebrity_top" }, [
              _c(
                "div",
                { staticClass: "celebrity_lt" },
                [
                  _c("span", [_vm._v("简称:")]),
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.tableFrom.abbreviation,
                      callback: function ($$v) {
                        _vm.$set(_vm.tableFrom, "abbreviation", $$v)
                      },
                      expression: "tableFrom.abbreviation",
                    },
                  }),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.lookRow } },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "celebrity_rt" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.settingEvent },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "celebrity_table" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.listData.list,
                      size: "mini",
                      "max-height": "500",
                      "highlight-current-row": "",
                      "tooltip-effect": "dark",
                      "header-cell-style": { "text-align": "center" },
                      "cell-style": { "text-align": "center" },
                    },
                    on: { "row-click": _vm.handleRowClick },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "nameZh", label: "名称" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "abbreviation", label: "简称" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "slug", label: "标识" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "icon", label: "币种头像" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-image", {
                                staticClass: "photo",
                                attrs: {
                                  src: scope.row.icon,
                                  "preview-src-list": [scope.row.icon],
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "120", fixed: "right" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      attrs: {
                                        value: scope.row.checkbox
                                          ? true
                                          : false,
                                        label: scope.row.checkbox,
                                        border: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.handerTopicBox(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选中")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "block mb20" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "page-sizes": [5, 10, 20, 30, 40],
                        "page-size": _vm.tableFrom.limit,
                        "current-page": _vm.tableFrom.page,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.listData.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.pageChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }