//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 引入VueUeditorWrap组件
import VueUeditorWrap from 'vue-ueditor-wrap'; // ES6 Module

import { ueditorConfig } from '@/api/ueditor';
import { fileImageApi } from '@/api/systemSetting'; // import SettingMer from '@/utils/settingMer'

export default {
  name: 'Index',
  components: {
    VueUeditorWrap: VueUeditorWrap
  },
  scrollerHeight: {
    content: String,
    default: ''
  },
  props: {
    content: {
      type: String,
      default: ''
    } // value: {}

  },
  data: function data() {
    return {
      contents: this.content,
      ueditorConfig: {
        serverUrl: '',
        // 调用上传初始化
        imageMaxSize: '',
        imageAllowFiles: ''
      },
      myConfig: {
        autoHeightEnabled: false,
        // 编辑器不自动被内容撑高
        initialFrameHeight: 500,
        // 初始容器高度
        initialFrameWidth: '100%',
        // 初始容器宽度
        UEDITOR_HOME_URL: 'UEditor/',
        // serverUrl: SettingMer.apiBaseURL + 'sys/ueditor/config', // 调用上传初始化
        // serverUrl: 'http://192.168.10.32:8091/sys/ueditor/todo', // 调用上传初始化
        serverUrl: 'https://api.lianpucom.com/xhj-gather-admin/sys/oss/open/oss/upload/',
        // serverUrl: '',
        'imageFieldName': 'file',
        imageUrlPrefix: '',
        // wordImagePath: 'http://192.168.10.254:8091/sys/ueditor/todo',
        wordImagePath: 'https://api.lianpucom.com/xhj-gather-admin/sys/ueditor/todo',
        'imageActionName': 'upfile',
        'imageMaxSize': 2048000,
        'imageAllowFiles': ['.png', '.jpg', '.jpeg', '.gif', '.bmp'],
        'wordActionName': 'wordupload',

        /* 执行上传视频的action名称 */
        'wordFieldName': 'upfile',

        /* 提交的视频表单名称 */
        // 'wordPathFormat': '/public/uploads/word/{yyyy}{mm}{dd}/{time}{rand:6}', /* 上传保存路径,可以自定义保存路径和文件名格式 */
        'wordMaxSize': 102400000,

        /* 上传大小限制，单位B，默认100MB */
        toolbars: [["fullscreen", // 全屏
        "source", // 源代码
        "|", "undo", // 撤销
        "redo", // 重做
        "|", "bold", // 加粗
        "italic", // 斜体
        "underline", // 下划线
        "fontborder", // 字符边框
        "strikethrough", // 删除线
        "superscript", // 上标
        "subscript", // 下标
        "removeformat", // 清除格式
        "formatmatch", // 格式刷
        "autotypeset", // 自动排版
        "blockquote", // 引用
        "pasteplain", // 纯文本粘贴模式
        "|", "forecolor", // 字体颜色
        "backcolor", // 背景色
        "insertorderedlist", // 有序列表
        "insertunorderedlist", // 无序列表
        "selectall", // 全选
        "cleardoc", // 清空文档
        "|", "rowspacingtop", // 段前距
        "rowspacingbottom", // 段后距
        "lineheight", // 行间距
        "|", "customstyle", // 自定义标题
        "paragraph", // 段落格式
        "fontfamily", // 字体
        "fontsize", // 字号
        "|", "directionalityltr", // 从左向右输入
        "directionalityrtl", // 从右向左输入
        "indent", // 首行缩进
        "|", "justifyleft", // 居左对齐
        "justifycenter", // 居中对齐
        "justifyright", "justifyjustify", // 两端对齐
        "|", "touppercase", // 字母大写
        "tolowercase", // 字母小写
        "|", "link", // 超链接
        "unlink", // 取消链接
        "anchor", // 锚点
        "|", "imagenone", // 图片默认
        "imageleft", // 图片左浮动
        "imageright", // 图片右浮动
        "imagecenter", // 图片居中
        "|", "emotion", // 表情
        "scrawl", // 涂鸦
        "insertvideo", // 视频
        "attachment", // 附件
        "insertframe", // 插入Iframe
        "insertcode", // 插入代码
        "pagebreak", // 分页
        "template", // 模板
        "background", // 背景
        "formula", // 公式
        "|", "horizontal", // 分隔线
        "date", // 日期
        "time", // 时间
        "spechars", // 特殊字符
        "wordimage", // Word图片转存
        "|", "inserttable", // 插入表格
        "deletetable", // 删除表格
        "insertparagraphbeforetable", // 表格前插入行
        "insertrow", // 前插入行
        "deleterow", // 删除行
        "insertcol", // 前插入列
        "deletecol", // 删除列
        "mergecells", // 合并多个单元格
        "mergeright", // 右合并单元格
        "mergedown", // 下合并单元格
        "splittocells", // 完全拆分单元格
        "splittorows", // 拆分成行
        "splittocols", // 拆分成列
        "contentimport", // 内容导入（支持Word、Markdown）
        "|", "print", // 打印
        "preview", // 预览
        "searchreplace", // 查询替换
        "help" // 帮助
        ]]
      }
    };
  },
  watch: {
    content: function content(val) {
      this.contents = this.content;
    },
    contents: function contents(val) {
      this.$emit('input', val);
    }
  },
  beforeMount: function beforeMount() {
    // 接收 v-model 数据
    if (this.value) {
      this.contents = this.value;
    }
  },
  mounted: function mounted() {
    this.getUeditorConfig();
  },
  methods: {
    ready: function ready(i) {
      console.log(i.queryCommandValue('serverparam')); // i.options.serverUrl = ''

      i.options.imageAllowFiles = this.ueditorConfig.imageAllowFiles;
      i.options.imageMaxSize = this.ueditorConfig.imageMaxSize;
      i.options.serverUrl = this.ueditorConfig.serverUrl;
      console.log(window.UE, '初始化', i.isServerConfigLoaded(), i.loadServerConfig());
    },
    getUeditorConfig: function getUeditorConfig() {
      var _this = this;

      var par = {
        action: 'config',
        callback: 'bd__editor__f3p3w7'
      };
      ueditorConfig(par).then(function (res) {
        console.log(res, 'res');
        _this.ueditorConfig.imageAllowFiles = res.imageAllowFiles;
        _this.ueditorConfig.imageMaxSize = res.imageMaxSize;
        _this.ueditorConfig.serverUrl = res.imageUrl;
        console.log(_this.ueditorConfig, 'this.ueditorConfig');
      });
    },
    handleUploadForm: function handleUploadForm(e) {
      var _this2 = this;

      var param = e.target.files[0];
      var formData = new FormData(); // if (param.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this2.$message.success('上传成功');

        var str = '<img style="max-width:100%" src="' + res.url + '">'; // this.$refs.ueditor.editor.setContent(str, true)

        _this2.$refs.ueditor.editor.execCommand('inserthtml', str);

        _this2.$refs.ueditor.dialog.close(true);
      }).catch(function (res) {
        loading.close();
      });
    },
    // 添加自定义弹窗
    addCustomDialog: function addCustomDialog(editorId) {
      var that = this;
      window.UE.registerUI('test-dialog', function (editor, uiName) {
        // 创建 dialog
        var dialog = new window.UE.ui.Dialog({
          // 指定弹出层中页面的路径，这里只能支持页面，路径参考常见问题 2
          iframeUrl: '/setting/uploadPicture?field=dialog&type=2',
          // 需要指定当前的编辑器实例
          editor: editor,
          // 指定 dialog 的名字
          name: uiName,
          // dialog 的标题
          title: '上传图片',
          // 指定 dialog 的外围样式
          cssRules: 'width:1000px;height:620px;padding:20px;'
        });
        this.dialog = dialog;
        var btn = new window.UE.ui.Button({
          name: 'dialog-button',
          title: '上传图片',
          cssRules: "background-image: url(@/assets/images/icons.png);background-position: -726px -77px;",
          onclick: function onclick() {
            // 渲染dialog
            // dialog.render()
            // dialog.open()
            // console.log(that.refs.files.onclick(), 'that')
            that.$refs.files.click();
          }
        });
        return btn;
      }, 37); // window.UE.registerUI('video-dialog', function(editor, uiName) {
      //   const dialog = new window.UE.ui.Dialog({
      //     iframeUrl: '/admin/widget.video/index.html?fodder=video',
      //     editor: editor,
      //     name: uiName,
      //     title: '上传视频',
      //     cssRules: 'width:1000px;height:500px;padding:20px;'
      //   })
      //   this.dialog = dialog
      //   const btn = new window.UE.ui.Button({
      //     name: 'video-button',
      //     title: '转存',
      //     cssRules: `background-image: url(../../../assets/images/icons.png);background-position: -301px -39px;`,
      //     onclick: function() {
      //       // 渲染dialog
      //       // dialog.render()
      //       // dialog.open()
      //     }
      //   })
      //   return btn
      // }, 38)
    }
  }
};