function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { spiderVideoDeleteByIds, spiderVideoQueryPage, spiderVideoQuerySave, spiderVideoUpdateById, spiderVideoderiveList } from '@/api/spiderVideo/index';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
import { parseTime, handleExport } from '@/utils';
export default {
  name: 'CrawlerControl',
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        sourceId: '',
        name: '',
        outId: '',
        translateFlag: '',
        url: '' // keywords: ''

      },
      translateFlagSelect: [{
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      listData: {
        list: [],
        total: 0
      },
      diaform: {
        title: '新增爬虫'
      },
      crawkerData: {
        id: '',
        name: '',
        translateFlag: 0,
        outId: '',
        sourceId: '',
        url: '',
        days: '',
        counts: 3
      },
      dialogFormVisible: false,
      formLabelWidth: '120px',
      addtype: 0,
      multipleSelection: [],
      options: []
    };
  },
  mounted: function mounted() {
    this.getoptions();
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    deriveList: function deriveList() {
      spiderVideoderiveList(this.tableFrom).then(function (res) {
        handleExport(res, '爬虫列表');
      });
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    getoptions: function getoptions() {
      var _this = this;

      spiderSourceQueryList({}).then(function (res) {
        _this.options = res;
      }).catch(function () {});
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20
      };
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      this.tableSelection = row;
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    handleFreeze: function handleFreeze(row) {
      this.diaform.title = '编辑爬虫';
      this.dialogFormVisible = true;
      this.crawkerData = row;
      this.addtype = 1;
    },
    // 删除
    delCelebrity: function delCelebrity(row) {
      var _this2 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      spiderVideoDeleteByIds(par.ids).then(function (res) {
        _this2.$message.success('删除成功');

        _this2.getList();
      });
    },
    // 新增
    addCelebrity: function addCelebrity() {
      this.diaform.title = '新增爬虫';
      this.crawkerData = {
        id: '',
        name: '',
        translateFlag: 0,
        outId: '',
        sourceId: '',
        url: '',
        days: '',
        counts: 3
      };
      this.dialogFormVisible = true;
      this.addtype = 0;
    },
    // 列表
    getList: function getList() {
      var _this3 = this;

      spiderVideoQueryPage(this.tableFrom).then(function (res) {
        // this.listData.list = res.list
        _this3.listData.total = res.totalCount; // console.log(this.options)

        var mergedArray = []; // 遍历第一个数组

        res.list.forEach(function (item1) {
          // 在第二个数组中查找匹配的id
          var matchingItem2 = _this3.options.find(function (item2) {
            return item2.id === item1.sourceId;
          }); // 如果找到匹配项，将两个对象合并到新数组中


          if (matchingItem2) {
            mergedArray.push(_objectSpread(_objectSpread({}, item1), {}, {
              sourceName: matchingItem2.name
            }));
          }
        });
        _this3.listData.list = mergedArray; // res.list.forEach(idObj => {
        // const matchingNameObj = this.option.find(nameObj => nameObj.id === idObj.sourceId);
        // console.log(matchingNameObj)
        // if (matchingNameObj) {
        //   this.listData.list.push({
        //     sourceName: nameObj.name,
        //     name: matchingNameObj.name
        //   })
        // }
        // })
        // console.log(nameArray)
      }).catch(function () {});
    },
    // 更新
    crawlerupd: function crawlerupd() {
      var _this4 = this;

      if (this.addtype === 1) {
        spiderVideoUpdateById(this.crawkerData).then(function (res) {
          _this4.dialogFormVisible = false;
          _this4.crawkerData = {
            id: '',
            name: '',
            translateFlag: 0,
            outId: '',
            sourceId: '',
            url: '',
            days: '',
            counts: ''
          };

          _this4.$message.success('修改成功');
        }).catch(function () {
          _this4.$message.error('修改失败');
        });
      } else {
        spiderVideoQuerySave(this.crawkerData).then(function (res) {
          _this4.dialogFormVisible = false;

          _this4.$message.success('新增成功');
        }).catch(function () {
          _this4.$message.error('新增失败');
        });
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listData.total = val;
      console.log(this.tableFrom); // this.getList()
    },
    pageChange: function pageChange(val) {
      this.tableFrom.page = val;
      this.getList();
    }
  }
};