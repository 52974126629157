var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            modal: false,
            width: "700",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布人", prop: "createBy" } },
                    [
                      _vm.formModal.createBy
                        ? _c(
                            "el-tag",
                            {
                              key: _vm.coincelebrityData.id,
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                closable: "",
                                "disable-transitions": false,
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.handleTagClose(_vm.tag)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.coincelebrityData.nickname) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "el-button",
                            {
                              staticClass: "button-new-tag",
                              attrs: { size: "small" },
                              on: { click: _vm.showInput },
                            },
                            [_vm._v("添加用户 +")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间", prop: "createTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "选择日期时间",
                        },
                        model: {
                          value: _vm.formModal.createTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "createTime", $$v)
                          },
                          expression: "formModal.createTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容", prop: "content" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.formModal.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "content", $$v)
                          },
                          expression: "formModal.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "点赞数", prop: "likeNum" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入点赞数" },
                        model: {
                          value: _vm.formModal.likeNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "likeNum", $$v)
                          },
                          expression: "formModal.likeNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("addIssuer", {
                ref: "addIssuer",
                on: { handleList: _vm.handleList },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }