var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入分类名称" },
                        model: {
                          value: _vm.formModal.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "name", $$v)
                          },
                          expression: "formModal.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类排序", prop: "sort" } },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1, max: 99999, l: "" },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.formModal.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "sort", $$v)
                          },
                          expression: "formModal.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键字", prop: "sort" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModal.keywords,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "keywords", $$v)
                          },
                          expression: "formModal.keywords",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否自营", prop: "sort" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formModal.selfFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "selfFlag", $$v)
                            },
                            expression: "formModal.selfFlag",
                          },
                        },
                        _vm._l(_vm.selfflagList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "语言", prop: "sort" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formModal.language,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "language", $$v)
                            },
                            expression: "formModal.language",
                          },
                        },
                        _vm._l(Object.keys(_vm.languageType), function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: {
                              label: _vm.languageType[item],
                              value: item,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formModal.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "status", $$v)
                            },
                            expression: "formModal.status",
                          },
                        },
                        [_vm._v("开启")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.formModal.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "status", $$v)
                            },
                            expression: "formModal.status",
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }