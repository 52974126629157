//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { coincelebritycontentSave, coincelebritycontentUpdateById } from '@/api/celebrity/celebrity';
import ueditorFrom from '@/components/ueditorFrom';
import addDynamic from './addDynamic';
export default {
  name: 'AddDynamicMen',
  components: {
    ueditorFrom: ueditorFrom,
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      formModal: {
        id: '',
        // id
        celebrityId: '',
        // 名人id
        celebrityName: '',
        // 关联名人
        name: '',
        sourceType: '',
        // 来自
        profilePhoto: '',
        // 头像
        content: '',
        // 内容
        likeNum: '',
        // 总获赞量
        discussNum: '',
        // 总评论量
        forwardNum: '',
        // 总被分享量
        dataStatus: 1 // 状态

      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      rules: {
        name: [{
          required: true,
          message: '请输入关联名人',
          trigger: 'blur'
        }],
        sourceType: [{
          required: true,
          message: '请选择内容来自位置',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        profilePhoto: [{
          required: true,
          message: '请输入头像',
          trigger: 'blur'
        }]
      },
      formDisable: false,
      // 禁用状态
      statue: '',
      coincelebrityData: {},
      setting: {
        // 弹窗
        title: '添加动态',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.formDisable = this.$route.query.formDisable !== '0';
    this.formModal = JSON.parse(decodeURIComponent(this.$route.query.formModal));
    this.statue = this.$route.query.statue;

    if (this.formModal.name) {
      this.coincelebrityData.name = this.formModal.name;
      this.coincelebrityData.celebrityName = this.formModal.name;
      this.coincelebrityData.celebrityId = this.formModal.celebrityId;
    }
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    handleList: function handleList(val) {
      // 弹窗回调
      console.log(val, 'val');
      this.coincelebrityData = val;
      this.formModal.celebrityName = val.name;
      this.formModal.name = val.name;
      this.formModal.celebrityId = val.celebrityId;
      this.$forceUpdate();
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      // this.formModal.label.splice(this.formModal.label.indexOf(tag), 1)
      this.coincelebrityData = {};
      this.formModal.celebrityName = '';
      this.formModal.name = '';
      this.formModal.celebrityId = '';
    },
    showInput: function showInput() {
      this.$refs.addDynamic.setting.visible = true;
      this.$refs.addDynamic.setting.title = '选择名人';
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.label.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();
        _this.formModal.profilePhoto = res.url;

        _this.$forceUpdate();

        _this.$message.success('上传成功');
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this2 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var formModal = JSON.parse(JSON.stringify(_this2.formModal));

          if (_this2.statue !== 1) {
            // 编辑
            coincelebritycontentUpdateById(formModal).then(function (res) {
              _this2.$message.success('编辑成功'); // 重置
              // this.$refs.formModal.resetFields()
              // this.handleClose()


              _this2.$router.push({
                name: 'DynamicMen'
              });
            }).catch(function (res) {
              _this2.$message.error('编辑失败');
            }); // this.formModal.label = this.formModal.label.split(',')
          } else {
            // 新增
            coincelebritycontentSave(formModal).then(function (res) {
              _this2.$message.success('添加成功'); // 重置


              _this2.$refs.formModal.resetFields();

              _this2.$router.push({
                name: 'DynamicMen'
              }); // this.handleClose()

            }).catch(function (res) {
              _this2.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};