//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { coinCelebrityContentCommentPage, coinCelebrityContentCommentDeleteByIds, coinCelebrityContentCommentUpdateById } from '@/api/celebrity/celebrity';
import addDynamic from './components/addDynamic';
import { parseTime } from '@/utils';
export default {
  name: 'DynamicMen',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        nickname: '' // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.nickname = '';
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // handlerStatus(rowData) {
    //   const status = rowData.auditStatus
    //   const prm = {
    //     id: rowData.id
    //   }
    //   if (status === 0) {
    //     this.$confirm(`是否审核通过`, '提示', {
    //       confirmButtonText: '通过',
    //       cancelButtonText: '不通过',
    //       type: 'warning'
    //     })
    //       .then(() => {
    //         prm.auditStatus = 1
    //         this.changeAudit(prm)
    //       })
    //       .catch(() => {
    //         prm.auditStatus = 2
    //         this.changeAudit(prm)
    //       })
    //   } else if (status === 1 || status === 2 || status === 3) {
    //     prm.auditStatus = 0
    //     this.changeAudit(prm)
    //   }
    // },
    changeAudit: function changeAudit(prm) {
      var _this = this;

      coinCelebrityContentCommentUpdateById(prm).then(function (res) {
        _this.$message.success("\u5BA1\u6838".concat(prm.status === 1 ? '通过' : '不通过'));

        _this.getList();
      });
    },
    // 打开弹窗
    addCelebrity: function addCelebrity() {
      // 重置弹窗
      this.$refs.addDynamic.formModal = {
        celebrityName: '',
        // 关联名人
        sourceType: '',
        // 来自
        image: '',
        // 头像
        content: '',
        // 内容
        likeNum: '',
        // 总获赞量
        discussNum: '',
        // 总评论量
        forwardNum: '',
        // 总被分享量
        dataStatus: '1' // 状态

      };
      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this2 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      coinCelebrityContentCommentDeleteByIds(par.ids).then(function (res) {
        _this2.$message.success('删除成功');

        _this2.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      coinCelebrityContentCommentPage(this.tableFrom).then(function (res) {
        _this3.listData.list = res.list;
        _this3.listData.total = res.totalCount;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};