//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { coincelebrityPage, coincelebrityDeleteByIds, coincelebrityUpdateById } from '@/api/celebrity/celebrity';
import AddFamous from './components/addFamous';
import addTopic from './components/addTopic';
export default {
  name: 'CelebrityMan',
  components: {
    AddFamous: AddFamous,
    addTopic: addTopic
  },
  data: function data() {
    return {
      options: [{
        value: '',
        label: '全部'
      }, {
        value: true,
        label: '推荐'
      }, {
        value: false,
        label: '不推荐'
      }],
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        name: '',
        isGood: '' // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      celebrityData: {
        data: [],
        loading: false,
        total: 0
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.name = '';
      this.tableFrom.isGood = '';
      this.getList();
    },
    handleOpen: function handleOpen(row) {
      var _this = this;

      this.$confirm("\u786E\u8BA4".concat(row.isShow ? '不推荐' : '推荐', "\u5F53\u524D\u540D\u4EBA"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.setSave(row);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.isShow ? '不推荐' : '推荐')
        });
      });
    },
    setSave: function setSave(row) {
      var _this2 = this;

      if (row.status) {
        row.isGood = false;
      } else {
        row.isGood = true;
      }

      coincelebrityUpdateById(row).then(function (res) {
        _this2.$message.success('修改成功');
      }).catch(function (res) {
        if (row.status) {
          row.status = false;
        } else {
          row.status = true;
        }

        _this2.$message.error('修改失败');
      });
    },
    showTopic: function showTopic(row) {
      this.$refs.addTopic.setting.visible = true;
      this.$refs.addTopic.setting.title = '查看详情';
      this.$refs.addTopic.tableFrom.coinCelebrityId = row.id;
      this.$refs.addTopic.tableFrom.page = 1;
      this.$refs.addTopic.tableFrom.limit = 20;
      this.$refs.addTopic.getList();
    },
    handlerDelete: function handlerDelete() {
      var _this3 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      coincelebrityDeleteByIds(par.ids).then(function (res) {
        _this3.$message.success('删除成功');

        _this3.getList();
      });
    },
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.id) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        this.$refs.addFamous.formModal = par;

        if (this.$refs.addFamous.formModal.selfIntroduction) {
          this.$refs.addFamous.formModal.selfIntroduction = par.selfIntroduction.split(',');
        } else {
          this.$refs.addFamous.formModal.selfIntroduction = [];
        }

        this.$refs.addFamous.setting.title = '编辑名人';
      } else {
        // 重置弹窗
        this.$refs.addFamous.formModal = {
          id: '',
          name: '',
          // 姓名
          selfIntroduction: [],
          // 标签
          profilePhoto: '',
          // 头像
          introduction: '',
          // 简介
          likeNum: '',
          // 总获赞量
          discussNum: '',
          // 总评论量
          forwardNum: '',
          // 总被分享量
          focusNum: '',
          // 关注Ta总人数
          sort: '',
          // 名人墙排序
          isGood: true // 状态

        };
        this.$refs.addFamous.setting.title = '添加名人';
      }

      this.$refs.addFamous.setting.visible = true;
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      coincelebrityPage(this.tableFrom).then(function (res) {
        _this4.listData.list = res.list;
        _this4.listData.total = res.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};