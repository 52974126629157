function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getRoleList, roleDelete, roleUpdate } from '@/api/role';
import addDynamic from './components/addDynamic';
import { mapGetters } from 'vuex';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
import { parseTime } from '@/utils';
import { registerType, virtualFlagType, whiteEnableType } from '@/utils/enum';
export default {
  name: 'Authority',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      registerType: registerType,
      virtualFlagType: virtualFlagType,
      whiteEnableType: whiteEnableType,
      value: '',
      input: '',
      comeFrom: [],
      tableFrom: {
        page: 1,
        limit: 20,
        mobile: '',
        regType: '',
        virtualFlag: '',
        whiteEnable: '',
        sourceId: '',
        email: '',
        roleName: '' // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: ''
    };
  },
  computed: _objectSpread({}, mapGetters(['info'])),
  mounted: function mounted() {
    this.getList();
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    handleOpen: function handleOpen(row) {
      var _this = this;

      this.$confirm("\u786E\u8BA4".concat(row.isShow ? '关闭' : '开启', "\u5F53\u524D\u5FEB\u8BAF"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.setSave(row);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.isShow ? '开启' : '关闭')
        });
      });
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.roleName = '';
      this.tableFrom.mobile = '';
      this.tableFrom.regType = '';
      this.tableFrom.virtualFlag = '';
      this.tableFrom.whiteEnable = '';
      this.tableFrom.sourceId = '';
      this.tableFrom.email = '';
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      this.tableSelection = row;
      this.multipleSelection = row.map(function (item) {
        return item.roleId;
      });
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.roleId) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        this.$refs.addDynamic.formModal = par;
        this.$refs.addDynamic.setting.title = '编辑身份';
        this.$refs.addDynamic.spiderSourceList();
        this.$refs.addDynamic.getMetuList();
      } else {
        // 重置弹窗
        this.$refs.addDynamic.formModal = {
          id: '',
          remark: '',
          // 备注
          createUserId: this.info.userId,
          // 用户Id
          roleName: '',
          // 昵称
          menuIdList: []
        };
        this.$refs.addDynamic.setting.title = '添加身份';
        this.$refs.addDynamic.getMetuList(); // this.$refs.addDynamic.$refs.tree.setCheckedKeys([])
      }

      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this2 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        roleIds: this.multipleSelection
      };
      roleDelete(par.roleIds).then(function (res) {
        _this2.$message.success('删除成功');

        _this2.getList();
      });
    },
    setSave: function setSave(row) {
      var _this3 = this;

      row.isShow = !row.isShow;
      roleUpdate(row).then(function (res) {
        _this3.$message.success('修改成功');
      }).catch(function (res) {
        row.isShow = !row.isShow;

        _this3.$message.error('修改失败');
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true; // if (this.tableFrom.formatDate) {
      //   this.tableFrom.formatDate = parseTime(
      //     this.tableFrom.formatDate,
      //     '{y}-{m}-{d}'
      //   )
      // }

      getRoleList(this.tableFrom).then(function (res) {
        console.log(res, 'res');
        _this4.listData.list = res.page.list;
        _this4.listData.total = res.page.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    spiderSourceList: function spiderSourceList() {
      var _this5 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this5.comeFrom = res;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};