import request from '@/utils/request'; // 会员邀请记录 === 活动
// 会员邀请记录验证通过

export function memberInvitedCheck(data) {
  return request({
    url: '/memberInvited/auth/check',
    method: 'post',
    data: data
  });
} // 会员邀请记录删除

export function memberInvitedDeleteByIds(data) {
  return request({
    url: '/memberInvited/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 会员邀请主键查询

export function memberInvitedGetById(data) {
  return request({
    url: '/memberInvited/auth/getById',
    method: 'post',
    data: data
  });
} // 会员邀请列表查询

export function memberInvitedQueryList(data) {
  return request({
    url: '/memberInvited/auth/queryList',
    method: 'post',
    data: data
  });
} // 会员邀请分页列表

export function memberInvitedQueryPage(data) {
  return request({
    url: '/memberInvited/auth/queryPage',
    method: 'post',
    data: data
  });
} // 会员邀请记录保存

export function memberInvitedQuerySave(data) {
  return request({
    url: '/memberInvited/auth/save',
    method: 'post',
    data: data
  });
} // 会员邀请记录修改

export function memberInvitedQueryUpdateById(data) {
  return request({
    url: '/memberInvited/auth/updateById',
    method: 'post',
    data: data
  });
} // 会员活动奖励记录分页列表

export function memberActivityReward(data) {
  return request({
    url: '/memberActivityReward/auth/queryPage',
    method: 'post',
    data: data
  });
} // 活动列表列表查询

export function memberActivityqueryList(data) {
  return request({
    url: '/activity/auth/queryList',
    method: 'post',
    data: data
  });
} // 活动列表分页列表

export function memberActivityqueryPage(data) {
  return request({
    url: '/activity/auth/queryPage',
    method: 'post',
    data: data
  });
} // 活动奖励规则分页列表

export function memberactivityRewardqueryPage(data) {
  return request({
    url: 'activityReward/auth/queryPage',
    method: 'post',
    data: data
  });
} // 活动奖励规则修改

export function memberactivityRewardupdateById(data) {
  return request({
    url: '/activityReward/auth/updateById',
    method: 'post',
    data: data
  });
} // 活动奖励规则主键查询

export function memberactivityRewardgetById(data) {
  return request({
    url: '/activityReward/auth/getById',
    method: 'post',
    data: data
  });
} // 活动奖励规则保存

export function memberactivityRewardsave(data) {
  return request({
    url: '/activityReward/auth/save',
    method: 'post',
    data: data
  });
} // 活动奖励规则满足条件类型ID

export function memberactivityRewardConditionType(data) {
  return request({
    url: '/activityRewardConditionType/auth/queryList',
    method: 'post',
    data: data
  });
} // 活动奖励规则删除

export function memberactivityRewarddeleteByIds(data) {
  return request({
    url: '/activityReward/auth/deleteByIds',
    method: 'post',
    data: data
  });
}