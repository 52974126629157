import request from '@/utils/request'; // app 版本控制
// 删除

export function appVersionDeleteByIds(data) {
  return request({
    url: '/appVersion/deleteByIds',
    method: 'post',
    data: data
  });
} // 版本主键查询

export function appVersionGetById(data) {
  return request({
    url: '/appVersion/getById',
    method: 'post',
    data: data
  });
} // 版本列表查询

export function appVersionQueryList(data) {
  return request({
    url: '/appVersion/queryList',
    method: 'post',
    data: data
  });
} // 版本分页列表

export function appVersionQueryPage(data) {
  return request({
    url: '/appVersion/queryPage',
    method: 'post',
    data: data
  });
} // 版本保存

export function appVersionQuerySave(data) {
  return request({
    url: '/appVersion/save',
    method: 'post',
    data: data
  });
} // 版本修改

export function appVersionUpdateById(data) {
  return request({
    url: '/appVersion/updateById',
    method: 'post',
    data: data
  });
} // 版本修改

export function getStr(data) {
  return request({
    url: '/sys/oss/getSts',
    method: 'get',
    data: data
  });
}