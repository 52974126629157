var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: { title: "选择用户标签", visible: _vm.dialog },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: { data: _vm.labelList },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "labelName", label: "标签名称" },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "page-sizes": [5, 10, 20, 30, 40],
              "page-size": _vm.fans.limit,
              "current-page": _vm.fans.page,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.fans.total,
            },
            on: {
              "size-change": _vm.fansSizeChange,
              "current-change": _vm.fanspageChange,
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updlabel } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }