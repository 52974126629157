import { categoryQueryList } from '@/api/article/article';
export default {
  data: function data() {
    return {
      articleType: [],
      comeFrom: [{
        value: 1,
        label: 'facebook'
      }, {
        value: 2,
        label: 'twitter'
      }, {
        value: 3,
        label: 'weibo'
      }],
      listLoading: true
    };
  },
  methods: {
    articleList: function articleList(type) {
      var _this = this;

      var par = {
        type: type
      };
      categoryQueryList(par).then(function (res) {
        _this.articleType = res;
      }).catch(function () {
        _this.listLoading = false;
      });
    }
  }
};