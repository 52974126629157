import request from '@/utils/request';
export function getRoutes() {
  return request({
    url: '/vue-element-admin/routes',
    method: 'get'
  });
}
export function getRoles() {
  return request({
    url: '/vue-element-admin/roles',
    method: 'get'
  });
}
export function addRole(data) {
  return request({
    url: '/vue-element-admin/role',
    method: 'post',
    data: data
  });
}
export function updateRole(id, data) {
  return request({
    url: "/vue-element-admin/role/".concat(id),
    method: 'put',
    data: data
  });
}
export function deleteRole(id) {
  return request({
    url: "/vue-element-admin/role/".concat(id),
    method: 'delete'
  });
} // 路由角色列表
// 删除角色

export function roleDelete(data) {
  return request({
    url: '/sys/role/delete',
    method: 'post',
    data: data
  });
} // 角色信息

export function getRoleInfo(roleId) {
  return request({
    url: "/sys/role/info/".concat(roleId),
    method: 'get'
  });
} //  列表

export function getRoleList(params) {
  return request({
    url: '/sys/role/list',
    method: 'get',
    params: params
  });
}
export function roleSave(data) {
  return request({
    url: '/sys/role/save',
    method: 'post',
    data: data
  });
} //  列表

export function getRoleSelect(params) {
  return request({
    url: '/sys/role/select',
    method: 'get',
    params: params
  });
} // 修改角色

export function roleUpdate(data) {
  return request({
    url: '/sys/role/update',
    method: 'post',
    data: data
  });
} // 导航菜单

export function menuNav(params) {
  return request({
    url: '/sys/menu/nav',
    method: 'get',
    params: params
  });
}