//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { parseTime } from '@/utils';
export default {
  name: 'BarTable',
  props: {
    listData: {
      type: Array,
      default: Array
    },
    tableFrom: {
      type: Object,
      default: Object
    },
    listLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      statusMap: {
        0: '安卓',
        1: 'IOS',
        2: '小程序',
        3: 'H5',
        4: 'Web'
      }
    };
  },
  methods: {
    parseTime: parseTime,
    pageChange: function pageChange(page) {
      this.$emit('pageChange', page);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.$emit('handleSizeChange', val);
    }
  }
};