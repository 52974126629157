//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
import { articleQuerySave, categoryQueryList, articleQueryUpdateById } from '@/api/article/article'; // import ueditorFrom from '@/components/ueditorFrom'
// import uploadByOss from '@/components/uploadByOss'

export default {
  name: 'AddFamous',
  components: {// ueditorFrom,
    // uploadByOss
  },
  data: function data() {
    return {
      formModal: {
        id: '',
        title: '',
        // 标题
        sourceId: '',
        // 来源
        categoryId: '',
        // 分类
        image: '',
        // 上传图片
        synopsis: '',
        // 简介
        content: '',
        // 内容
        virtualVisit: '',
        // 虚拟浏览量
        video: '',
        // 视频
        contentType: '',
        // 类型
        boutiqueFlag: '',
        // 是否精品 默认空
        topFlag: '',
        // 是否置顶 默认空
        isShow: false // 状态

      },
      formDisable: false,
      statue: 1,
      companyUrlList: [],
      comeFrom: [{
        value: 1,
        label: 'facebook'
      }, {
        value: 2,
        label: 'twitter'
      }, {
        value: 3,
        label: 'weibo'
      }],
      articleSelect: [{
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      contentType: 0,
      // 区分视频和图文
      rules: {
        title: [{
          required: true,
          message: '请输入标题名称',
          trigger: 'blur'
        }],
        synopsis: [{
          required: true,
          message: '请输入简介',
          trigger: 'blur'
        }],
        sourceId: [{
          required: true,
          message: '请选择来源',
          trigger: 'blur'
        }],
        categoryId: [{
          required: true,
          message: '请选择分类',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加资讯分类',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.statue = this.$route.query.statue; // this.articleList()
    // this.spiderSourceList()
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 新增 video 需求添加方法
    handleVideoList: function handleVideoList(list) {
      this.formModal.videoAspectRatio = list.videoAspectRatio;
      this.formModal.videoAspectRatioType = list.videoAspectRatioType;
      this.formModal.videoDuration = list.videoDuration;
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      this.formModal.selfIntroduction.splice(this.formModal.selfIntroduction.indexOf(tag), 1);
    },
    handleVideo: function handleVideo(video) {
      this.formModal.video = video;
    },
    showInput: function showInput() {
      var _this = this;

      if (this.formModal.selfIntroduction.length > 2) {
        this.$message.error('标签最多可以添加3个');
        return;
      }

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this2 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this2.$message.success('上传成功');

        _this2.formModal.columnAvatar = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    handleUpdate: function handleUpdate(formModal) {
      var _this3 = this;

      articleQueryUpdateById(formModal).then(function (res) {
        _this3.$message.success('修改成功'); // 重置


        _this3.$refs.formModal.resetFields();

        _this3.handleClose();
      }).catch(function (res) {
        _this3.$message.error('修改失败');
      });
    },
    settingEvent: function settingEvent() {
      var _this4 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var formModal = JSON.parse(JSON.stringify(_this4.formModal));

          if (formModal.video) {
            formModal.contentType = 3;
          } else {
            formModal.contentType = 1;
          } // 新增需求 统一添加字段 authorName: 来源名称


          var authorName = _this4.comeFrom.find(function (val) {
            return val.id === formModal.sourceId;
          });

          formModal.authorName = authorName.name;

          if (_this4.formModal.id) {
            _this4.handleUpdate(formModal);
          } else {
            articleQuerySave(formModal).then(function (res) {
              _this4.$message.success('添加成功'); // 重置


              _this4.$refs.formModal.resetFields();

              _this4.handleClose();
            }).catch(function (res) {
              _this4.$message.error('添加失败');
            });
          }
        }
      });
    },
    articleList: function articleList() {
      var _this5 = this;

      var par = {
        type: 1
      };
      categoryQueryList(par).then(function (res) {
        _this5.articleType = res;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    spiderSourceList: function spiderSourceList() {
      var _this6 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this6.comeFrom = res;
      }).catch(function () {});
    }
  }
};