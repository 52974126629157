//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { coincelebritycontentSave } from '@/api/celebrity/celebrity'
import { memberQueryPage } from '@/api/member/member';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        mobile: '',
        email: '',
        memberType: '',
        virtualFlag: '',
        nickname: '' // keywords: ''

      },
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '普通用户'
      }, {
        value: 2,
        label: '官方用户'
      }, {
        value: 3,
        label: '名人用户'
      }],
      virtualFlagType: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      modalState: true,
      setting: {
        // 弹窗
        title: '选择名人',
        visible: false
      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      coincelebrityData: {},
      // 选中数据
      coincelebrityRadio: '' // 单选

    };
  },
  mounted: function mounted() {// this.getList()
    // this.coincelebrityRadio = ''
  },
  methods: {
    // table 选中回调
    handleRowClick: function handleRowClick(row, column, event) {
      this.coincelebrityData = row;
      this.coincelebrityRadio = row.id; // console.log(row, column, event, '参数')
    },
    settingEvent: function settingEvent() {
      this.$emit('handleList', this.coincelebrityData);
      this.setting.visible = false;
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false;
      this.coincelebrityRadio = ''; // 调用父组件 刷新页面
    },
    lookRow: function lookRow() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.mobile = '';
      this.tableFrom.email = '';
      this.tableFrom.memberType = '';
      this.tableFrom.virtualFlag = '';
      this.tableFrom.nickname = '';
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      memberQueryPage(this.tableFrom).then(function (res) {
        _this.listData.list = res.list;
        _this.listData.total = res.totalCount;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.coincelebrityRadio = '';
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};