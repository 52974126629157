//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { coincelebritycontentSave } from '@/api/celebrity/celebrity'
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    return {
      celebrityLink: '',
      // 链接
      celebrityText: '',
      // 文本
      setting: {
        // 弹窗
        title: '选择名人',
        visible: false
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    this.celebrityLink = '';
    this.celebrityText = '';
  },
  methods: {
    settingEvent: function settingEvent() {
      if (!this.celebrityLink) {
        this.$message.error('链接地址不能为空!');
        return;
      }

      this.$emit('handleLink', "".concat(this.celebrityLink, ",").concat(this.celebrityText));
      this.setting.visible = false;
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false;
      this.coincelebrityRadio = ''; // 调用父组件 刷新页面
    }
  }
};