//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getRoleList } from '@/api/role';
import { userUpdate, userSave, getInfoDetail } from '@/api/user';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    var _this = this;

    return {
      formModal: {
        userId: '',
        username: '',
        // 昵称
        password: '',
        // 密码
        mobile: '',
        // 手机号
        email: '',
        // 邮箱
        status: 1,
        // 状态
        roleIdList: [] // 个性签名

      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      rules: {
        username: [{
          required: true,
          message: '请输入名称',
          trigger: 'change'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        countryCode: [{
          required: true,
          message: '请选择手机区号',
          trigger: 'change'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (/^1[3-9]\d{9}$/.test(value) || !value) {
              callback();
            } else {
              callback(new Error('请输入有效的手机号码'));
            }
          },
          trigger: 'blur'
        }],
        email: [{
          required: true,
          trigger: 'blur',
          message: '请输入邮箱'
        }, {
          validator: function validator(rule, value, callback) {
            if (!value) {
              if (_this.rules.email[0].required) {
                return callback(new Error('邮箱不能为空'));
              }

              return callback();
            } else {
              var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

              if (reg.test(value)) {
                callback();
              } else {
                return callback(new Error('邮箱格式不正确！'));
              }
            }
          },
          trigger: 'blur'
        }]
      },
      listData: [],
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    updswitch: function updswitch(e) {
      if (e.status == 1) {
        e.status = 0;
      } else {
        e.status = 1;
      }
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 列表
    getList: function getList() {
      var _this2 = this;

      var par = {
        page: 1,
        limit: 10000
      };
      getRoleList(par).then(function (res) {
        console.log(res, 'res');
        _this2.listData = res.page.list;
      }).catch(function () {});
    },
    handlePhoneCodeChange: function handlePhoneCodeChange() {
      this.$refs.formModal.validateField('countryCode');
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      userUpdate(this.formModal).then(function (res) {
        _this3.$message.success('修改成功'); // 重置


        _this3.$refs.formModal.resetFields();

        _this3.handleClose();
      }).catch(function (res) {// this.$message.error('修改失败')
      });
    },
    spiderSourceList: function spiderSourceList(userId) {
      var _this4 = this;

      console.log(this.formModal, 'this.formModal');
      getInfoDetail(userId).then(function (res) {
        console.log(res, 'res');
        _this4.formModal = Object.assign(_this4.formModal, res.user);
        _this4.formModal.password = ''; // this.$refs.tree.setCheckedKeys(this.formModal.menuIdList)
      }).catch(function () {});
    },
    settingEvent: function settingEvent() {
      var _this5 = this;

      this.$refs.formModal.validate(function (valid) {
        console.log(_this5.formModal, 'this.formModal');

        if (valid) {
          if (_this5.formModal.userId) {
            _this5.handleUpdate();
          } else {
            userSave(_this5.formModal).then(function (res) {
              _this5.$message.success('添加成功'); // 重置


              _this5.$refs.formModal.resetFields();

              _this5.handleClose();

              console.log(res, 'res');
            }).catch(function (res) {
              _this5.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};