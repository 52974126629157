//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { newflashQueryPage, newflashDeleteByIds, newflashQueryUpdateById, newflashBatchUpdateById } from '@/api/newsFlash/newsFlash';
import addDynamic from './components/addDynamic';
import { parseTime } from '@/utils';
import { languageType } from '@/utils/enum';
import { categoryQueryList } from '@/api/article/article';
export default {
  name: 'DynamicMen',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      languageType: languageType,
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      articleType: [],
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        newsFlashTitle: '',
        isShow: '',
        language: 'cn',
        // 语言
        formatDate: '' // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: ''
    };
  },
  mounted: function mounted() {
    this.getList();
    this.articleList();
  },
  methods: {
    parseTime: parseTime,
    updListInfo: function updListInfo(data) {
      var _this = this;

      console.log(data.importantFlag);
      newflashQueryUpdateById(data).then(function (res) {
        _this.$message.success('修改成功'); // 重置

      }).catch(function (res) {
        _this.$message.error('修改失败');
      });
    },
    articleList: function articleList() {
      var _this2 = this;

      var par = {
        type: 2,
        language: this.tableFrom.language
      };
      categoryQueryList(par).then(function (res) {
        _this2.articleType = res;

        _this2.articleType.push({
          name: '其他',
          id: ''
        });

        console.log(_this2.articleType); // this.formModal.cid = Number(this.formModal.cid)

        _this2.$forceUpdate();
      }).catch(function () {// this.listLoading = false
      });
    },
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    handleUpdate: function handleUpdate(status) {
      var _this3 = this;

      if (this.listLoading) {
        return;
      }

      this.listLoading = true;

      if (this.tableSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      this.tableSelection.forEach(function (val) {
        val.isShow = status;
      });
      newflashBatchUpdateById(this.tableSelection).then(function (res) {
        _this3.$refs.multipleTable.clearSelection();

        _this3.$message.success('修改成功');

        _this3.listLoading = false;
        _this3.tableSelection = [];
      }).catch(function (res) {
        _this3.$refs.multipleTable.clearSelection();

        _this3.$message.error('修改失败');

        _this3.listLoading = false;
      });
    },
    handleOpen: function handleOpen(row) {
      var _this4 = this;

      this.$confirm("\u786E\u8BA4".concat(row.isShow ? '关闭' : '开启', "\u5F53\u524D\u5FEB\u8BAF"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.setSave(row);
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.isShow ? '开启' : '关闭')
        });
      });
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.newsFlashTitle = '';
      this.tableFrom.isShow = '';
      this.tableFrom.language = 'cn';
      this.tableFrom.formatDate = '';
      this.tableFrom.cid = null;
      this.articleList();
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      this.tableSelection = row;
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.id) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        this.$refs.addDynamic.formModal = par;
        this.$refs.addDynamic.articleList();

        if (par.cid != "") {
          par.cid = par.cid.split(',').map(function (id) {
            return parseInt(id);
          });
        } // this.$set(this.$refs.addDynamic.formModal,'cid',Number(par.cid))


        if (this.$refs.addDynamic.formModal.selfIntroduction) {
          this.$refs.addDynamic.formModal.selfIntroduction = par.selfIntroduction.split(',');
        } else {
          this.$refs.addDynamic.formModal.selfIntroduction = [];
        }

        this.$refs.addDynamic.setting.title = '编辑分类';
      } else {
        // 重置弹窗
        this.$refs.addDynamic.formModal = {
          id: '',
          newsFlashTitle: '',
          // 快讯标题
          flashSourceName: '',
          // 来源名称
          newsFlashContent: '',
          // 内容
          flashUp: '',
          // 虚拟利好量
          flashDown: '',
          // 虚拟利空量
          language: 'cn',
          // 语言
          isShow: true // 状态

        };
        this.$refs.addDynamic.setting.title = '添加分类';
        this.$refs.addDynamic.articleList();
      } // 重置弹窗
      // this.$refs.addDynamic.formModal = {
      //   newsFlashTitle: '', // 快讯标题
      //   flashSourceName: '', // 来源名称
      //   newsFlashContent: '', // 内容
      //   flashUp: '', // 虚拟利好量
      //   flashDown: '', // 虚拟利空量
      //   isShow: true // 状态
      // }


      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this5 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      newflashDeleteByIds(par.ids).then(function (res) {
        _this5.$message.success('删除成功');

        _this5.getList();
      });
    },
    setSave: function setSave(row) {
      var _this6 = this;

      row.isShow = !row.isShow;
      newflashQueryUpdateById(row).then(function (res) {
        _this6.$message.success('修改成功');
      }).catch(function (res) {
        row.isShow = !row.isShow;

        _this6.$message.error('修改失败');
      });
    },
    // 列表
    getList: function getList() {
      var _this7 = this;

      this.listLoading = true;

      if (this.tableFrom.formatDate) {
        this.tableFrom.formatDate = parseTime(this.tableFrom.formatDate, '{y}-{m}-{d}');
      }

      newflashQueryPage(this.tableFrom).then(function (res) {
        _this7.listData.list = res.list;
        _this7.listData.total = res.totalCount;
        _this7.listLoading = false;
      }).catch(function () {
        _this7.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};