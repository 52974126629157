import request from '@/utils/request'; // 视频爬取计划
// 删除

export function spiderVideoDeleteByIds(data) {
  return request({
    url: '/spiderVideo/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 视频爬取计划主键查询

export function spiderVideoGetById(data) {
  return request({
    url: '/spiderVideo/auth/getById',
    method: 'post',
    data: data
  });
} // 视频爬取计划列表查询

export function spiderVideoueryList(data) {
  return request({
    url: '/spiderVideo/auth/queryList',
    method: 'post',
    data: data
  });
} // 视频爬取计划分页列表

export function spiderVideoQueryPage(data) {
  return request({
    url: '/spiderVideo/auth/queryPage',
    method: 'post',
    data: data
  });
} // 视频爬取计划保存

export function spiderVideoQuerySave(data) {
  return request({
    url: '/spiderVideo/auth/save',
    method: 'post',
    data: data
  });
} // 视频爬取计划修改

export function spiderVideoUpdateById(data) {
  return request({
    url: '/spiderVideo/auth/updateById',
    method: 'post',
    data: data
  });
} // 爬虫列表导出

export function spiderVideoderiveList(data) {
  return request({
    url: '/spiderVideo/auth/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}