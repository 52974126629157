var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "300",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "celebrity" }, [
            _c("div", { staticClass: "celebrity_item" }, [
              _c("div", { staticClass: "lt" }, [_vm._v("链接地址:")]),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.celebrityLink,
                      callback: function ($$v) {
                        _vm.celebrityLink = $$v
                      },
                      expression: "celebrityLink",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "celebrity_item" }, [
              _c("div", { staticClass: "lt" }, [_vm._v("文本(选填):")]),
              _c(
                "div",
                { staticClass: "rt" },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.celebrityText,
                      callback: function ($$v) {
                        _vm.celebrityText = $$v
                      },
                      expression: "celebrityText",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }