//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { eventUpdateById, eventSave } from '@/api/newsFlash/newsFlash';
import ueditorFrom from '@/components/ueditorFrom';
import { languageType } from '@/utils/enum';
import { categoryQueryList } from '@/api/article/article';
import addCurrency from './addCurrency';
export default {
  name: 'AddFamous',
  components: {
    ueditorFrom: ueditorFrom,
    addCurrency: addCurrency
  },
  data: function data() {
    return {
      languageType: languageType,
      formModal: {
        id: '',
        title: '',
        // 内容标题
        content: '',
        // 来源名称
        categoryId: [],
        // 分类
        language: 'cn'
      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      languageList: [{
        value: 'cn',
        label: '简体'
      }, {
        value: 'tn',
        label: '繁体'
      }, {
        value: 'en',
        label: '英语'
      }],
      articleType: [],
      currencyIdList: [],
      coincelebrityData: '',
      rules: {
        title: [{
          required: true,
          message: '请输入内容标题',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        categoryId: [{
          required: true,
          message: '请选择分类',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.articleList();
  },
  methods: {
    checkformModal: function checkformModal(val) {
      var _this = this;

      // let resultArr = this.articleType.filter((item)=>{
      //   return item.id == val
      // });
      // this.formModal.categoryName = resultArr[0].zbldmc
      this.formModal.categoryName = [];
      val.forEach(function (element) {
        for (var i = 0; i < _this.articleType.length; i++) {
          if (_this.articleType[i].id == element) {
            _this.formModal.categoryName.push(_this.articleType[i].name);
          }
        }
      });
    },
    handleList: function handleList(val) {
      // 弹窗回调
      console.log(val);
      this.coincelebrityData = val;
      this.formModal.currencyId = val.id;
      this.formModal.nameEn = val.nameEn;
    },
    handleTagClose: function handleTagClose() {
      this.coincelebrityData = {};
      this.formModal.currencyId = '';
      this.formModal.nameEn = '';
    },
    showCurrency: function showCurrency() {
      this.$refs.addCurrency.setting.visible = true;
      this.$refs.addCurrency.setting.title = '选择关联币种';
      this.$refs.addCurrency.coincelebrityRadio = '';
      this.$refs.addCurrency.coincelebrityData = JSON.parse(JSON.stringify(this.formModal.currencyId)) ? JSON.parse(JSON.stringify(this.formModal.currencyId)) : [];
      this.$refs.addCurrency.getList();
    },
    checklanguage: function checklanguage() {
      this.formModal.categoryId = [];
      this.articleList();
    },
    articleList: function articleList() {
      var _this2 = this;

      var par = {
        type: 5,
        language: this.formModal.language
      };
      categoryQueryList(par).then(function (res) {
        _this2.articleType = res;

        _this2.$forceUpdate();
      }).catch(function () {// this.listLoading = false
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      eventUpdateById(this.formModal).then(function (res) {
        _this3.$message.success('修改成功'); // 重置


        _this3.$refs.formModal.resetFields();

        _this3.handleClose();
      }).catch(function (res) {
        _this3.$message.error('修改失败');
      });
    },
    settingEvent: function settingEvent() {
      var _this4 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var categoryNameList = "";
          var categoryIdList = "";

          _this4.formModal.categoryName.forEach(function (e, index) {
            if (_this4.formModal.categoryName.length - 1 == index) {
              categoryNameList = categoryNameList + e;
            } else {
              categoryNameList = categoryNameList + e + ',';
            }
          });

          _this4.formModal.categoryId.forEach(function (e, index) {
            if (_this4.formModal.categoryId.length - 1 == index) {
              categoryIdList = categoryIdList + e;
            } else {
              categoryIdList = categoryIdList + e + ',';
            }
          });

          _this4.formModal.categoryName = categoryNameList;
          _this4.formModal.categoryId = categoryIdList; // this.formModal.categoryName = JSON.stringify(this.formModal.categoryName)
          // this.formModal.categoryId = JSON.stringify(this.formModal.categoryId)

          if (_this4.formModal.id) {
            // this.formModal.sourceId = this.formModal.id
            _this4.handleUpdate();
          } else {
            eventSave(_this4.formModal).then(function (res) {
              _this4.$message.success('添加成功'); // 重置


              _this4.$refs.formModal.resetFields();

              _this4.handleClose();
            }).catch(function (res) {
              _this4.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};