import request from '@/utils/request'; // 币界版块
// 版块删除

export function plateDeleteByIds(data) {
  return request({
    url: '/cms/plate/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 版块主键查询

export function plateGetById(data) {
  return request({
    url: '/cms/plate/auth/getById',
    method: 'post',
    data: data
  });
} // 版块列表查询

export function plateQueryList(data) {
  return request({
    url: '/cms/plate/auth/queryList',
    method: 'post',
    data: data
  });
} // 版块分页列表

export function plateQueryPage(data) {
  return request({
    url: '/cms/plate/auth/queryPage',
    method: 'post',
    data: data
  });
} // 版块保存

export function plateSave(data) {
  return request({
    url: '/cms/plate/auth/save',
    method: 'post',
    data: data
  });
} // 版块修改

export function plateUpdateById(data) {
  return request({
    url: '/cms/plate/auth/updateById',
    method: 'post',
    data: data
  });
}