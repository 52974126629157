var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "panel-group", attrs: { gutter: 20 } },
    [
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 8, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.handleSetLineChartData("shoppings")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-head" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/dashboard/svg-.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                  _vm._v(" 游客新增用户 "),
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("第一次启动应用的用户（App以设备为判断标准）"),
                    ]),
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "card-panel-today" }, [
                _vm._v("今日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.increaseVisitor))]),
              ]),
              _c("div", { staticClass: "card-panel-yesterday" }, [
                _vm._v("昨日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.increaseVisitorYes))]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 8, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.handleSetLineChartData("shoppings")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-head" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/dashboard/youke.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                  _vm._v(" 游客活跃用户 "),
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "启动过应用用户（去重），启动过一次的用户即视为活跃用户，包括新游客用户与老游客用户。"
                      ),
                    ]),
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "card-panel-today" }, [
                _vm._v("今日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.activeVisitor))]),
              ]),
              _c("div", { staticClass: "card-panel-yesterday" }, [
                _vm._v("昨日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.activeVisitorYes))]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 8, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.handleSetLineChartData("purchases")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-head" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/dashboard/dianji.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                  _vm._v(" 启动次数 "),
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("截止至统计时间，应用的总启动次数。"),
                    ]),
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "card-panel-today" }, [
                _vm._v("今日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.up))]),
              ]),
              _c("div", { staticClass: "card-panel-yesterday" }, [
                _vm._v("昨日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.upYes))]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 8, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.handleSetLineChartData("purchases")
                },
              },
            },
            [
              _c("div", { staticClass: "card-panel-head" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../../assets/dashboard/dianji.png"),
                    alt: "",
                    srcset: "",
                  },
                }),
                _vm._v(" 注册量 "),
              ]),
              _c("div", { staticClass: "card-panel-today" }, [
                _vm._v("截止今日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.register))]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 8, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.handleSetLineChartData("shoppings")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-head" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/dashboard/svg-.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                  _vm._v(" 新增用户 "),
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("第一次注册应用的用户（App以设备为判断标准）"),
                    ]),
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "card-panel-today" }, [
                _vm._v("今日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.increase))]),
              ]),
              _c("div", { staticClass: "card-panel-yesterday" }, [
                _vm._v("昨日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.increaseYes))]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 8, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.handleSetLineChartData("shoppings")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-head" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/dashboard/youke.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                  _vm._v(" 活跃用户 "),
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "启动过应用的注册用户（去重），启动过一次的用户即视为活跃用户，包括新用户与老用户。"
                      ),
                    ]),
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "card-panel-today" }, [
                _vm._v("今日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.active))]),
              ]),
              _c("div", { staticClass: "card-panel-yesterday" }, [
                _vm._v("昨日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.activeYes))]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 8, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.handleSetLineChartData("newVisitis")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-head" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/dashboard/xinzengyonghu.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                  _vm._v(" 回流用户 "),
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("超过7天没启动APP的注册用户在今天启动了APP"),
                    ]),
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "card-panel-today" }, [
                _vm._v("今日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.back))]),
              ]),
              _c("div", { staticClass: "card-panel-yesterday" }, [
                _vm._v("昨日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.backYes))]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 8, lg: 6 } },
        [
          _c(
            "div",
            {
              staticClass: "card-panel",
              on: {
                click: function ($event) {
                  return _vm.handleSetLineChartData("messages")
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-panel-head" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/dashboard/huoyuedu.png"),
                      alt: "",
                      srcset: "",
                    },
                  }),
                  _vm._v(" 沉默用户 "),
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v("注册APP后的28天内没有启动APP的用户（去重）。"),
                    ]),
                    _c("i", { staticClass: "el-icon-warning-outline" }),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "card-panel-today" }, [
                _vm._v("今日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.reticent))]),
              ]),
              _c("div", { staticClass: "card-panel-yesterday" }, [
                _vm._v("昨日 "),
                _c("span", [_vm._v(_vm._s(_vm.listData.reticentYes))]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }