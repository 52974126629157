//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberLabelqueryPage, savequeryPage } from '@/api/member/member';
import { labelqueryPage, labelupdateById, labelsave } from '@/api/member/member_zh';
export default {
  name: 'userLabes',
  components: {},
  data: function data() {
    return {
      setting: {
        title: '用户标签',
        visible: false
      },
      freezeLoading: false,
      gridData: [],
      Integraldata: {
        memberId: 0,
        page: 1,
        limit: 20,
        total: 0
      },
      dialog: false,
      // memberId: 0
      fans: {
        page: 1,
        limit: 20
      },
      labelList: [],
      newlabelList: '',
      addloading: false,
      multipleSelection: []
    };
  },
  mounted: function mounted() {// this.spiderSourceList()
    // this.getLableList()
  },
  methods: {
    toInteger: function toInteger() {// let reg = /^[0-9]+$/
      // if (!reg.test(this.fans.count)) {
      //   this.$message.warning('只能输入整数')
      //   // 用以在dom渲染挂载后重新触发dom渲染挂载
      //   this.$nextTick(() => {
      //     this.fans.count = parseInt(this.fans.count)
      //   })
      // }
    },
    updlabel: function updlabel() {
      var _this2 = this;

      if (this.multipleSelection.length == 0) {
        this.$message.error('请选择用户标签');
        return false;
      }

      var par = {
        memberId: this.Integraldata.memberId,
        labelIds: this.multipleSelection
      };
      savequeryPage(par).then(function (res) {
        _this2.$message.success('更新成功');

        _this2.dialog = false;
      }).catch(function (res) {
        _this2.$message.error('查询失败');
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this3 = this;

      this.multipleSelection = [];
      val.forEach(function (e) {
        _this3.multipleSelection.push(e.id);
      }); // console.log(this.multipleSelection)
    },
    getLableList: function getLableList() {
      var _this4 = this;

      this.dialog = true;

      var _this = this;

      _this.labelList = [];
      labelqueryPage(this.fans).then(function (res) {
        _this4.labelList = res.list;
        _this4.fans.total = res.totalCount;

        if (_this.gridData.length == 0) {} else {
          _this4.$nextTick(function () {
            res.list.forEach(function (e, index) {
              _this.gridData.forEach(function (a) {
                if (e.id == a.labelId) {
                  _this4.$refs.multipleTable.toggleRowSelection(e);
                }
              });
            });
          });
        }
      }).catch(function (res) {
        _this4.$message.error('查询失败');
      });
    },
    checkstatus: function checkstatus(data) {},
    // checkstatus(){
    //   // console.log(this.gridData)
    //    if(this.gridData == ''){
    //       this.labelList.forEach((e,index) => {
    //         e.status = 0
    //       });
    //    }else{
    //       this.gridData.forEach((e,index) => {
    //         this.labelList.forEach((a,index) => {
    //           if(e.labelName == a.labelName){
    //             a.status = 1
    //           }else{
    //             a.status = 0
    //           }
    //         })
    //       });
    //    }
    //   // console.log(this.labelList)
    // },
    addLabel: function addLabel(data) {
      this.gridData.push(data);
      data.status = 1; // this.checkstatus()
    },
    delLabel: function delLabel() {},
    addfans: function addfans() {
      var _this5 = this;

      this.addloading = true;
      var reg = /^[0-9]+$/;

      if (!reg.test(this.fans.count)) {
        this.$message.warning('只能输入整数');
        return false; // 用以在dom渲染挂载后重新触发dom渲染挂载

        this.$nextTick(function () {
          _this5.fans.count = parseInt(_this5.fans.count);
        });
      }

      addMemberFollow(this.fans).then(function (res) {
        // this.gridData = res.list
        _this5.addloading = false;
        _this5.dialog = false;

        _this5.getFansList();
      }).catch(function (res) {
        _this5.$message.error('新增失败');
      });
    },
    IntegralpageChange: function IntegralpageChange(page) {
      this.Integraldata.page = page;
      console.log(this.Integraldata);
      this.getFansList();
    },
    IntegralSizeChange: function IntegralSizeChange(val) {
      console.log(this.Integraldata);
      this.Integraldata.limit = val;
      this.getFansList();
    },
    fansSizeChange: function fansSizeChange(page) {
      this.fans.page = page;
      this.getLableList();
    },
    fanspageChange: function fanspageChange(val) {
      this.fans.limit = val;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getLableList();
    },
    getFansList: function getFansList() {
      var _this6 = this;

      memberLabelqueryPage(this.Integraldata).then(function (res) {
        // 重置
        // this.$refs.formModal.resetFields()
        // console.log(res.list)
        _this6.gridData = res.list;
        _this6.Integraldata.total = res.totalCount;

        _this6.getLableList();
      }).catch(function (res) {
        _this6.$message.error('查询失败');
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.freezeLoading = false;
      this.setting.visible = false;
    },
    dialoghandleClose: function dialoghandleClose() {
      this.dialog = false;
      this.setting.visible = false;
    }
  }
};