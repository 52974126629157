var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "famous" },
    [
      _c("div", { staticClass: "famous_header" }, [
        _c("div", { staticClass: "famous_lt" }, [
          _c(
            "div",
            { staticClass: "famous_item" },
            [
              _c("div", { staticClass: "text" }, [_vm._v("审核状态：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择审核状态" },
                  model: {
                    value: _vm.tableFrom.state,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "state", $$v)
                    },
                    expression: "tableFrom.state",
                  },
                },
                _vm._l(_vm.registerType, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "famous_item" },
            [
              _c("div", { staticClass: "text" }, [_vm._v("旧昵称：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入旧昵称" },
                model: {
                  value: _vm.tableFrom.nicknameOld,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "nicknameOld", $$v)
                  },
                  expression: "tableFrom.nicknameOld",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "famous_rt" },
          [
            _c(
              "el-button",
              { attrs: { type: "danger" }, on: { click: _vm.handleReset } },
              [_vm._v("重置")]
            ),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.handlerState } },
              [_vm._v("筛选")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "famous_content" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.listData.list,
                size: "mini",
                "max-height": "500",
                "highlight-current-row": "",
                "tooltip-effect": "dark",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "nicknameOld", label: "用户名称（旧）" },
              }),
              _c("el-table-column", {
                attrs: { label: "用户名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { "background-color": "yellow" } },
                          [_vm._v(_vm._s(scope.row.nickname))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "signOld", label: "个性签名（旧）" },
              }),
              _c("el-table-column", {
                attrs: { label: "个性签名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { "background-color": "yellow" } },
                          [_vm._v(_vm._s(scope.row.sign))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "头像（旧）" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.headerOld
                          ? _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: scope.row.headerOld,
                                "preview-src-list": [scope.row.headerOld],
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "头像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.header
                          ? _c("el-image", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: scope.row.header,
                                "preview-src-list": [scope.row.header],
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "提交时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.createTime
                          ? _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDateTime2(scope.row.createTime)
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "审核状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == 0
                          ? _c("div", [_vm._v("未审核")])
                          : _vm._e(),
                        scope.row.state == 1
                          ? _c("div", [_vm._v("审核成功")])
                          : _vm._e(),
                        scope.row.state == 2
                          ? _c("div", [_vm._v("审核不通过")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state != 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getlableid(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 修改 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block mb20" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [5, 10, 20, 30, 40],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.listData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.diaTitle,
            visible: _vm.dialogIntegral,
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogIntegral = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.label } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择注册类型" },
                      model: {
                        value: _vm.label.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.label, "state", $$v)
                        },
                        expression: "label.state",
                      },
                    },
                    _vm._l(_vm.stateType, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogIntegral = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.updLabel()
                    },
                  },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }