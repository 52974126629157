var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "famous" }, [
    _c("div", { staticClass: "famous_header" }, [
      _c("div", { staticClass: "famous_lt" }, [
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("语言：")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.tableFrom.language,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "language", $$v)
                  },
                  expression: "tableFrom.language",
                },
              },
              _vm._l(Object.keys(_vm.languageType), function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: _vm.languageType[item], value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("类型：")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.tableFrom.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "type", $$v)
                  },
                  expression: "tableFrom.type",
                },
              },
              _vm._l(_vm.translateFlagSelect, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "famous_rt" },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerState } },
            [_vm._v("筛选")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "famous_btn" }, [
      _c(
        "div",
        { staticClass: "famouts_btn_rt" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addCelebrity } },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "famous_content" },
      [
        _c(
          "el-table",
          {
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.listData.list,
              size: "mini",
              "highlight-current-row": "",
              "tooltip-effect": "dark",
              "header-cell-style": { "text-align": "center" },
              "cell-style": { "text-align": "center" },
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
            _c("el-table-column", {
              attrs: { prop: "content", label: "提问内容" },
            }),
            _c("el-table-column", { attrs: { prop: "weight", label: "权重" } }),
            _c("el-table-column", {
              attrs: { label: "语言" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.language == "cn"
                        ? _c("div", [_vm._v("简体")])
                        : _vm._e(),
                      scope.row.language == "tn"
                        ? _c("div", [_vm._v("繁体")])
                        : _vm._e(),
                      scope.row.language == "en"
                        ? _c("div", [_vm._v("英文")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "是否显示" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.optionshowEnable(scope.row)
                          },
                        },
                        model: {
                          value: scope.row.showEnable,
                          callback: function ($$v) {
                            _vm.$set(scope.row, "showEnable", $$v)
                          },
                          expression: "scope.row.showEnable",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.type == 1
                        ? _c("div", [_vm._v("引导提问")])
                        : _vm._e(),
                      scope.row.type == 2
                        ? _c("div", [_vm._v("便携提问")])
                        : _vm._e(),
                      scope.row.type == 3
                        ? _c("div", [_vm._v("反馈提问")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "更新时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDateTime3(scope.row.updateTime)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "120", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      !scope.row.freezeTime
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleFreeze(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: { title: _vm.diaform.title, visible: _vm.dialogFormVisible },
            on: {
              "update:visible": function ($event) {
                _vm.dialogFormVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              { attrs: { model: _vm.crawkerData } },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "语言", "label-width": _vm.formLabelWidth },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.crawkerData.language,
                          callback: function ($$v) {
                            _vm.$set(_vm.crawkerData, "language", $$v)
                          },
                          expression: "crawkerData.language",
                        },
                      },
                      _vm._l(Object.keys(_vm.languageType), function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: { label: _vm.languageType[item], value: item },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "问题类型",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.crawkerData.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.crawkerData, "type", $$v)
                          },
                          expression: "crawkerData.type",
                        },
                      },
                      _vm._l(_vm.translateFlagSelect, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "问题内容",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.crawkerData.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.crawkerData, "content", $$v)
                        },
                        expression: "crawkerData.content",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "权重", "label-width": _vm.formLabelWidth },
                  },
                  [
                    _c("el-input-number", {
                      attrs: { min: 1 },
                      model: {
                        value: _vm.crawkerData.weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.crawkerData, "weight", $$v)
                        },
                        expression: "crawkerData.weight",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "是否显示",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-switch", {
                      attrs: {
                        "active-value": 1,
                        "inactive-value": 0,
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                      },
                      model: {
                        value: _vm.crawkerData.showEnable,
                        callback: function ($$v) {
                          _vm.$set(_vm.crawkerData, "showEnable", $$v)
                        },
                        expression: "crawkerData.showEnable",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogFormVisible = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.crawlerupd } },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "block mb20" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [5, 10, 20, 30, 40],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.listData.totalCount,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }