//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberqueryPage, groupMessagequeryPage, groupMemberqueryPage, setgroupMemberqueryPage } from '@/api/member/member_zh';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
import { parseTime } from '@/utils';
export default {
  name: 'UserControl',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        accountType: 1,
        nickname: '',
        memberId: '',
        sortByBalance: '',
        sortByBalanceRule: '' // keywords: ''

      },
      gridData: [],
      gridData2: [],
      dialogIntegral: false,
      dialogIntegral2: false,
      dialogIntegral3: false,
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: '',
      form: {
        memberId: 0,
        amount: 0,
        remark: ''
      },
      Integraldata: {
        page: 1,
        limit: 20,
        total: 0,
        groupId: ''
      },
      Integraldata2: {
        page: 1,
        limit: 20,
        total: 0,
        groupId: ''
      },
      userStatus: [{
        value: 0,
        label: '已申请'
      }, {
        value: 1,
        label: '已通过'
      }, {
        value: 2,
        label: '未通过'
      }, {
        value: 3,
        label: '退群'
      }, {
        value: 4,
        label: '移除'
      }],
      memberTypeList: [{
        value: 0,
        label: '普通用户'
      }, {
        value: 1,
        label: '管理员'
      }, {
        value: 2,
        label: '群主'
      }],
      status: 0,
      UserInfo: {
        id: '',
        memberId: '',
        groupId: '',
        status: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    upddio2Status: function upddio2Status() {
      this.dialogIntegral2 = false;
      this.Integraldata2.status = '';
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20,
        accountType: 1,
        nickname: '',
        memberId: ''
      };
      this.getList();
    },
    getformid: function getformid(id) {
      this.dialogIntegral = true;
      this.Integraldata.groupId = id;
      this.showIntegralList();
    },
    getformid2: function getformid2(id) {
      this.dialogIntegral2 = true;
      this.Integraldata2.groupId = id;
      this.showIntegralList2();
    },
    showdio: function showdio(memberId, groupId, id) {
      this.dialogIntegral3 = true;
      this.UserInfo.memberId = memberId;
      this.UserInfo.groupId = groupId;
      this.UserInfo.id = id;
      console.log(this.UserInfo);
    },
    setUserStatus: function setUserStatus() {
      var _this = this;

      var UserInfo = {
        id: this.UserInfo.id,
        memberId: this.UserInfo.memberId,
        groupId: this.UserInfo.groupId,
        status: this.UserInfo.status
      };
      setgroupMemberqueryPage(UserInfo).then(function (res) {
        _this.$message({
          message: '修改成功',
          type: 'success'
        });

        _this.dialogIntegral3 = false;

        _this.showIntegralList2();

        var UserInfo = {
          id: '',
          memberId: '',
          groupId: '',
          status: ''
        };
      }).catch(function () {});
    },
    showIntegralList: function showIntegralList() {
      var _this2 = this;

      groupMessagequeryPage(this.Integraldata).then(function (res) {
        _this2.gridData = res.list;
        _this2.Integraldata.total = res.totalCount;
      }).catch(function () {});
    },
    showIntegralList2: function showIntegralList2() {
      var _this3 = this;

      groupMemberqueryPage(this.Integraldata2).then(function (res) {
        _this3.gridData2 = res.list;
        _this3.Integraldata2.total = res.totalCount;
      }).catch(function () {});
    },
    IntegralSizeChange: function IntegralSizeChange(val) {
      this.Integraldata.limit = val;
      this.showIntegralList();
    },
    IntegralpageChange: function IntegralpageChange(page) {
      this.Integraldata.page = page;
      this.showIntegralList();
    },
    Integral2SizeChange: function Integral2SizeChange(val) {
      this.Integraldata2.limit = val;
      this.showIntegralList();
    },
    Integral2pageChange: function Integral2pageChange(page) {
      this.Integraldata2.page = page;
      this.showIntegralList2();
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;

      if (this.tableFrom.formatDate) {
        this.tableFrom.formatDate = parseTime(this.tableFrom.formatDate, '{y}-{m}-{d}');
      }

      memberqueryPage(this.tableFrom).then(function (res) {
        _this4.listData.list = res.list;
        _this4.listData.total = res.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    spiderSourceList: function spiderSourceList() {
      var _this5 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this5.comeFrom = res;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleFreeze: function handleFreeze(data) {
      console.log(data);
      var par = {
        id: data.memberId
      };
      memberAccountgetById(par).then(function (res) {});
      this.dialogTableVisible = true;
    }
  }
};