//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { communityContentSave, communityTopicQueryList } from '@/api/community/community';
import { memberQueryList } from '@/api/member/member';
import ueditorFrom from '@/components/ueditorFrom';
export default {
  name: 'AddFamous',
  components: {
    ueditorFrom: ueditorFrom
  },
  data: function data() {
    return {
      formModal: {
        createBy: '',
        // 发布人
        contentType: '',
        // 分类
        topicId: '',
        // 话题
        content: '',
        // 内容
        image: '',
        // 图片
        url: [],
        // 超链接
        likeNum: '',
        // 点赞数
        discussNum: '',
        // 评论数
        forwardNum: '',
        // 转发数
        showEnable: true,
        // 状态
        auditStatus: '1' // 审核通过

      },
      dnamicSelect: [{
        valule: '1',
        label: '开启'
      }, {
        value: '0',
        label: '隐藏'
      }],
      topicList: {},
      // 发布人
      memberList: {},
      // 话题
      comeFrom: [{
        value: '1',
        label: '纯文本'
      }, {
        value: '2',
        label: '图文结合'
      }, {
        value: '3',
        label: '文+视频'
      }],
      rules: {
        createBy: [{
          required: true,
          message: '请输入发布人',
          trigger: 'blur'
        }],
        contentType: [{
          required: true,
          message: '请输入分类',
          trigger: 'blur'
        }],
        topicId: [{
          required: true,
          message: '请输入话题',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '图片不能为空',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '超链接不能为空',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  computed: {
    visible: function visible() {
      return this.setting.visible;
    }
  },
  watch: {
    visible: function visible(newValue, oldValue) {
      if (newValue) {
        this.getMemberList();
        this.getTopicList();
      }
    }
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      this.formModal.url.splice(this.formModal.url.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this = this;

      if (this.formModal.url.length > 2) {
        this.$message.error('标签最多可以添加3个');
        return;
      }

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    // 发布人
    getTopicList: function getTopicList() {
      var _this2 = this;

      communityTopicQueryList({}).then(function (res) {
        _this2.topicList = res;
      }).catch(function () {});
    },
    // 话题
    getMemberList: function getMemberList() {
      var _this3 = this;

      memberQueryList({}).then(function (res) {
        _this3.memberList = res;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.url.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this4 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      console.log(formData, param.file, 'formData');
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this4.$message.success('上传成功');

        _this4.formModal.image = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this5 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          // 深拷贝 转换url
          var par = JSON.parse(JSON.stringify(_this5.formModal));
          par.url = "".concat(par.url);
          communityContentSave(par).then(function (res) {
            _this5.$message.success('添加成功'); // 重置


            _this5.$refs.formModal.resetFields();

            _this5.handleClose();
          }).catch(function (res) {
            _this5.$message.error('添加失败');
          });
        }
      });
    }
  }
};