import request from '@/utils/request'; // 币界版块
// 交易所-动态删除

export function exchangeDeleteByIds(data) {
  return request({
    url: '/cms/exchange/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 交易所主键查询

export function exchangeGetById(data) {
  return request({
    url: '/cms/exchange/auth/getById',
    method: 'post',
    data: data
  });
} // 交易所列表查询

export function exchangeQueryList(data) {
  return request({
    url: '/cms/exchange/auth/queryList',
    method: 'post',
    data: data
  });
} // 交易所分页列表

export function exchangeQueryPage(data) {
  return request({
    url: '/cms/exchange/auth/queryPage',
    method: 'post',
    data: data
  });
} // 交易所保存

export function exchangeSave(data) {
  return request({
    url: '/cms/exchange/auth/save',
    method: 'post',
    data: data
  });
} // 交易所修改

export function exchangeUpdateById(data) {
  return request({
    url: '/cms/exchange/auth/updateById',
    method: 'post',
    data: data
  });
}