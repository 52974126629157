import request from '@/utils/request'; // 币界版块
// 币种-动态删除

export function currencyDeleteByIds(data) {
  return request({
    url: '/cms/coin/auth/currency/deleteByIds',
    method: 'post',
    data: data
  });
} // 币种主键查询

export function currencyGetById(data) {
  return request({
    url: '/cms/coin/auth/currency/getById',
    method: 'post',
    data: data
  });
} // 币种列表查询

export function currencyQueryList(data) {
  return request({
    url: '/cms/coin/auth/currency/queryList',
    method: 'post',
    data: data
  });
} // 币种分页列表

export function currencyQueryPage(data) {
  return request({
    url: '/cms/coin/auth/currency/queryPage',
    method: 'post',
    data: data
  });
} // 币种保存

export function currencySave(data) {
  return request({
    url: '/cms/coin/auth/currency/save',
    method: 'post',
    data: data
  });
} // 币种修改

export function currencyUpdateById(data) {
  return request({
    url: '/cms/coin/auth/currency/updateById',
    method: 'post',
    data: data
  });
} // 币种tabs

export function currencyplateCurrencyqueryList(data) {
  return request({
    url: '/plateCurrency/auth/queryList',
    method: 'post',
    data: data
  });
} // 板块-币种保存

export function currencyplateCurrencysave(data) {
  return request({
    url: '/plateCurrency/auth/batchSave',
    method: 'post',
    data: data
  });
}