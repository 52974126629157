import request from '@/utils/request';
/**
 * 名人版块
 */

export function coincelebrityList(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebrity/queryList',
    method: 'post',
    data: data
  });
} // 分页列表

export function coincelebrityPage(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebrity/queryPage',
    method: 'post',
    data: data
  });
} // 名人保存

export function coincelebritySave(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebrity/save',
    method: 'post',
    data: data
  });
} // 名人修改

export function coincelebrityUpdateById(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebrity/updateById',
    method: 'post',
    data: data
  });
} // 主键查询

export function coincelebrityGetById(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebrity/getById',
    method: 'post',
    data: data
  });
} // 名人删除

export function coincelebrityDeleteByIds(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebrity/deleteByIds',
    method: 'post',
    data: data
  });
} // 名人用户关注分页列表

export function cmsCoinCelebrityFollowQueryPage(data) {
  return request({
    url: '/cms/celebrity/auth/cmsCoinCelebrityFollow/queryPage',
    method: 'post',
    data: data
  });
} // 名人动态版块接口
// 分页列表

export function coincelebritycontentPage(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebritycontent/queryPage',
    method: 'post',
    data: data
  });
} // 名人动态保存

export function coincelebritycontentSave(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebritycontent/save',
    method: 'post',
    data: data
  });
} // 名人动态信息修改

export function coincelebritycontentUpdateById(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebritycontent/updateById',
    method: 'post',
    data: data
  });
} // 名人动态信息列表查询

export function coincelebritycontentQueryList(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebritycontent/queryList',
    method: 'post',
    data: data
  });
} // 名人动态信息列表主键查询

export function coincelebritycontentGetById(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebritycontent/getById',
    method: 'post',
    data: data
  });
} // 名人动态信息列表删除

export function coincelebritycontentDeleteByIds(data) {
  return request({
    url: '/cms/celebrity/auth/coincelebritycontent/deleteByIds',
    method: 'post',
    data: data
  });
} // 名人评论版块接口
// 名人评论分页列表

export function coinCelebrityContentCommentPage(data) {
  return request({
    url: '/cms/celebrity/auth/coinCelebrityContentComment/queryPage',
    method: 'post',
    data: data
  });
} // 名人评论保存

export function coinCelebrityContentCommentSave(data) {
  return request({
    url: '/cms/celebrity/auth/coinCelebrityContentComment/save',
    method: 'post',
    data: data
  });
} // 名人评论信息修改

export function coinCelebrityContentCommentUpdateById(data) {
  return request({
    url: '/cms/celebrity/auth/coinCelebrityContentComment/updateById',
    method: 'post',
    data: data
  });
} // 名人评论信息列表查询

export function coinCelebrityContentCommentQueryList(data) {
  return request({
    url: '/cms/celebrity/auth/coinCelebrityContentComment/queryList',
    method: 'post',
    data: data
  });
} // 名人评论信息列表主键查询

export function coinCelebrityContentCommentGetById(data) {
  return request({
    url: '/cms/celebrity/auth/coinCelebrityContentComment/getById',
    method: 'post',
    data: data
  });
} // 名人评论信息列表删除

export function coinCelebrityContentCommentDeleteByIds(data) {
  return request({
    url: '/cms/celebrity/auth/coinCelebrityContentComment/deleteByIds',
    method: 'post',
    data: data
  });
}