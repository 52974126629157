//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberActivityCommentRecord, memberActivityCommentexamine, memberActivityCommentRecordHistory } from '@/api/member/member_zh';
import { formatDateTime2 } from '@/utils';
export default {
  name: 'UserControl',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        auditStatus: '',
        page: 1,
        limit: 20
      },
      registerType: [{
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '审核成功'
      }, {
        value: 2,
        label: '审核不通过'
      }],
      listData: {
        list: [],
        total: 0
      },
      //标签弹窗
      dialogIntegral: false,
      dialogIntegral2: false,
      diaTitle: "标签新增",
      type: '',
      label: {
        id: '',
        auditStatus: ''
      },
      auditStatusType: [{
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '审核成功'
      }, {
        value: 2,
        label: '审核不通过'
      }],
      tablehis: ""
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    formatDateTime2: formatDateTime2,
    getGuess: function getGuess(id) {
      var _this = this;

      memberActivityCommentRecordHistory({
        memberId: id
      }).then(function (res) {
        if (res.list.length == 1) {
          _this.$message.warning('暂无数据');
        } else {
          _this.tablehis = res.list;
          _this.dialogIntegral2 = true;
        }
      }).catch(function () {
        _this.$message.warning('查询失败');
      });
    },
    getlableid: function getlableid(data) {
      this.label = data;
      this.dialogIntegral = true; // if(data.auditStatus == 1){
      //   this.auditStatusType = [
      //     {
      //       value:1,
      //       label:'审核成功'
      //     },
      //     {
      //       value:2,
      //       label:'审核不通过'
      //     }
      //   ]
      // }else{
      //   this.auditStatusType = [
      //       {
      //         value:0,
      //         label:'未审核'
      //       },
      //       {
      //         value:1,
      //         label:'审核成功'
      //       },{
      //         value:2,
      //         label:'审核不通过'
      //       }
      //   ]
      // }
    },
    updLabel: function updLabel() {
      var _this2 = this;

      memberActivityCommentexamine(this.label).then(function (res) {
        _this2.dialogIntegral = false;

        _this2.$message.success('修改成功');
      }).catch(function () {
        _this2.$message.error('修改失败');
      });
    },
    showdio: function showdio() {
      this.label = {
        id: '',
        labelName: ''
      };
      this.type = 'add';
      this.dialogIntegral = true;
    },
    getList: function getList() {
      var _this3 = this;

      memberActivityCommentRecord(this.tableFrom).then(function (res) {
        _this3.listData.list = res.list;
        _this3.listData.total = res.totalCount;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20,
        auditStatus: ''
      };
      this.getList();
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    }
  }
};