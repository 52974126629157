//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { exchangeQueryPage, exchangeDeleteByIds, exchangeUpdateById } from '@/api/exchange/exchange';
import addDynamic from './components/addDynamic';
import { parseTime } from '@/utils';
export default {
  name: 'Bourse',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        nameZh: '' // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handleScreen: function handleScreen() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.nameZh = '';
      this.getList();
    },
    handleOpen: function handleOpen(row) {
      var _this = this;

      this.$confirm("\u786E\u8BA4".concat(row.dataStatus ? '隐藏' : '开启', "\u5F53\u524D\u4EA4\u6613\u6240"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.setSave(row);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.dataStatus ? '隐藏' : '开启')
        });
      });
    },
    setSave: function setSave(row) {
      var _this2 = this;

      if (row.dataStatus) {
        row.dataStatus = 0;
      } else {
        row.dataStatus = 1;
      }

      exchangeUpdateById(row).then(function (res) {
        _this2.$message.success('修改成功');
      }).catch(function (res) {
        if (row.dataStatus) {
          row.dataStatus = 0;
        } else {
          row.dataStatus = 1;
        }

        _this2.$message.error('修改失败');
      });
    },
    handlerState: function handlerState(statue, val) {
      var formDisable = false;
      var formModal = {
        id: '',
        // id
        nameZh: '',
        // 交易所名称
        pairNum: '',
        // 交易对数量
        volUsd: '',
        // 24H成交额
        logo: '',
        // 交易所图片
        // sortByRank: '', // 序号
        sort: '',
        // 排名
        score: '',
        // 综合评分
        url: '',
        // 网址
        link: '',
        label: [],
        // 标签
        synopsis: '',
        // 基本信息
        launchedTime: '',
        // 成立时间
        country: '',
        // 注册地
        dataStatus: 1 // 状态

      };

      if (statue === 3) {
        formDisable = true; // 禁止编辑状态
      }

      if (statue !== 1) {
        formModal = val;
      }

      this.$router.push({
        name: 'AddBourse',
        params: {
          statue: statue,
          // 状态1 新增 2 编辑 3 查看
          formModal: formModal,
          formDisable: formDisable
        }
      });
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity() {
      // 重置弹窗
      this.$refs.addDynamic.formModal = {
        celebrityName: '',
        // 关联名人
        sourceType: '',
        // 来自
        image: '',
        // 头像
        content: '',
        // 内容
        likeNum: '',
        // 总获赞量
        discussNum: '',
        // 总评论量
        forwardNum: '',
        // 总被分享量
        dataStatus: '1' // 状态

      };
      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this3 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      exchangeDeleteByIds(par.ids).then(function (res) {
        _this3.$message.success('删除成功');

        _this3.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      exchangeQueryPage(this.tableFrom).then(function (res) {
        _this4.listData.list = res.list;
        _this4.listData.total = res.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};