//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { menuList, menuDelete } from '@/api/menu';
import addDynamic from './components/addDynamic';
import { parseTime } from '@/utils';
export default {
  name: 'MenuManager',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      value: '',
      input: '',
      comeFrom: [],
      tableFrom: {
        page: 1,
        limit: 3000,
        version: '' // keywords: ''

      },
      listLoading: false,
      listData: [],
      multipleSelection: [],
      tableSelection: '',
      dialogTableVisible: false,
      dialist: [],
      disnum: ''
    };
  },
  mounted: function mounted() {
    // this.spiderSourceList()
    this.getList();
    console.log('进来哦我');
  },
  methods: {
    parseTime: parseTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    handleOpen: function handleOpen(row) {
      var _this = this;

      this.$confirm("\u786E\u8BA4".concat(row.isShow ? '关闭' : '开启', "\u5F53\u524D\u5FEB\u8BAF"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.setSave(row);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.isShow ? '开启' : '关闭')
        });
      });
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.version = '';
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      this.tableSelection = row;
      row.map(function (item) {
        arr.push(item.menuId);
      });
      this.multipleSelection = arr;
    },
    handleCut: function handleCut(row, key, value) {
      var _this2 = this;

      this.$confirm("\u786E\u8BA4".concat(row[key] ? '关闭' : '开启', "\u5F53\u524D").concat(value), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.setSave(row, key);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row[key] ? '开启' : '关闭')
        });
      });
    },
    // setSave(row, key) {
    //   row[key] = row[key] ^ 1
    //   appVersionGetById(row).then(res => {
    //     this.$message.success('修改成功')
    //   }).catch(res => {
    //     row[key] = row[key] ^ 1
    //     this.$message.error('修改失败')
    //   })
    // },
    // 打开弹窗
    addCelebrity: function addCelebrity(row, status) {
      if (row.menuId) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        this.$refs.addDynamic.formModal = par;
        this.$refs.addDynamic.setting.title = '编辑菜单';
        this.$refs.addDynamic.getMenuList();
      } else {
        // 重置弹窗
        this.$refs.addDynamic.formModal = {
          menuId: '',
          icon: '',
          // icon
          name: '',
          // 菜单名称
          outId: '',
          //
          orderNum: '',
          //
          parentId: '',
          //
          parentName: '',
          // 福可展开列表名称
          perms: '',
          //
          type: '',
          // 类型
          url: '' // 路由地址

        };
        this.$refs.addDynamic.setting.title = '添加菜单';
      }

      this.$refs.addDynamic.getMenuList(); // const list = this.listData.list.filter(item => item.parentId === 0)
      // this.$refs.addDynamic.parentType = JSON.parse(JSON.stringify(list))

      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this3 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      } else if (this.multipleSelection.length > 1) {
        this.$message.error('不能做批量删除，只能单个删除!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      console.log("".concat(par.ids), 'iddddd');
      menuDelete("".concat(this.multipleSelection)).then(function (res) {
        _this3.$message.success('删除成功');

        _this3.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true; // if (this.tableFrom.formatDate) {
      //   this.tableFrom.formatDate = parseTime(
      //     this.tableFrom.formatDate,
      //     '{y}-{m}-{d}'
      //   )
      // }

      this.listData.length = 0;
      menuList({}).then(function (res) {
        _this4.addarr(res.data);

        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    addarr: function addarr(data) {
      var _this5 = this;

      data.forEach(function (e) {
        if (e.type != 2) {
          var parentName = '';

          if (e.parentId == 0) {
            parentName = '一级路由';
          } else {
            parentName = e.parentId;
          }

          _this5.listData.push({
            menuId: e.menuId,
            name: e.name,
            parentName: parentName,
            parentId: e.parentId,
            url: e.url,
            type: e.type,
            routeName: e.routeName,
            icon: e.icon
          });

          if (e.list != undefined) {
            _this5.addarr(e.list);
          } // if (e.list != undefined) {
          //   if (e.list.type != 2) {
          //     this.addarr(e.list)
          //   }
          // }

        }
      }); // console.log(this.listData)
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection(); // this.getList()
    },
    // spiderSourceList() {
    //   spiderSourceQueryList({ salfFalg: 1 })
    //     .then((res) => {
    //       this.comeFrom = res
    //     })
    //     .catch(() => {})
    // },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val; // this.getList()
    },
    showdio: function showdio(id) {
      this.dialogTableVisible = true;
      this.disnum = id;
      this.getButtondio();
    },
    getButtondio: function getButtondio() {
      var _this6 = this;

      console.log('123');
      menuList({
        parentId: this.disnum.menuId
      }).then(function (res) {
        _this6.dialist = res.data;
      }).catch(function () {});
    },
    dellist: function dellist(id) {
      var _this7 = this;

      this.$confirm("\u786E\u8BA4\u5220\u9664\u5F53\u524D\u83DC\u5355", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        menuDelete("".concat(id.menuId)).then(function (res) {
          _this7.$message.success('删除成功');

          _this7.getList();

          _this7.getButtondio();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88\u5220\u9664"
        });
      });
    }
  }
};