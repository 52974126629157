//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { coincelebritycontentSave } from '@/api/celebrity/celebrity'
import { currencyQueryPage } from '@/api/currency/currency';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        abbreviation: '' // keywords: ''

      },
      setting: {
        // 弹窗
        title: '选择名人',
        visible: false
      },
      coincelebrityRadio: '',
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      coincelebrityData: [] // 选中数据

    };
  },
  computed: {
    topicCbox: function topicCbox() {
      if (this.listData) {
        return this.listData.list.filter(function (i) {
          return i.checkbox;
        });
      }

      return [];
    }
  },
  mounted: function mounted() {},
  methods: {
    // table 选中回调
    handleRowClick: function handleRowClick(row, column, event) {
      this.coincelebrityData = row;
      this.coincelebrityRadio = row.id; // console.log(row, column, event, '参数')
    },
    handerTopicBox: function handerTopicBox(val, index) {
      this.listData.list[index].checkbox = !this.listData.list[index].checkbox;
      this.$set(this.listData.list, index, this.listData.list[index]); // 新增

      if (this.listData.list[index].checkbox) {
        // 合并
        this.coincelebrityData.push(val); // 去重

        var nArr = this.coincelebrityData.filter(function (currentValue, currentIndex, selfArr) {
          return selfArr.findIndex(function (x) {
            return x.id === currentValue.id;
          }) === currentIndex;
        });
        this.coincelebrityData = nArr;
      } else {
        // 删除
        var idx = this.coincelebrityData.findIndex(function (item) {
          return item.id === val.id;
        });

        if (idx !== -1) {
          this.coincelebrityData.splice(idx, 1);
        }
      } // 更新dom
      // this.$forceUpdate()

    },
    settingEvent: function settingEvent() {
      this.$emit('handleList', this.coincelebrityData);
      this.setting.visible = false;
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false;
      this.coincelebrityRadio = ''; // 调用父组件 刷新页面
    },
    lookRow: function lookRow() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    // 列表
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      currencyQueryPage(this.tableFrom).then(function (res) {
        _this.listData.list = res.list;
        _this.listData.total = res.totalCount;
        _this.listLoading = false;

        _this.listData.list.forEach(function (v) {
          v.checkbox = false;
        });

        if (!_this.coincelebrityData) return false;
        var indexArray = [];

        _this.coincelebrityData.forEach(function (v) {
          var idx = _this.listData.list.findIndex(function (item) {
            return item.id === v.id;
          });

          if (idx !== -1) {
            indexArray.push(idx);
          }
        });

        if (indexArray.length) {
          indexArray.forEach(function (v) {
            _this.listData.list[v].checkbox = true;
          });
        }
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.coincelebrityRadio = '';
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};