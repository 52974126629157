var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入标题名称" },
                        model: {
                          value: _vm.formModal.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "title", $$v)
                          },
                          expression: "formModal.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容", prop: "content" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.formModal.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "content", $$v)
                          },
                          expression: "formModal.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "语言" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              return _vm.checklanguage()
                            },
                          },
                          model: {
                            value: _vm.formModal.language,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "language", $$v)
                            },
                            expression: "formModal.language",
                          },
                        },
                        _vm._l(_vm.languageList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "categoryId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { multiple: "", placeholder: "请选择" },
                          on: { change: _vm.checkformModal },
                          model: {
                            value: _vm.formModal.categoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "categoryId", $$v)
                            },
                            expression: "formModal.categoryId",
                          },
                        },
                        _vm._l(_vm.articleType, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "币种" } },
                    [
                      _vm.formModal.currencyId
                        ? _c(
                            "el-tag",
                            {
                              key: _vm.formModal.currencyId,
                              staticStyle: { "margin-right": "10px" },
                              attrs: {
                                closable: "",
                                "disable-transitions": false,
                              },
                              on: {
                                close: function ($event) {
                                  return _vm.handleTagClose(_vm.tag)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.formModal.currencyName) + " "
                              ),
                            ]
                          )
                        : _c(
                            "el-button",
                            {
                              staticClass: "button-new-tag",
                              attrs: { size: "small" },
                              on: { click: _vm.showCurrency },
                            },
                            [_vm._v("添加币种 +")]
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "事件日期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "yyyy-MM-dd",
                          "value-format": "timestamp",
                          type: "date",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.formModal.eventTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "eventTime", $$v)
                          },
                          expression: "formModal.eventTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("addCurrency", {
        ref: "addCurrency",
        on: { handleList: _vm.handleList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }