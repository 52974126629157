//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberLoginLogStat } from '@/api/memberLoginLog';
export default {
  components: {},
  data: function data() {
    return {
      listData: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {// this.$emit('handleSetLineChartData', type)
    },
    // 列表
    getList: function getList() {
      var _this = this;

      memberLoginLogStat().then(function (res) {
        _this.listData = res;
      }).catch(function () {});
    }
  }
};