import request from '@/utils/request'; // 创作者 认证表删除

export function memberCreatorDeleteByIds(data) {
  return request({
    url: '/memberCreator/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 创作者 认证表审核

export function memberCreatorExamine(data) {
  return request({
    url: '/memberCreator/auth/examine',
    method: 'post',
    data: data
  });
} // 创作者 认证表主键查询

export function memberCreatorGetById(data) {
  return request({
    url: '/memberCreator/auth/getById',
    method: 'post',
    data: data
  });
} // 创作者 认证表列表查询

export function memberCreatorQueryList(data) {
  return request({
    url: '/memberCreator/auth/queryList',
    method: 'post',
    data: data
  });
} // 创作者 认证表分页列表

export function memberCreatorQueryPage(data) {
  return request({
    url: '/memberCreator/auth/queryPage',
    method: 'post',
    data: data
  });
} // 创作者 认证表保存

export function memberCreatorQuerySave(data) {
  return request({
    url: '/memberCreator/auth/save',
    method: 'post',
    data: data
  });
} // 创作者 认证表修改

export function memberCreatorQueryUpdateById(data) {
  return request({
    url: '/memberCreator/auth/updateById',
    method: 'post',
    data: data
  });
}