import request from '@/utils/request'; // 内容文章版块 === 讯息 快讯管理轮播图版块
// 快讯批量修改

export function newflashBatchUpdateById(data) {
  return request({
    url: '/cms/content/newflash/auth/newsflash/batchUpdateById',
    method: 'post',
    data: data
  });
} // 快讯删除

export function newflashDeleteByIds(data) {
  return request({
    url: '/cms/content/newflash/auth/newsflash/deleteByIds',
    method: 'post',
    data: data
  });
} // 快讯主键查询

export function newflashGetById(data) {
  return request({
    url: '/cms/content/newflash/auth/newsflash/getById',
    method: 'get',
    data: data
  });
} // 快讯列表查询

export function newflashQueryList(data) {
  return request({
    url: '/cms/content/newflash/auth/newsflash/queryList',
    method: 'post',
    data: data
  });
} // 快讯分页列表

export function newflashQueryPage(data) {
  return request({
    url: '/cms/content/newflash/auth/newsflash/queryPage',
    method: 'post',
    data: data
  });
} // 快讯保存

export function newflashQuerySave(data) {
  return request({
    url: '/cms/content/newflash/auth/newsflash/save',
    method: 'post',
    data: data
  });
} // 快讯修改

export function newflashQueryUpdateById(data) {
  return request({
    url: '/cms/content/newflash/auth/newsflash/updateById',
    method: 'post',
    data: data
  });
} //行业日历

export function eventQueryPage(data) {
  return request({
    url: '/event/auth/queryPage',
    method: 'post',
    data: data
  });
} //修改

export function eventUpdateById(data) {
  return request({
    url: '/event/auth/updateById',
    method: 'post',
    data: data
  });
} //修改

export function eventSave(data) {
  return request({
    url: '/event/auth/save',
    method: 'post',
    data: data
  });
} //修改

export function eventDel(data) {
  return request({
    url: '/event/auth/deleteByIds',
    method: 'post',
    data: data
  });
}