//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { userList, userDelete, userUpdate } from '@/api/user';
import addDynamic from './components/addDynamic'; // import { spiderSourceQueryList } from '@/api/crawler/crawler'

import { parseTime } from '@/utils';
import { registerType } from '@/utils/enum';
export default {
  name: 'Management',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      value: '',
      input: '',
      comeFrom: [],
      registerType: registerType,
      tableFrom: {
        page: 1,
        limit: 20,
        mobile: '',
        regType: '',
        virtualFlag: '',
        whiteEnable: '',
        sourceId: '',
        email: '',
        username: '' // keywords: ''

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: ''
    };
  },
  mounted: function mounted() {
    this.spiderSourceList();
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    handleOpen: function handleOpen(row) {
      var _this = this;

      this.$confirm("\u786E\u8BA4".concat(row.isShow ? '关闭' : '开启', "\u5F53\u524D\u5FEB\u8BAF"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.setSave(row);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.isShow ? '开启' : '关闭')
        });
      });
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.username = '';
      this.tableFrom.mobile = '';
      this.tableFrom.regType = '';
      this.tableFrom.virtualFlag = '';
      this.tableFrom.whiteEnable = '';
      this.tableFrom.sourceId = '';
      this.tableFrom.email = '';
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      this.tableSelection = row;
      row.map(function (item) {
        arr.push(item.userId);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.userId) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑
        // this.$refs.addDynamic.formModal = par

        this.$refs.addDynamic.setting.title = '编辑管理员';
        this.$refs.addDynamic.spiderSourceList(par.userId);
      } else {
        // 重置弹窗
        this.$refs.addDynamic.formModal = {
          userId: '',
          username: '',
          // 昵称
          password: '',
          // 密码
          mobile: '',
          // 手机号
          email: '',
          // 邮箱
          status: '1',
          // 状态
          roleIdList: [] // 个性签名

        };
        this.$refs.addDynamic.setting.title = '添加管理员';
      }

      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this2 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        userIds: this.multipleSelection
      };
      console.log(par);
      userDelete(par.userIds).then(function (res) {
        _this2.$message.success('删除成功');

        _this2.getList();
      });
    },
    setSave: function setSave(row) {
      var _this3 = this;

      row.isShow = !row.isShow;
      userUpdate(row).then(function (res) {
        _this3.$message.success('修改成功');
      }).catch(function (res) {
        row.isShow = !row.isShow;

        _this3.$message.error('修改失败');
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      userList(this.tableFrom).then(function (res) {
        console.log(res);
        _this4.listData.list = res.page.list;
        _this4.listData.total = res.page.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    spiderSourceList: function spiderSourceList() {// spiderSourceQueryList({ salfFalg: 1 })
      //   .then((res) => {
      //     this.comeFrom = res
      //   })
      //   .catch(() => {})
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    updswitch: function updswitch(e) {
      var _this5 = this;

      if (e.status == 1) {
        e.status = 0;
      } else {
        e.status = 1;
      }

      userUpdate(e).then(function (res) {
        _this5.$message.success('修改成功'); // 重置

      }).catch(function (res) {
        _this5.$message.error('修改失败');
      });
    }
  }
};