var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "30%",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Freeze" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "选择日期时间",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                },
                model: {
                  value: _vm.freezeVal,
                  callback: function ($$v) {
                    _vm.freezeVal = $$v
                  },
                  expression: "freezeVal",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.freezeLoading, type: "primary" },
                  on: { click: _vm.settingEvent },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }