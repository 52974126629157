var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _vm.fileList.length <= 0
        ? _c(
            "el-upload",
            {
              ref: "uploadMutiple",
              attrs: {
                action: "",
                "show-file-list": false,
                multiple: false,
                "before-upload": _vm.beforeUpload,
                "on-exceed": _vm.handleExceed,
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    slot: "trigger",
                    size: "small",
                    type: "primary",
                    disabled: _vm.btnDisabled,
                  },
                  slot: "trigger",
                },
                [_vm._v(_vm._s(_vm.btnText))]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.fileList.length > 0
        ? _c("div", { staticClass: "video" }, [
            _vm.fileList[0].status === "success"
              ? _c("video", {
                  staticClass: "upVideo",
                  attrs: { src: _vm.fileURL },
                })
              : _vm._e(),
            _vm.fileList[0].status === "success"
              ? _c(
                  "div",
                  {
                    staticClass: "close",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleRemove(_vm.fileList[0])
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-close" })]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.fileList.length > 0
        ? _c(
            "div",
            [
              _vm.fileList[0].status === "success"
                ? [_vm._v("上传成功！")]
                : _vm.fileList[0].status === "error"
                ? [_vm._v("上传失败!")]
                : _c("el-progress", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.progressFlag,
                        expression: "progressFlag",
                      },
                    ],
                    attrs: { percentage: _vm.fileList[0].progress },
                  }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }