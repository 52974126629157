//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { coincelebritySave } from '@/api/celebrity/celebrity';
export default {
  name: 'AddFamous',
  data: function data() {
    return {
      formModal: {
        id: '',
        name: '',
        // 姓名
        selfIntroduction: [],
        // 标签
        profilePhoto: '',
        // 头像
        introduction: '',
        // 简介
        likeNum: '',
        // 总获赞量
        discussNum: '',
        // 总评论量
        forwardNum: '',
        // 总被分享量
        focusNum: '',
        // 关注Ta总人数
        sort: '',
        // 名人墙排序
        isGood: true // 状态

      },
      rules: {
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        selfIntroduction: [{
          required: true,
          message: '请输入标签',
          trigger: 'blur'
        }],
        profilePhoto: [{
          required: true,
          message: '请上传头像',
          trigger: 'blur'
        }],
        introduction: [{
          required: true,
          message: '请输入简介',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      this.formModal.selfIntroduction.splice(this.formModal.selfIntroduction.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this = this;

      if (this.formModal.selfIntroduction.length > 2) {
        this.$message.error('标签最多可以添加3个');
        return;
      }

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this2 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this2.$message.success('上传成功');

        _this2.formModal.profilePhoto = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this3 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          console.log('进来');
          _this3.formModal.selfIntroduction = "".concat(_this3.formModal.selfIntroduction);
          coincelebritySave(_this3.formModal).then(function (res) {
            _this3.$message.success('添加成功'); // 重置


            _this3.$refs.formModal.resetFields();

            _this3.handleClose();
          }).catch(function (res) {
            _this3.$message.error('添加失败');
          });
        }
      });
    }
  }
};