//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PanelGroup from './components/PanelGroup';
import LineChart from './components/LineChart';
import { handleExport } from '@/utils';
import BarTable from './components/BarTable';
import { memberLoginLogQueryPage, memberLoginLogCharts, memberLoginLogExport } from '@/api/memberLoginLog'; // import BarChart from './components/BarChart'
// import TransactionTable from './components/TransactionTable'
// import TodoList from './components/TodoList'
// import BoxCard from './components/BoxCard'

var lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
};
export default {
  name: 'DashboardAdmin',
  components: {
    PanelGroup: PanelGroup,
    LineChart: LineChart,
    BarTable: BarTable // PieChart,
    // BarChart,
    // TransactionTable,
    // TodoList,
    // BoxCard

  },
  data: function data() {
    return {
      lineChartData: lineChartData.newVisitis,
      chartDate: '天',
      listData: [],
      listLoading: false,
      days: 1,
      chartDays: {
        '天': 1,
        '周': 7,
        '月': 30
      },
      value: '',
      tableFrom: {
        page: 1,
        limit: 20,
        visitorFlag: 1,
        // 是否游客
        days: 1,
        // 是否游客
        newFlag: 1,
        // 是否新增
        activeFlag: null,
        // 是否活跃
        backFlag: null,
        // 是否回流用户
        reticentFlag: null,
        // 是否沉默用户
        loginType: null,
        // 是否有启动次数 默认没有
        endDate: '',
        startDate: '' // keywords: 0

      }
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getCharts();
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    },
    handeRadio: function handeRadio(val) {
      this.tableFrom.days = this.chartDays[val];
      this.getCharts();
      this.getList();
    },
    downExport: function downExport() {
      var _this = this;

      this.listLoading = true;
      memberLoginLogExport(this.tableFrom).then(function (res) {
        handleExport(res);
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    handleScreen: function handleScreen(type) {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.visitorFlag = null;
      this.tableFrom.newFlag = null;
      this.tableFrom.activeFlag = null;
      this.tableFrom.backFlag = null;
      this.tableFrom.reticentFlag = null;
      this.tableFrom.loginType = null;

      switch (type) {
        case 'visitorFlag':
          // 新增游客
          this.tableFrom.visitorFlag = 1;
          this.tableFrom.newFlag = 1;
          break;

        case 'newFlag':
          // 新增用户
          this.tableFrom.visitorFlag = 0;
          this.tableFrom.newFlag = 1;
          break;

        case 'activeFlag':
          // 活跃用户
          this.tableFrom.visitorFlag = 0;
          this.tableFrom.activeFlag = 1;
          break;

        case 'backFlag':
          // 回流用户
          this.tableFrom.visitorFlag = 0;
          this.tableFrom.backFlag = 1;
          break;

        case 'reticentFlag':
          // 沉默
          this.tableFrom.visitorFlag = 0;
          this.tableFrom.reticentFlag = 1;
          break;

        case 'loginType':
          // 启动次数
          this.tableFrom.loginType = 0;
          break;

        default:
          break;
      }

      this.getList();
      this.getCharts();
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      memberLoginLogQueryPage(this.tableFrom).then(function (res) {
        _this2.listData.list = res.list;
        _this2.listData.total = res.totalCount;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    getCharts: function getCharts() {
      var _this3 = this;

      memberLoginLogCharts(this.tableFrom).then(function (res) {
        _this3.lineChartData['expectedData'] = res.android;
        _this3.lineChartData['actualData'] = res.ios;
        _this3.lineChartData['xdate'] = res.dateTime;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    selectDate: function selectDate(e) {
      console.log();

      if (e != null) {
        this.tableFrom.startDate = e[0];
        this.tableFrom.endDate = e[1];
        this.getCharts();
        this.getList();
      } else {
        this.tableFrom.startDate = "";
        this.tableFrom.endDate = "";
        this.getCharts();
        this.getList();
      }
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};