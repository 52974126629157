var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "famous" }, [
    _c("div", { staticClass: "famous_header" }, [
      _c("div", { staticClass: "famous_lt" }, [
        _c(
          "div",
          { staticClass: "famous_item", staticStyle: { display: "none" } },
          [
            _c("div", { staticClass: "text" }, [_vm._v("版本：")]),
            _c("el-input", {
              attrs: { placeholder: "请输入相关昵称" },
              model: {
                value: _vm.tableFrom.version,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "version", $$v)
                },
                expression: "tableFrom.version",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "famous_rt" },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerState } },
            [_vm._v("筛选")]
          ),
        ],
        1
      ),
    ]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "famous_content" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.listData.list,
              size: "mini",
              "max-height": "500",
              "highlight-current-row": "",
              "tooltip-effect": "dark",
              "header-cell-style": { "text-align": "center" },
              "cell-style": { "text-align": "center" },
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _c("el-table-column", {
              attrs: { prop: "version", label: "版本号" },
            }),
            _c("el-table-column", {
              attrs: { prop: "versionCode", label: "版本code码" },
            }),
            _c("el-table-column", {
              attrs: { prop: "appType", label: "app类型" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.appType ? "ios" : "Androied")),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "description", label: "更新说明" },
            }),
            _c("el-table-column", {
              attrs: { prop: "downloadUrl", label: "更新地址" },
            }),
            _c("el-table-column", {
              attrs: { prop: "isForce", label: "强制更新" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-switch", {
                        attrs: { disabled: true, value: scope.row.isForce },
                        on: {
                          change: function ($event) {
                            return _vm.handleCut(
                              scope.row,
                              "isForce",
                              "强制更新"
                            )
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "120", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addCelebrity(scope.row)
                            },
                          },
                        },
                        [_vm._v(" 编辑 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "block mb20" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [5, 10, 20, 30, 40],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.listData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
        _c("addDynamic", { ref: "addDynamic", on: { getList: _vm.getList } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "famous_btn" }, [
      _c("div", { staticClass: "famouts_btn_lt" }),
      _c("div", { staticClass: "famouts_btn_rt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }