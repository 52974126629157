//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { categoryQueryPage, categoryDeleteByIds, categoryQueryUpdateById } from '@/api/article/article';
import addDynamic from './components/addDynamic';
import { formatDateTime } from '@/utils';
import { languageType } from '@/utils/enum';
export default {
  name: 'DynamicMen',
  components: {
    addDynamic: addDynamic
  },
  data: function data() {
    return {
      languageType: languageType,
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '显示'
      }, {
        value: 0,
        label: '隐藏'
      }],
      selfflagList: [{
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      typeList: [{
        value: 1,
        label: '文章'
      }, {
        value: 2,
        label: '快讯'
      }, {
        value: 3,
        label: '币种'
      }, {
        value: 4,
        label: '社区动态'
      }, {
        value: 5,
        label: '行业日历'
      }],
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        selfFlag: '',
        language: '',
        name: '',
        // 标题
        status: '' // 是否显示

      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: [] // 选中row

    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    formatDateTime: formatDateTime,
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    handleUpdate: function handleUpdate(status) {
      var _this = this;

      if (this.listLoading) {
        return;
      }

      this.listLoading = true;

      if (this.tableSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      this.tableSelection.forEach(function (val) {
        val.status = status;
      });
      categoryQueryUpdateById(this.tableSelection).then(function (res) {
        _this.$refs.multipleTable.clearSelection();

        _this.$message.success('修改成功');

        _this.listLoading = false;
        _this.tableSelection = [];
      }).catch(function (res) {
        _this.$refs.multipleTable.clearSelection();

        _this.$message.error('修改失败');

        _this.listLoading = false;
      });
    },
    handleOpen: function handleOpen(row) {
      var _this2 = this;

      this.$confirm("\u786E\u8BA4".concat(row.isShow ? '关闭' : '开启', "\u5F53\u524D\u8D44\u8BAF\u5206\u7C7B"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.setSave(row);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row.isShow ? '关闭' : '开启')
        });
      });
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.name = '';
      this.tableFrom.status = '';
      this.tableFrom.selfFlag = '';
      this.tableFrom.language = '';
      this.tableFrom.type = '';
      this.getList();
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      this.tableSelection = row;
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(row) {
      if (row.id) {
        var par = JSON.parse(JSON.stringify(row)); // 编辑

        this.$refs.addDynamic.formModal = par;
        this.$refs.addDynamic.setting.title = '编辑分类';
      } else {
        // 重置弹窗
        this.$refs.addDynamic.formModal = {
          id: '',
          name: '',
          // 分类名称
          sort: '',
          // 来自
          language: '',
          keywords: '',
          // 关键词
          status: 1 // 状态

        };
        this.$refs.addDynamic.setting.title = '添加分类';
      } // 重置弹窗
      // this.$refs.addDynamic.formModal = {
      //   id: '',
      //   name: '', // 分类名称
      //   sort: '', // 来自
      //   dataStatus: 1 // 状态
      // }


      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this3 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      categoryDeleteByIds(par.ids).then(function (res) {
        _this3.$message.success('删除成功');

        _this3.getList();
      });
    },
    setSave: function setSave(row) {
      var _this4 = this;

      if (row.status) {
        row.status = 0;
      } else {
        row.status = 1;
      }

      categoryQueryUpdateById(row).then(function (res) {
        _this4.$message.success('修改成功');
      }).catch(function (res) {
        if (row.status) {
          row.status = 0;
        } else {
          row.status = 1;
        }

        _this4.$message.error('修改失败');
      });
    },
    // 列表
    getList: function getList() {
      var _this5 = this;

      this.listLoading = true;
      categoryQueryPage(this.tableFrom).then(function (res) {
        _this5.listData.list = res.list;
        _this5.listData.total = res.totalCount;
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};