//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberFreeze } from '@/api/member/member';
export default {
  name: 'AddFreeze',
  components: {},
  data: function data() {
    return {
      freezeVal: '',
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      freezeLoading: false,
      freezeId: '',
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {// this.spiderSourceList()
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.freezeLoading = false;
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 添加名人
    settingEvent: function settingEvent() {
      var _this = this;

      var par = {
        freezeTime: this.freezeVal,
        id: this.freezeId
      };
      this.freezeLoading = true;
      memberFreeze(par).then(function (res) {
        console.log(res, 'res');

        _this.$message.success('修改成功'); // 重置


        _this.handleClose();
      }).catch(function (res) {
        _this.freezeLoading = false;

        _this.$message.error('修改失败');
      });
    }
  }
};