import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// import { message } from 'ant-design-vue'
// import { getCos } from '@/utils/business/cos'
// import { fileTypeFolderMap } from '@/enum/file'
// import Api from '@/api'

/**
 * @file 下载
 */
var handleDownloadFile = function handleDownloadFile(content, filename) {
  var a = document.createElement('a');
  var blob = new Blob([content]);
  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

var ajax = function ajax(url, callback, options) {
  window.URL = window.URL || window.webkitURL;
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.responseType = 'blob';
  xhr.open('get', url, true);

  if (options.responseType) {
    xhr.responseType = options.responseType;
  }

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      callback(xhr);
    }
  };

  xhr.onprogress = function (evt) {
    if (evt.lengthComputable) {
      var percent = Math.round(evt.loaded * 100 / evt.total);
      var loadingOptions = {
        lock: true,
        text: '下载视频加载中' + percent + '%',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      };

      var loadingInstance = _Loading.service(loadingOptions); // console.log('percent:', percent)


      if (percent === 100) {
        loadingInstance.close();
      } else {
        loadingInstance.setText('下载视频加载中' + percent + '%');
      }

      if (options.onprogress) {
        options.onprogress({
          url: url,
          percent: percent,
          speed: ''
        });
      }
    } else {
      console.log('loadedPercent:', 'unable to compute');
    }
  };

  xhr.send();
};
/**
 * 下载文件
 * @param {string} url 下载url
 * @param {string} name 文件名
 */


export var downloadFile2 = function downloadFile2(url, name) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  // 获取最后一个.的位置
  var index = url.lastIndexOf('.');
  var ext = url.substring(index + 1);
  ajax(url, function (xhr) {
    handleDownloadFile(xhr.response, name + '.' + ext);
  }, _objectSpread({
    responseType: 'blob'
  }, options));
};
/**
 * 下载文件方法2
 */

export var downloadFile = function downloadFile(url, name) {
  var a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  a.click();
};
/**
 * 下载方法 - 异步
 */

export var asyncDownloadFile = function asyncDownloadFile(url, name) {
  return new Promise(function (resolve, reject) {
    window.URL = window.URL || window.webkitURL;
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.responseType = 'blob';
    xhr.open('get', url, true);

    xhr.onreadystatechange = function () {
      if (xhr.readyState !== 4) return;

      try {
        if (xhr.status === 200) {
          var a = document.createElement('a');
          var blob = new Blob([xhr.response]);
          var sourceUrl = window.URL.createObjectURL(blob);
          a.href = sourceUrl;
          a.download = name;
          a.click();
          window.URL.revokeObjectURL(sourceUrl);
          resolve();
        } else {
          reject(new Error('请求失败'));
        }
      } catch (error) {
        reject(error);
      }
    };

    xhr.send();
  });
}; // export const downloadCloudFile = (fileList) => {
//   const vodFileList = fileList.filter((item) => [0, 4].includes(item.type))
//   const cosFileList = fileList.filter((item) => !([0, 4].includes(item.type)))
//   vodFileList.forEach((item) => {
//     item.url += `&download_name=${item.name}.${item.suffix}`
//     downloadFile(item.url, `${item.name}.${item.suffix}`)
//   })
//   if (cosFileList.length) {
//     getCos((Cos) => {
//       cosFileList.forEach((item) => {
//         Cos.getObjectUrl({
//           Bucket: item.bucket,
//           Region: item.region,
//           Key: `${fileTypeFolderMap[item.type]}/${item.filename}${item.suffix ? '.' : ''}${item.suffix}`,
//           Headers: {},
//           Sign: true,
//           Query: {
//             'response-content-disposition': `attachment; filename* = UTF-8''${item.name}${item.suffix ? '.' : ''}${item.suffix}`,
//             'response-content-type': 'application/octet-stream'
//           }
//         }, (err, data) => {
//           if (err) {
//             const msg = `${item.name}${item.suffix ? '.' : ''}${item.suffix}下载失败，请重新下载`
//             return message.error(msg, 1)
//           }
//           downloadFile(data.Url, `${item.name}${item.suffix ? '.' : ''}${item.suffix}`)
//         })
//       })
//     })
//   }
// }