//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { currencyQueryPage, currencyplateCurrencyqueryList, currencyplateCurrencysave } from '@/api/currency/currency';
import { plateQueryPage, plateDeleteByIds, plateUpdateById } from '@/api/plate/plate';
import { parseTime } from '@/utils';
export default {
  name: 'Plate',
  components: {},
  data: function data() {
    return {
      value: '',
      input: '',
      btctabsid: 0,
      tableFrom: {
        page: 1,
        limit: 20,
        nameZh: '' // keywords: ''

      },
      btcFrom: {
        page: 1,
        limit: 20,
        abbreviation: ''
      },
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      listData2: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      gridData: [],
      dialogTableVisible: false,
      tags: [],
      multipleSelection2: {
        plateId: '',
        currencyIds: []
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    closeBtc: function closeBtc() {
      this.dialogTableVisible = false;
      this.tags = [];
      this.multipleSelection2 = {
        plateId: '',
        currencyIds: []
      };
      this.btcFrom.abbreviation = '';
    },
    addtags: function addtags(row) {
      var _this = this;

      var valueExists = false;
      this.tags.some(function (e) {
        if (e.currencyIds == row.id) {
          valueExists = true;

          _this.$message.warning('列表内已有此币种');
        }
      });

      if (valueExists == false) {
        this.tags.push({
          name: row.nameZh,
          plateId: this.btctabsid,
          currencyIds: row.id
        });
        this.multipleSelection2.plateId = this.btctabsid;
        this.multipleSelection2.currencyIds.push(row.id); // this.multipleSelection2.push({
        //   plateId: this.btctabsid,
        //   currencyIds: row.id
        // })
      }
    },
    saveBtc: function saveBtc() {
      var _this2 = this;

      currencyplateCurrencysave(this.multipleSelection2).then(function (res) {
        _this2.dialogTableVisible = false;
        _this2.tags = [];
        _this2.multipleSelection2 = {
          plateId: '',
          currencyIds: []
        };

        _this2.$message.success('提交成功');
      });
    },
    handleClose: function handleClose(tag) {
      var _this3 = this;

      this.tags.forEach(function (e, index) {
        if (e.currencyIds == tag.currencyIds) {
          _this3.tags.splice(index, 1);
        }
      });
      this.multipleSelection2.currencyIds.forEach(function (e, index) {
        if (e == tag.currencyIds) {
          _this3.multipleSelection2.currencyIds.splice(index, 1);
        }
      });
    },
    updBtc: function updBtc(id) {
      this.dialogTableVisible = true;
      this.btctabsid = id;
      this.getBtclist();
      this.getBtcTabs();
    },
    getBtcTabs: function getBtcTabs() {
      var _this4 = this;

      currencyplateCurrencyqueryList({
        plateId: this.btctabsid
      }).then(function (res) {
        res.forEach(function (e) {
          _this4.tags.push({
            name: e.nameZh,
            plateId: _this4.btctabsid,
            currencyIds: e.id
          });

          _this4.multipleSelection2.plateId = _this4.btctabsid;

          _this4.multipleSelection2.currencyIds.push(e.id);
        });
      });
    },
    getBtclist: function getBtclist() {
      var _this5 = this;

      currencyQueryPage(this.btcFrom).then(function (res) {
        _this5.gridData = res.list;
        _this5.listData2.list = res.list;
        _this5.listData2.total = res.totalCount;
      });
    },
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handleScreen: function handleScreen() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.nameZh = '';
      this.getList();
    },
    handleCut: function handleCut(row, key, value) {
      var _this6 = this;

      this.$confirm("\u786E\u8BA4".concat(row[key] ? '隐藏' : '开启', "\u5F53\u524D").concat(value), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.setSave(row, key);
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: "\u5DF2\u53D6\u6D88".concat(row[key] ? '开启' : '隐藏')
        });
      });
    },
    setSave: function setSave(row, key) {
      var _this7 = this;

      row[key] = row[key] ^ 1;
      plateUpdateById(row).then(function (res) {
        _this7.$message.success('修改成功'); // this.getList()

      }).catch(function (res) {
        row[key] = row[key] ^ 1;

        _this7.$message.error('修改失败');
      });
    },
    handlerState: function handlerState(statue, val) {
      var formDisable = false;
      var formModal = {
        id: '',
        // id
        nameZh: '',
        // 版块名称
        curNum: '',
        // 币种总量
        synopsis: '',
        // 基本信息
        status: 1 // 状态

      };

      if (statue === 3) {
        formDisable = true; // 禁止编辑状态
      }

      if (statue !== 1) {
        formModal = val;
      }

      this.$router.push({
        name: 'AddPlate',
        query: {
          statue: statue,
          // 状态1 新增 2 编辑 3 查看
          formModal: encodeURIComponent(JSON.stringify(formModal)),
          formDisable: formDisable
        }
      });
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity() {
      // 重置弹窗
      this.$refs.addDynamic.formModal = {
        celebrityName: '',
        // 关联名人
        sourceType: '',
        // 来自
        image: '',
        // 头像
        content: '',
        // 内容
        likeNum: '',
        // 总获赞量
        discussNum: '',
        // 总评论量
        forwardNum: '',
        // 总被分享量
        dataStatus: '1' // 状态

      };
      this.$refs.addDynamic.setting.visible = true;
    },
    handlerDelete: function handlerDelete() {
      var _this8 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      plateDeleteByIds(par.ids).then(function (res) {
        _this8.$message.success('删除成功');

        _this8.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this9 = this;

      this.listLoading = true;
      plateQueryPage(this.tableFrom).then(function (res) {
        _this9.listData.list = res.list;
        _this9.listData.total = res.totalCount;
        _this9.listLoading = false;
      }).catch(function () {
        _this9.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange2: function pageChange2(page) {
      this.btcFrom.page = page;
      this.multipleSelection2 = [];
      this.$refs.multipleTable2.clearSelection();
      this.getBtclist();
    },
    handleSizeChange2: function handleSizeChange2(val) {
      this.btcFrom.limit = val;
      this.getBtclist();
    }
  }
};