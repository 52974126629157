//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { exchangeSave, exchangeUpdateById } from '@/api/exchange/exchange';
import { parseTime } from '@/utils';
import ueditorFrom from '@/components/ueditorFrom';
import addLink from './addLink';
export default {
  name: 'AddBourse',
  components: {
    ueditorFrom: ueditorFrom,
    addLink: addLink
  },
  data: function data() {
    return {
      formModal: {
        id: '',
        // id
        nameZh: '',
        // 交易所名称
        pairNum: '',
        // 交易对数量
        volUsd: '',
        // 24H成交额
        logo: '',
        // 交易所图片
        // sortByRank: '', // 序号
        sort: '',
        // 排名
        score: '',
        // 综合评分
        url: '',
        // 网址
        label: [],
        // 标签
        synopsis: '',
        // 基本信息
        launchedTime: '',
        // 成立时间
        country: '',
        // 注册地
        link: '',
        dataStatus: 1 // 状态

      },
      formDisable: false,
      // 禁用状态
      statue: '',
      linkList: [],
      rules: {
        nameZh: [{
          required: true,
          message: '请输入交易所名称',
          trigger: 'blur'
        }],
        pairNum: [{
          required: true,
          message: '请输入交易对数量',
          trigger: 'blur'
        }],
        volUsd: [{
          required: true,
          message: '请输入24H成交额',
          trigger: 'blur'
        }],
        logo: [{
          required: true,
          message: '请上传币种图片',
          trigger: 'blur'
        }],
        // sortByRank: [
        //   { required: true, message: '请输入序号', trigger: 'blur' }
        // ],
        sort: [{
          required: true,
          message: '请输入排名',
          trigger: 'blur'
        }],
        score: [{
          required: true,
          message: '请输入综合评分',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '请输入网址',
          trigger: 'blur'
        }],
        label: [{
          required: true,
          message: '请输入标签',
          trigger: 'blur'
        }],
        synopsis: [{
          required: true,
          message: '请输入基本信息',
          trigger: 'blur'
        }],
        launchedTime: [{
          required: true,
          message: '请输入成立时间',
          trigger: 'blur'
        }],
        country: [{
          required: true,
          message: '请输入注册地',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    if (this.$route.params.formModal) {
      this.formModal = this.$route.params.formModal;
      this.formDisable = this.$route.params.formDisable;
    }

    this.statue = this.$route.params.statue;

    if (this.formModal.label.length && this.statue !== 1) {
      this.$route.meta.alias = '编辑交易所'; // this.formModal.label = this.formModal.label.split(',')
    }
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    handleLink: function handleLink(data) {
      this.linkList.push(data);
      var par = {};
      par["".concat(data.name)] = {
        url: data.url,
        logo: data.image
      };
      this.formModal.link = Object.assign({}, this.formModal.link, par);
    },
    showLink: function showLink() {
      this.$refs.addLink.url = '';
      this.$refs.addLink.name = '';
      this.$refs.addLink.image = '';
      this.$refs.addLink.setting.visible = true;
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      this.formModal.label.splice(this.formModal.label.indexOf(tag), 1);
    },
    showInput: function showInput() {
      var _this = this;

      if (this.formModal.label.length > 2) {
        this.$message.error('标签最多可以添加3个');
        return;
      }

      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.label.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this2 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      console.log(formData, param.file, 'formData');
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this2.$message.success('上传成功');

        _this2.formModal.logo = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this3 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var formModal = JSON.parse(JSON.stringify(_this3.formModal)); // formModal.historyPriceMaxDate = this.formModal.historyPriceMaxDate.getTime()

          formModal.label = "".concat(_this3.formModal.label);

          if (_this3.statue !== 1) {
            // 不是新增
            exchangeUpdateById(formModal).then(function (res) {
              _this3.$message.success('编辑成功'); // 重置
              // this.$refs.formModal.resetFields()
              // this.handleClose()


              _this3.$router.push({
                name: 'Bourse'
              });
            }).catch(function (res) {
              _this3.$message.error('编辑失败');
            });
            _this3.formModal.label = _this3.formModal.label.split(',');
          } else {
            formModal.launchedTime = parseTime(_this3.formModal.launchedTime.getTime(), '{y}-{m}-{d}');
            exchangeSave(formModal).then(function (res) {
              _this3.$message.success('添加成功'); // 重置


              _this3.$refs.formModal.resetFields();

              _this3.$router.push({
                name: 'Bourse'
              }); // this.handleClose()

            }).catch(function (res) {
              _this3.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};