import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/sys/login',
    method: 'post',
    data: data
  });
}
export function getInfo(params) {
  return request({
    url: '/sys/user/info',
    method: 'get',
    params: params
  });
}
export function getMenuNav(params) {
  return request({
    url: "/sys/menu/nav",
    method: 'get',
    params: params
  });
}
export function getInfoDetail(userId) {
  return request({
    url: "/sys/user/info/".concat(userId),
    method: 'get'
  });
}
export function logout(data) {
  return request({
    url: '/sys/logout',
    method: 'post',
    data: data
  });
} // 删除用户

export function userDelete(data) {
  return request({
    url: '/sys/user/delete',
    method: 'post',
    data: data
  });
} // 用户列表

export function userList(data) {
  return request({
    url: '/sys/user/list',
    method: 'post',
    data: data
  });
} // 修改登录用户密码

export function userPassword(data) {
  return request({
    url: '/sys/user/password',
    method: 'post',
    data: data
  });
} // 保存用户

export function userSave(data) {
  return request({
    url: '/sys/user/save',
    method: 'post',
    data: data
  });
} // 修改用户

export function userUpdate(data) {
  return request({
    url: '/sys/user/update',
    method: 'post',
    data: data
  });
}