var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-ueditor-wrap", {
        ref: "ueditor",
        staticStyle: { width: "100%" },
        attrs: { config: _vm.myConfig },
        on: { ready: _vm.ready, beforeInit: _vm.addCustomDialog },
        model: {
          value: _vm.contents,
          callback: function ($$v) {
            _vm.contents = $$v
          },
          expression: "contents",
        },
      }),
      _c("input", {
        ref: "files",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "image/*", multiple: "multiple" },
        on: {
          change: function ($event) {
            return _vm.handleUploadForm($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }