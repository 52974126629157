import request from '@/utils/request'; // 广告位置
// 删除

export function advPositionDeleteByIds(data) {
  return request({
    url: '/cms/content/auth/advPosition/deleteByIds',
    method: 'post',
    data: data
  });
} // 广告位置主键查询

export function advPositionGetById(data) {
  return request({
    url: '/cms/content/auth/advPosition/getById',
    method: 'post',
    data: data
  });
} // 广告位置列表查询

export function advPositionQueryList(data) {
  return request({
    url: '/cms/content/auth/advPosition/queryList',
    method: 'post',
    data: data
  });
} // 广告位置分页列表

export function advPositionQueryPage(data) {
  return request({
    url: '/cms/content/auth/advPosition/queryPage',
    method: 'post',
    data: data
  });
} // 广告位置保存

export function advPositionQuerySave(data) {
  return request({
    url: '/cms/content/auth/advPosition/save',
    method: 'post',
    data: data
  });
} // 广告位置修改

export function advPositionUpdateById(data) {
  return request({
    url: '/cms/content/auth/advPosition/updateById',
    method: 'post',
    data: data
  });
}