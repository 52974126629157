function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import _variables from '@/styles/variables.scss';
import Layout from '@/layout';
import { menuNav } from '@/api/menu';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routes', 'sidebar'])), {}, {
    activeMenu: function activeMenu() {
      // console.log(this.permission_routes)
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  created: function created() {
    var _this = this;

    setTimeout(function () {
      menuNav().then(function (res) {
        _this.permission_routes.length = 0;

        var extractedArray = _this.doObj(res.menuList);

        extractedArray.forEach(function (e) {
          _this.permission_routes.push(e);
        }); // this.$set(this, 'permission_routes', extractedArray)
        // this.doObj(res.menuList)
        // console.log(this.permission_routes)
        // res.data[0].list.forEach(e => {
        //   this.permission_routes.push({
        //     path: e.routeName,
        //     name: e.routeName,
        //     component: e.url,
        //     meta: {
        //       title: e.name
        //     }
        //   })
        // })
      });
    }, 800); // this.permission_routes.push('123')
  },
  data: function data() {
    return {
      datalist: []
    };
  },
  mounted: function mounted() {},
  methods: {
    doObj: function doObj(datas) {
      var outputArray = []; // datas.forEach((e, index) => {
      // this.permission_routes.push({
      //   path: e.routeName,
      //   name: e.routeName,
      //   component: Layout,
      //   children: [],
      //   meta: {
      //     title: e.name,
      //     icon: e.icon
      //   }
      // })
      // if (e.list != undefined && e.type == 0) {
      //   this.addarr(index, e.list)
      //   // e.list.forEach((a, aindex) => {
      //   //   this.permission_routes[index].children.push({
      //   //     path: a.routeName,
      //   //     name: a.routeName,
      //   //     component: a.url,
      //   //     children: [],
      //   //     meta: {
      //   //       title: a.name,
      //   //       icon: a.icon
      //   //     }
      //   //   })
      //   // })
      // }
      // })

      this.addarr(datas, outputArray); // console.log(this.permission_routes)
      // 循环每次的对象
      // for (let key in datas) {
      //   if (typeof datas.list != undefined) {
      //     this.doObj(datas.list)
      //   }
      //   datalist.push({
      //     path: datas.routeName,
      //     name: datas.routeName,
      //     component: datas.url,
      //     meta: {
      //       title: datas.name
      //     }
      //   })
      //   // 跳出循环，避免重复push
      //   break
      // }

      return outputArray;
    },
    addarr: function addarr(input, output) {
      for (var i = 0; i < input.length; i++) {
        if (input[i].list != undefined) {
          if (input[i].type == 1 || input[i].type == 0) {
            var subOutput = {
              path: input[i].routeName,
              name: input[i].routeName,
              component: input[i].url,
              meta: {
                title: input[i].name,
                icon: input[i].icon
              },
              children: []
            }; // output.push({
            //   path: input[i].routeName,
            //   name: input[i].routeName,
            //   component: input[i].url,
            //   meta: {
            //     title: input[i].name,
            //     icon: input[i].icon
            //   }
            // })

            this.addarr(input[i].list, subOutput.children);
            output.push(subOutput);
          } else {// console.log(input[i])
          }
        } // if (Array.isArray(input[i])) {
        //   output.push([]);
        //   this.addarr(input[i], output[i]);
        // } else {
        //   output.push(input[i]);
        // }

      }
    }
  }
};