import request from '@/utils/request'; // 爬虫来源版块
// 爬虫来源删除

export function spiderSourceDeleteByIds(data) {
  return request({
    url: '/spiderSource/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 爬虫来源主键查询

export function spiderSourceGetById(data) {
  return request({
    url: '/spiderSource/auth/getById',
    method: 'post',
    id: data
  });
} // 爬虫来源列表查询

export function spiderSourceQueryList(data) {
  return request({
    url: '/spiderSource/auth/queryList',
    method: 'post',
    data: data
  });
} // 爬虫来源分页列表

export function spiderSourceQueryPage(data) {
  return request({
    url: '/spiderSource/auth/queryPage',
    method: 'post',
    data: data
  });
} // 爬虫来源保存

export function spiderSourceSave(data) {
  return request({
    url: '/spiderSource/auth/save',
    method: 'post',
    data: data
  });
} // 爬虫-动态修改

export function spiderSourceUpdateById(data) {
  return request({
    url: '/spiderSource/auth/updateById',
    method: 'post',
    data: data
  });
}