var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "famous" }, [
    _c("div", { staticClass: "famous_header" }, [
      _c("div", { staticClass: "famous_lt" }, [
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("昵称：")]),
            _c("el-input", {
              attrs: { placeholder: "请输入相关昵称" },
              model: {
                value: _vm.tableFrom.nickname,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "nickname", $$v)
                },
                expression: "tableFrom.nickname",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("手机号：")]),
            _c("el-input", {
              attrs: { placeholder: "请输入相关手机号" },
              model: {
                value: _vm.tableFrom.mobile,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "mobile", $$v)
                },
                expression: "tableFrom.mobile",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("邮箱：")]),
            _c("el-input", {
              attrs: { placeholder: "请输入相关邮箱" },
              model: {
                value: _vm.tableFrom.email,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "email", $$v)
                },
                expression: "tableFrom.email",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("注册类型：")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择注册类型" },
                model: {
                  value: _vm.tableFrom.regType,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "regType", $$v)
                  },
                  expression: "tableFrom.regType",
                },
              },
              _vm._l(_vm.registerType, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("虚拟帐号:")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择虚拟账号" },
                model: {
                  value: _vm.tableFrom.virtualFlag,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "virtualFlag", $$v)
                  },
                  expression: "tableFrom.virtualFlag",
                },
              },
              _vm._l(_vm.virtualFlagType, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("白名单:")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择白名单" },
                model: {
                  value: _vm.tableFrom.whiteEnable,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "whiteEnable", $$v)
                  },
                  expression: "tableFrom.whiteEnable",
                },
              },
              _vm._l(_vm.whiteEnableType, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("来源:")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.tableFrom.sourceId,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "sourceId", $$v)
                  },
                  expression: "tableFrom.sourceId",
                },
              },
              _vm._l(_vm.comeFrom, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("外部Id：")]),
            _c("el-input", {
              attrs: { placeholder: "请输入外部Id" },
              model: {
                value: _vm.tableFrom.outId,
                callback: function ($$v) {
                  _vm.$set(_vm.tableFrom, "outId", $$v)
                },
                expression: "tableFrom.outId",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("是否有效：")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.tableFrom.dataStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "dataStatus", $$v)
                  },
                  expression: "tableFrom.dataStatus",
                },
              },
              _vm._l(_vm.dataStatusList, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "famous_item" },
          [
            _c("div", { staticClass: "text" }, [_vm._v("是否创作者：")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.tableFrom.creatorFlag,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableFrom, "creatorFlag", $$v)
                  },
                  expression: "tableFrom.creatorFlag",
                },
              },
              _vm._l(_vm.creatorFlagList, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "famous_rt" },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handleReset } },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlerState } },
            [_vm._v("筛选")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "famous_btn" }, [
      _c(
        "div",
        { staticClass: "famouts_btn_lt" },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.handlerDelete } },
            [_vm._v("删除")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.restore } },
            [_vm._v("恢复")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "famouts_btn_rt" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.downExport } },
            [_vm._v("批量导出")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.addCelebrity } },
            [_vm._v("添加用户")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "famous_content" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.listData.list,
              size: "mini",
              "max-height": "500",
              "highlight-current-row": "",
              "tooltip-effect": "dark",
              "header-cell-style": { "text-align": "center" },
              "cell-style": { "text-align": "center" },
            },
            on: { "selection-change": _vm.handleSelectionChange },
          },
          [
            _c("el-table-column", {
              attrs: { type: "selection", width: "55" },
            }),
            _c("el-table-column", {
              attrs: { prop: "id", label: "ID", width: "100" },
            }),
            _c("el-table-column", {
              attrs: { prop: "nickname", label: "昵称", width: "100" },
            }),
            _c("el-table-column", {
              attrs: { prop: "header", label: "头像", "min-width": "178" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("el-image", {
                        staticClass: "photo",
                        attrs: {
                          src: scope.row.header,
                          "preview-src-list": [scope.row.header],
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "mobile", label: "手机号", width: "100" },
            }),
            _c("el-table-column", { attrs: { prop: "email", label: "邮箱" } }),
            _c("el-table-column", {
              attrs: { label: "来源", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.sourceId && _vm.comeFrom.length > 0
                        ? _c("div", [
                            _vm.comeFrom[scope.row.sourceId - 1] != undefined
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.comeFrom[scope.row.sourceId - 1].name
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "是否创作者", width: "100" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.creatorFlag == 0
                        ? _c("div", [_vm._v("否")])
                        : _vm._e(),
                      scope.row.creatorFlag == 1
                        ? _c("div", [_vm._v("是")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "createTime", label: "注册时间" },
            }),
            _c("el-table-column", {
              attrs: { prop: "lastTime", label: "最后一次登录时间" },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "240", fixed: "right" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      !scope.row.freezeTime
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleFreeze(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 冻结 ")]
                          )
                        : _vm._e(),
                      scope.row.freezeTime
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.cancelFreeze(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 取消冻结 ")]
                          )
                        : _vm._e(),
                      scope.row.freezeTime
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleFreeze(scope.row)
                                },
                              },
                            },
                            [_vm._v(" 修改冻结 ")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addCelebrity(scope.row)
                            },
                          },
                        },
                        [_vm._v(" 编辑 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handlePrize(scope.row)
                            },
                          },
                        },
                        [_vm._v(" 生成抽奖 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.showIntegralDia(scope.row.id)
                            },
                          },
                        },
                        [_vm._v(" 发放积分 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getformid(scope.row.id)
                            },
                          },
                        },
                        [_vm._v(" 用户积分列表 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getfans(scope.row.id)
                            },
                          },
                        },
                        [_vm._v(" 粉丝列表 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.unlookcode(scope.row.id, 1)
                            },
                          },
                        },
                        [_vm._v(" 解锁验证码 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.unlookcode(scope.row.id, 2)
                            },
                          },
                        },
                        [_vm._v(" 解锁风控 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.getlabes(scope.row.id)
                            },
                          },
                        },
                        [_vm._v(" 用户标签列表 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "block mb20" },
          [
            _c("el-pagination", {
              attrs: {
                "page-sizes": [5, 10, 20, 30, 40],
                "page-size": _vm.tableFrom.limit,
                "current-page": _vm.tableFrom.page,
                layout: "total, sizes, prev, pager, next, jumper",
                total: _vm.listData.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.pageChange,
              },
            }),
          ],
          1
        ),
        _c("addFreeze", { ref: "addFreeze", on: { getList: _vm.getList } }),
        _c("addDynamic", { ref: "addDynamic", on: { getList: _vm.getList } }),
        _c("userFans", { ref: "userFans" }),
        _c("userLabes", { ref: "userLabes" }),
        _c(
          "el-dialog",
          {
            attrs: { title: "发放积分", visible: _vm.dialogTableVisible },
            on: {
              "update:visible": function ($event) {
                _vm.dialogTableVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              { attrs: { model: _vm.form } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "积分数量" } },
                  [
                    _c("el-input-number", {
                      attrs: { min: 1, label: "请输入积分" },
                      model: {
                        value: _vm.form.amount,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "amount", $$v)
                        },
                        expression: "form.amount",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "备注" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 2,
                        placeholder: "请输入备注",
                      },
                      model: {
                        value: _vm.form.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "remark", $$v)
                        },
                        expression: "form.remark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogTableVisible = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.addIntegralDia },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "积分列表",
              visible: _vm.dialogIntegral,
              top: "10vh",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogIntegral = $event
              },
            },
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.showIntegralDia(_vm.Integraldata.memberId, 1)
                  },
                },
              },
              [_vm._v("发放积分")]
            ),
            _c(
              "el-form",
              { attrs: { model: _vm.Integraldata } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "备注查询" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入remark" },
                      model: {
                        value: _vm.Integraldata.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.Integraldata, "remark", $$v)
                        },
                        expression: "Integraldata.remark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.getintegralFollow()
                  },
                },
              },
              [_vm._v("查询备注")]
            ),
            _c(
              "el-table",
              { attrs: { data: _vm.gridData, "max-height": "350" } },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: {
                    property: "amount",
                    label: "积分数量",
                    width: "150",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "交易类型", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.transationType == 1
                            ? _c("div", [_vm._v("入")])
                            : _vm._e(),
                          scope.row.transationType == 2
                            ? _c("div", [_vm._v("出")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { property: "remark", label: "备注", width: "200" },
                }),
                _c("el-table-column", {
                  attrs: { property: "orderDate", label: "时间" },
                }),
                _c("el-table-column", {
                  attrs: { property: "balance", label: "余额" },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "block mb20" },
              [
                _c("el-pagination", {
                  attrs: {
                    "page-sizes": [5, 10, 20, 30, 40],
                    "page-size": _vm.Integraldata.limit,
                    "current-page": _vm.Integraldata.page,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.Integraldata.total,
                  },
                  on: {
                    "size-change": _vm.IntegralSizeChange,
                    "current-change": _vm.IntegralpageChange,
                  },
                }),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.dialogIntegral = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }