//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { newflashQuerySave, newflashQueryUpdateById } from '@/api/newsFlash/newsFlash';
import ueditorFrom from '@/components/ueditorFrom';
import { languageType } from '@/utils/enum';
import { categoryQueryList } from '@/api/article/article';
export default {
  name: 'AddFamous',
  components: {
    ueditorFrom: ueditorFrom
  },
  data: function data() {
    return {
      languageType: languageType,
      formModal: {
        id: '',
        newsFlashTitle: '',
        // 快讯标题
        flashSourceName: '',
        // 来源名称
        newsFlashContent: '',
        // 内容
        flashUp: '',
        // 虚拟利好量
        flashDown: '',
        // 虚拟利空量
        cid: 0,
        // 分类
        isShow: true // 状态

      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      articleType: [],
      rules: {
        newsFlashTitle: [{
          required: true,
          message: '请输入快讯标题',
          trigger: 'blur'
        }],
        flashSourceName: [{
          required: true,
          message: '请输入来源名称',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '内容不能为空',
          trigger: 'blur'
        }],
        cid: [{
          required: true,
          message: '请选择分类',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {// this.articleList()
  },
  methods: {
    checklanguage: function checklanguage() {
      this.formModal.cid = '';
      this.articleList();
    },
    articleList: function articleList() {
      var _this = this;

      var par = {
        type: 2,
        language: this.formModal.language
      };
      categoryQueryList(par).then(function (res) {
        _this.articleType = res;

        _this.$forceUpdate();
      }).catch(function () {// this.listLoading = false
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    handleUpdate: function handleUpdate() {
      var _this2 = this;

      newflashQueryUpdateById(this.formModal).then(function (res) {
        _this2.$message.success('修改成功'); // 重置


        _this2.$refs.formModal.resetFields();

        _this2.handleClose();
      }).catch(function (res) {
        _this2.$message.error('修改失败');
      });
    },
    settingEvent: function settingEvent() {
      var _this3 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var categoryIdList = "";

          _this3.formModal.cid.forEach(function (e, index) {
            if (_this3.formModal.cid.length - 1 == index) {
              categoryIdList = categoryIdList + e;
            } else {
              categoryIdList = categoryIdList + e + ',';
            }
          });

          _this3.formModal.cid = categoryIdList;

          if (_this3.formModal.id) {
            _this3.handleUpdate();
          } else {
            newflashQuerySave(_this3.formModal).then(function (res) {
              _this3.$message.success('添加成功'); // 重置


              _this3.$refs.formModal.resetFields();

              _this3.handleClose();
            }).catch(function (res) {
              _this3.$message.error('添加失败');
            });
          }
        }
      });
    }
  }
};