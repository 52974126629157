import request from '@/utils/request'; // 社区版块
// 社区-动态删除

export function communityContentDeleteByIds(data) {
  return request({
    url: '/communityContent/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 社区-动态主键查询

export function communityContentGetById(data) {
  return request({
    url: '/communityContent/auth/getById',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  });
} // 社区-动态列表查询

export function communityContentQueryList(data) {
  return request({
    url: '/communityContent/auth/queryList',
    method: 'post',
    data: data
  });
} // 社区-动态分页列表

export function communityContentQueryPage(data) {
  return request({
    url: '/communityContent/auth/queryPage',
    method: 'post',
    data: data
  });
} // 社区-动态保存

export function communityContentSave(data) {
  return request({
    url: '/communityContent/auth/save',
    method: 'post',
    data: data
  });
} // 社区-动态修改

export function communityContentUpdateById(data) {
  return request({
    url: '/communityContent/auth/updateById',
    method: 'post',
    data: data
  });
} // 社区-动态-评论管理
// 社区-动态-设置精选

export function communityContentCommentAddFeaturedSelection(data) {
  return request({
    url: '/communityContentComment/auth/addFeaturedSelection',
    method: 'post',
    data: data
  });
} // 社区-动态-评论删除

export function communityContentCommentDeleteByIds(data) {
  return request({
    url: '/communityContentComment/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 社区-动态-评论主键查询

export function communityContentCommentGetById(data) {
  return request({
    url: '/communityContentComment/auth/getById',
    method: 'post',
    data: data
  });
} // 社区-动态-评论列表查询

export function communityContentCommentQueryList(data) {
  return request({
    url: '/communityContentComment/auth/queryList',
    method: 'post',
    data: data
  });
} // 社区-动态-评论分页列表

export function communityContentCommentQueryPage(data) {
  return request({
    url: '/communityContentComment/auth/queryPage',
    method: 'post',
    data: data
  });
} // 社区-动态-评论保存

export function communityContentCommentSave(data) {
  return request({
    url: '/communityContentComment/auth/save',
    method: 'post',
    data: data
  });
} // 社区-动态-评论修改

export function communityContentCommentUpdateById(data) {
  return request({
    url: '/communityContentComment/auth/updateById',
    method: 'post',
    data: data
  });
} // 社区-动态-评论管理
// 社区-话题删除

export function communityTopicDeleteByIds(data) {
  return request({
    url: '/communityTopic/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 社区-话题主键查询

export function communityTopicGetById(data) {
  return request({
    url: '/communityTopic/auth/getById',
    method: 'post',
    data: data
  });
} // 社区-话题列表查询

export function communityTopicQueryList(data) {
  return request({
    url: '/communityTopic/auth/queryList',
    method: 'post',
    data: data
  });
} // 社区-话题分页列表

export function communityTopicQueryPage(data) {
  return request({
    url: '/communityTopic/auth/queryPage',
    method: 'post',
    data: data
  });
} // 社区-话题保存

export function communityTopicSave(data) {
  return request({
    url: '/communityTopic/auth/save',
    method: 'post',
    data: data
  });
} // 社区-话题-评论修改

export function communityTopicUpdateById(data) {
  return request({
    url: '/communityTopic/auth/updateById',
    method: 'post',
    data: data
  });
} // 社区-动态-问答管理
// 社区-问答删除

export function communityQuestionDeleteByIds(data) {
  return request({
    url: '/communityQuestion/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 社区-问答主键查询

export function communityQuestionGetById(data) {
  return request({
    url: '/communityQuestion/auth/getById',
    method: 'post',
    data: data
  });
} // 社区-问答列表查询

export function communityQuestionQueryList(data) {
  return request({
    url: '/communityQuestion/auth/queryList',
    method: 'post',
    data: data
  });
} // 社区-问答分页列表

export function communityQuestionQueryPage(data) {
  return request({
    url: '/communityQuestion/auth/queryPage',
    method: 'post',
    data: data
  });
} // 社区-问答保存

export function communityQuestionSave(data) {
  return request({
    url: '/communityQuestion/auth/save',
    method: 'post',
    data: data
  });
} // 社区-问答修改

export function communityQuestionUpdateById(data) {
  return request({
    url: '/communityQuestion/auth/updateById',
    method: 'post',
    data: data
  });
} // 社区-标签
// 社区-标签删除

export function communityTagDeleteByIds(data) {
  return request({
    url: '/communityTag/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 社区-标签主键查询

export function communityTagGetById(data) {
  return request({
    url: '/communityTag/auth/getById',
    method: 'post',
    data: data
  });
} // 社区-标签列表查询

export function communityTagQueryList(data) {
  return request({
    url: '/communityTag/auth/queryList',
    method: 'post',
    data: data
  });
} // 社区-标签分页列表

export function communityTagQueryPage(data) {
  return request({
    url: '/communityTag/auth/queryPage',
    method: 'post',
    data: data
  });
} // 社区-标签保存

export function communityTagSave(data) {
  return request({
    url: '/communityTag/auth/save',
    method: 'post',
    data: data
  });
} // 社区-标签修改

export function communityTagUpdateById(data) {
  return request({
    url: '/communityTag/auth/updateById',
    method: 'post',
    data: data
  });
} // 社区-标签-评论
// 社区-问答-评论删除

export function communityQuestionCommentDeleteByIds(data) {
  return request({
    url: '/communityQuestionComment/auth/deleteByIds',
    method: 'post',
    data: data
  });
} // 社区-问答-评论主键查询

export function communityQuestionCommentGetById(data) {
  return request({
    url: '/communityQuestionComment/auth/getById',
    method: 'post',
    data: data
  });
} // 社区-问答-评论列表查询

export function communityQuestionCommentQueryList(data) {
  return request({
    url: '/communityQuestionComment/auth/queryList',
    method: 'post',
    data: data
  });
} // 社区-问答-评论分页列表

export function communityQuestionCommentQueryPage(data) {
  return request({
    url: '/communityQuestionComment/auth/queryPage',
    method: 'post',
    data: data
  });
} // 社区-问答-评论保存

export function communityQuestionCommentSave(data) {
  return request({
    url: '/communityQuestionComment/auth/save',
    method: 'post',
    data: data
  });
} // 社区-问答-评论修改

export function communityQuestionCommentUpdateById(data) {
  return request({
    url: '/communityQuestionComment/auth/updateById',
    method: 'post',
    data: data
  });
} // 社区-问答-审核修改

export function communityQuestionExamineUpdateById(data) {
  return request({
    url: '/communityContent/auth/examine',
    method: 'post',
    data: data
  });
} // 资讯管理分类列表查询

export function categoryQueryList(data) {
  return request({
    url: '/cms/content/category/auth/category/queryList',
    method: 'post',
    data: data
  });
}