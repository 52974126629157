//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberFollow, addMemberFollow } from '@/api/member/member';
export default {
  name: 'UserFans',
  components: {},
  data: function data() {
    return {
      setting: {
        title: '粉丝列表',
        visible: false
      },
      freezeLoading: false,
      gridData: [],
      Integraldata: {
        memberId: 0,
        page: 1,
        limit: 20,
        total: 0
      },
      dialog: false,
      // memberId: 0
      fans: {
        memberId: 0,
        count: 0
      },
      addloading: false
    };
  },
  mounted: function mounted() {// this.spiderSourceList()
    // this.getFansList()
  },
  methods: {
    toInteger: function toInteger() {// let reg = /^[0-9]+$/
      // if (!reg.test(this.fans.count)) {
      //   this.$message.warning('只能输入整数')
      //   // 用以在dom渲染挂载后重新触发dom渲染挂载
      //   this.$nextTick(() => {
      //     this.fans.count = parseInt(this.fans.count)
      //   })
      // }
    },
    addfans: function addfans() {
      var _this = this;

      this.addloading = true;
      var reg = /^[0-9]+$/;

      if (!reg.test(this.fans.count)) {
        this.$message.warning('只能输入整数');
        return false; // 用以在dom渲染挂载后重新触发dom渲染挂载

        this.$nextTick(function () {
          _this.fans.count = parseInt(_this.fans.count);
        });
      }

      addMemberFollow(this.fans).then(function (res) {
        // this.gridData = res.list
        _this.addloading = false;
        _this.dialog = false;

        _this.getFansList();
      }).catch(function (res) {
        _this.$message.error('新增失败');
      });
    },
    IntegralpageChange: function IntegralpageChange(page) {
      this.Integraldata.page = page;
      console.log(this.Integraldata);
      this.getFansList();
    },
    IntegralSizeChange: function IntegralSizeChange(val) {
      console.log(this.Integraldata);
      this.Integraldata.limit = val;
      this.getFansList();
    },
    getFansList: function getFansList() {
      var _this2 = this;

      memberFollow(this.Integraldata).then(function (res) {
        // 重置
        // this.$refs.formModal.resetFields()
        // console.log(res.list)
        _this2.gridData = res.list;
        _this2.Integraldata.total = res.totalCount;
      }).catch(function (res) {
        _this2.$message.error('查询失败');
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.freezeLoading = false;
      this.setting.visible = false;
    },
    dialoghandleClose: function dialoghandleClose() {
      this.dialog = false;
      this.setting.visible = false;
    }
  }
};