function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

/**
 * @file 对象相关api
 */
import { isEmpty, isDate, isArray, isObject, isJsonObject } from './types';
/**
 * 深克隆（深拷贝）
 * @param {*} value
 * @returns {*} 返回深克隆（深拷贝）后的值
 *
 * @example
 * deepClone(1) // => 1
 * deepClone({a: 1, b: 2}) // => {a: 1, b: 2}
 */

export var deepClone = function deepClone(value) {
  if (isEmpty(value) || _typeof(value) !== 'object') return value;

  if (isDate(value)) {
    return new Date(new Date().setTime(value.getTime()));
  }

  if (isArray(value)) {
    return value.map(function (row) {
      return deepClone(row);
    });
  }

  if (isObject(value)) {
    var copy = {};
    Object.keys(value).forEach(function (attr) {
      if (Object.prototype.hasOwnProperty.call(value, attr)) copy[attr] = deepClone(value[attr]);
    });
    return copy;
  }

  return value;
};
/**
 * 安全的json parse一个object对象
 * 若不是可parse的对象，返回 undefiend
 */

export var safeParseJsonObject = function safeParseJsonObject(val) {
  if (isJsonObject(val)) {
    return JSON.parse(val);
  }

  return undefined;
};