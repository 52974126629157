//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberActivityReward, memberActivityqueryList } from '@/api/activity/activity';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
import { parseTime } from '@/utils';
export default {
  name: 'UserControl',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        activityName: '',
        memberName: '',
        memberId: '',
        activityRewardTitle: '',
        activityRewardAmountType: '',
        startDate: '',
        endDate: '',
        date: '',
        state: '',
        cashState: '' // keywords: ''

      },
      activityIdList: [],
      registerType: [{
        value: 0,
        label: '未领取'
      }, {
        value: 1,
        label: '已领取'
      }],
      cashStateList: [{
        value: 0,
        label: '未提现'
      }, {
        value: 1,
        label: '申请提现'
      }],
      activityReward: [{
        value: 1,
        label: 'usdt'
      }, {
        value: 3,
        label: '积分'
      }, {
        value: 999,
        label: '谢谢参与'
      }],
      gridData: [],
      dialogIntegral: false,
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: '',
      form: {
        memberId: 0,
        amount: 0,
        remark: ''
      },
      status: 0,
      activityRewardTime: ''
    };
  },
  mounted: function mounted() {
    this.spiderSourceList();
    this.getList();
    this.getActivity();
  },
  methods: {
    parseTime: parseTime,
    setTime: function setTime(e) {
      this.tableFrom.startDate = e[0];
      this.tableFrom.endDate = e[1];
    },
    getActivity: function getActivity() {
      var _this = this;

      memberActivityqueryList({}).then(function (res) {
        _this.activityIdList = res;
      }).catch(function () {});
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20,
        activityName: '',
        memberName: '',
        memberId: '',
        activityRewardTitle: '',
        activityRewardAmountType: '',
        startDate: '',
        endDate: '',
        date: '',
        state: '',
        cashState: ''
      };
      this.activityRewardTime = '';
      this.getList();
    },
    getformid: function getformid(id) {
      this.Integraldata.remark = '';
      this.dialogIntegral = true;
      this.Integraldata.accountId = id;
      this.showIntegralList();
    },
    // 列表
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;

      if (this.tableFrom.formatDate) {
        this.tableFrom.formatDate = parseTime(this.tableFrom.formatDate, '{y}-{m}-{d}');
      }

      memberActivityReward(this.tableFrom).then(function (res) {
        _this2.listData.list = res.list;
        _this2.listData.total = res.totalCount;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    spiderSourceList: function spiderSourceList() {
      var _this3 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this3.comeFrom = res;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleFreeze: function handleFreeze(data) {
      console.log(data);
      var par = {
        id: data.memberId
      };
      memberAccountgetById(par).then(function (res) {});
      this.dialogTableVisible = true;
    }
  }
};