//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberActivityqueryPage, memberactivityRewardqueryPage, memberactivityRewardupdateById, memberactivityRewardgetById, memberactivityRewardConditionType, memberactivityRewardsave, memberactivityRewarddeleteByIds } from '@/api/activity/activity';
import { fileImageApi } from '@/api/systemSetting';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
import { parseTime } from '@/utils';
export default {
  name: 'UserControl',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        accountType: '',
        nickname: '',
        memberId: '' // keywords: ''

      },
      conditionTypeIdList: [],
      registerType: [{
        value: 1,
        label: '积分'
      }, {
        value: 2,
        label: 'chatgpt'
      }, {
        value: 3,
        label: '翻倍卡'
      }],
      amountTypeList: [{
        value: 1,
        label: 'USDT'
      }, {
        value: 2,
        label: '内容奖励'
      }, {
        value: 3,
        label: '积分'
      }, {
        value: 999,
        label: '谢谢参与'
      }],
      gridData: [],
      dialogIntegral: false,
      dialogTableVisible: false,
      dialogTableVisible2: false,
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: '',
      form: '',
      Integraldata: {
        page: 1,
        limit: 20,
        total: 0,
        title: '',
        activityId: '',
        amountType: '',
        lotteryCount: 0,
        maxCount: '',
        pic: '',
        popUpFlag: '',
        remark: '',
        weight: ''
      },
      addform: {
        activityId: '',
        amount: '',
        amountType: '',
        circulateFlag: 0,
        conditionTypeId: '',
        conditionValue: '',
        lotteryCount: 0,
        maxCount: '',
        pic: '',
        popUpFlag: 0,
        remark: '',
        sort: 0,
        title: '',
        unit: '',
        weight: 0,
        dayFlag: 0
      },
      status: 0
    };
  },
  mounted: function mounted() {
    this.spiderSourceList();
    this.getList();
    this.getTypeList();
  },
  methods: {
    parseTime: parseTime,
    getTypeList: function getTypeList() {
      var _this = this;

      memberactivityRewardConditionType({}).then(function (res) {
        _this.conditionTypeIdList = res;
      });
    },
    addactivity: function addactivity() {
      this.dialogTableVisible2 = true;
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20,
        accountType: '',
        nickname: '',
        memberId: ''
      };
      this.getList();
    },
    getformid: function getformid(id) {
      this.dialogIntegral = true;
      this.Integraldata.activityId = id;
      this.addform.activityId = id;
      this.showIntegralList();
    },
    showIntegralList: function showIntegralList() {
      var _this2 = this;

      memberactivityRewardqueryPage(this.Integraldata).then(function (res) {
        _this2.gridData = res.list;
        _this2.Integraldata.total = res.totalCount;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    updgridData: function updgridData(data) {
      this.form = JSON.parse(JSON.stringify(data));
      this.dialogTableVisible = true;
    },
    delgridData: function delgridData() {
      var _this3 = this;

      if (this.multipleSelection2.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection2
      };
      memberactivityRewarddeleteByIds(par.ids).then(function (res) {
        _this3.$message.success('删除成功');

        _this3.showIntegralList();
      }).catch(function () {
        _this3.$message.error('删除失败');
      });
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;

      if (this.tableFrom.formatDate) {
        this.tableFrom.formatDate = parseTime(this.tableFrom.formatDate, '{y}-{m}-{d}');
      }

      memberActivityqueryPage(this.tableFrom).then(function (res) {
        _this4.listData.list = res.list;
        _this4.listData.total = res.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection2 = arr;
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    spiderSourceList: function spiderSourceList() {
      var _this5 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this5.comeFrom = res;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleFreeze: function handleFreeze(data) {
      console.log(data);
      var par = {
        id: data.memberId
      };
      this.dialogTableVisible = true;
    },
    addformsave: function addformsave() {
      var _this6 = this;

      memberactivityRewardsave(this.addform).then(function (res) {
        _this6.getformid(_this6.Integraldata.activityId);

        _this6.dialogTableVisible2 = false;
        _this6.addform = {
          activityId: '',
          amount: '',
          amountType: '',
          circulateFlag: 0,
          conditionTypeId: '',
          conditionValue: '',
          lotteryCount: '',
          maxCount: '',
          pic: '',
          popUpFlag: 0,
          remark: '',
          sort: 0,
          title: '',
          unit: '',
          weight: 0,
          dayFlag: 0
        };

        _this6.$message.success('新增成功');
      });
    },
    addIntegralDia: function addIntegralDia() {
      var _this7 = this;

      memberactivityRewardupdateById(this.form).then(function (res) {
        _this7.showIntegralList();

        _this7.dialogTableVisible = false;
      });
    },
    // 上传头像
    handleUploadForm: function handleUploadForm(param) {
      var _this8 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this8.$message.success('上传成功');

        _this8.form.pic = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    // 上传头像
    handleUploadForm2: function handleUploadForm2(param) {
      var _this9 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this9.$message.success('上传成功');

        _this9.addform.pic = res.url;
      }).catch(function (res) {
        loading.close();
      });
    }
  }
};