//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { menuList, menuSave, menuUpdate } from '@/api/menu'; // import { spiderSourceQueryList } from '@/api/crawler/crawler'
// import { forEach } from 'mock/user'

export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    var _this = this;

    return {
      formModal: {
        menuId: '',
        icon: '',
        // icon
        name: '',
        // 菜单名称
        outId: '',
        //
        orderNum: '',
        //
        parentId: '',
        //
        parentName: '',
        // 福可展开列表名称
        perms: '',
        //
        type: '',
        // 类型
        url: '',
        // 路由地址
        routeName: ''
      },
      parentType: [],
      // 父路由菜单
      menuType: [{
        value: '',
        label: ''
      }, {
        value: 0,
        label: '可展开父列表'
      }, {
        value: 1,
        label: '列表'
      }, {
        value: 2,
        label: '按钮类型'
      }],
      rules: {
        nickname: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        header: [{
          required: true,
          message: '请上传头像',
          trigger: 'blur'
        }],
        countryCode: [{
          required: true,
          message: '请选择手机区号',
          trigger: 'change'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (/^1[3-9]\d{9}$/.test(value) || !value) {
              callback();
            } else {
              callback(new Error('请输入有效的手机号码'));
            }
          },
          trigger: 'blur'
        }],
        email: [{
          required: false,
          trigger: 'blur',
          message: '请输入邮箱'
        }, {
          validator: function validator(rule, value, callback) {
            if (!value) {
              if (_this.rules.email[0].required) {
                return callback(new Error('邮箱不能为空'));
              }

              return callback();
            } else {
              var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

              if (reg.test(value)) {
                callback();
              } else {
                return callback(new Error('邮箱格式不正确！'));
              }
            }
          },
          trigger: 'blur'
        }]
      },
      phoneCodes: [{
        value: '86',
        label: '中国大陆'
      }, {
        value: '852',
        label: '中国香港'
      }, {
        value: '853',
        label: '中国澳门'
      }, {
        value: '886',
        label: '中国台湾'
      }, {
        value: '1',
        label: '美国'
      }, {
        value: '44',
        label: '英国'
      }, {
        value: '81',
        label: '日本'
      }, {
        value: '82',
        label: '韩国'
      }, {
        value: '65',
        label: '新加坡'
      }, {
        value: '60',
        label: '马来西亚'
      }, {
        value: '61',
        label: '澳大利亚'
      }, {
        value: '64',
        label: '新西兰'
      }, {
        value: '7',
        label: '俄罗斯'
      }],
      setting: {
        // 弹窗
        title: '添加菜单',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.spiderSourceList();
  },
  methods: {
    getMenuList: function getMenuList() {
      var _this2 = this;

      this.parentType = [];
      menuList({}).then(function (res) {
        // for (let i = 0; i < res.data[0].list.length; i++) {
        //   this.parentType.push({
        //     name: res.data[0].list[i].name, // 菜单名称
        //     menuId: res.data[0].list[i].menuId // 菜单id
        //   })
        // }
        _this2.getMenuListobj(res.data); // res.data[0].list.forEach((e, index) => {
        //   this.parentType.push({
        //     menuId: e.menuId,
        //     name: e.name
        //   })
        //   if (e.list != undefined && e.type == 0) {
        //     e.list.forEach((a, aindex) => {
        //       this.parentType.push({
        //         menuId: a.menuId,
        //         name: a.name
        //       })
        //     })
        //     // this.doObj(e.list)
        //   }
        // })


        _this2.parentType.push({
          name: '一级路由',
          // 菜单名称
          menuId: 0 // 菜单id

        });
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    getMenuListobj: function getMenuListobj(data) {
      var _this3 = this;

      data.forEach(function (e) {
        console.log(e); // console.log(e.type)

        if (e.type != 2) {
          _this3.parentType.push({
            menuId: e.menuId,
            name: e.name
          });
        }

        if (e.list != undefined && e.type != 2) {
          _this3.getMenuListobj(e.list);
        }
      });
    },
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面
      // this.$emit('getButtondio')
      // this.$emit('getList')
    },
    handleParentType: function handleParentType(val) {
      console.log(val, 'val');
    },
    // handleHotFlag() {
    //   this.formModal.isForce = this.formModal.isForce ^ 1
    // },
    handleRegTypeChange: function handleRegTypeChange() {
      // 控制是否必填 mobile email rules
      if (this.formModal.regType === 1) {
        this.rules.mobile[0].required = false;
        this.rules.email[0].required = true;
      } else if (this.formModal.regType === 2) {
        this.rules.mobile[0].required = false;
        this.rules.email[0].required = false;
      } else {
        this.rules.email[0].required = false;
        this.rules.mobile[0].required = true;
      }
    },
    handlePhoneCodeChange: function handlePhoneCodeChange() {
      this.$refs.formModal.validateField('countryCode');
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    handleUpdate: function handleUpdate() {
      var _this4 = this;

      var formModal = JSON.parse(JSON.stringify(this.formModal));
      menuUpdate(formModal).then(function (res) {
        _this4.$message.success('修改成功'); // 重置


        _this4.$refs.formModal.resetFields();

        _this4.handleClose();

        _this4.$parent.getList();

        _this4.$parent.getButtondio();
      }).catch(function (res) {
        _this4.$message.error('修改失败');
      });
    },
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this5 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this5.$message.success('上传成功');

        _this5.formModal.header = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this6 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var formModal = JSON.parse(JSON.stringify(_this6.formModal));

          if (_this6.formModal.menuId) {
            _this6.handleUpdate();
          } else {
            menuSave(formModal).then(function (res) {
              _this6.$message.success('添加成功'); // 重置


              _this6.$refs.formModal.resetFields();

              _this6.handleClose();

              _this6.$parent.getButtondio();

              console.log(res, 'res');
            }).catch(function (res) {
              _this6.$message.error('添加失败');
            });
          }
        }
      });
    },
    spiderSourceList: function spiderSourceList() {// spiderSourceQueryList({ salfFalg: 1 }).then(res => {
      //   this.comeFrom = res
      // }).catch(() => {
      // })
    }
  }
};