//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { communityContentCommentQueryPage, communityContentCommentDeleteByIds, communityContentCommentUpdateById, communityContentGetById } from '@/api/community/community';
import addManagemen from './components/addManagemen';
import { parseTime } from '@/utils';
export default {
  name: 'DynamicMen',
  components: {
    addManagemen: addManagemen
  },
  data: function data() {
    return {
      dnamicSelect: [{
        value: '',
        label: '全部'
      }, {
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '审核通过'
      }, {
        value: 2,
        label: '审核失败'
      }],
      memberVirtualType: [{
        value: '',
        label: '全部'
      }, {
        value: 1,
        label: '是'
      }, {
        value: 0,
        label: '否'
      }],
      value: '',
      input: '',
      tableFrom: {
        page: 1,
        limit: 20,
        parentId: 0,
        memberVirtualFlag: '',
        // 虚拟用户
        nickname: '',
        auditStatus: '' // keywords: ''

      },
      listLoading: false,
      dialogVisible: false,
      dynamic: {
        content: ''
      },
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    checkContent: function checkContent(id) {
      var _this = this;

      var data = {
        id: id
      };
      communityContentGetById(data).then(function (res) {
        _this.dynamic = res;
        _this.dialogVisible = true;
      }).catch(function () {});
    },
    deleteRow: function deleteRow(index, rows) {
      this.$message.error('暂未开发!');
      return; // rows.splice(index, 1)
    },
    lookRow: function lookRow() {
      this.$message.error('暂未开发!');
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.tableFrom.auditStatus = '';
      this.tableFrom.memberVirtualFlag = '';
      this.tableFrom.nickname = '';
      this.getList();
    },
    // table 选中行回调
    handleSelectionChange: function handleSelectionChange(row) {
      var arr = [];
      row.map(function (item) {
        arr.push(item.id);
      });
      this.multipleSelection = arr;
    },
    // 打开弹窗
    addCelebrity: function addCelebrity(parentId, row) {
      // 重置弹窗
      this.$refs.addDynamic.setting.visible = true; // this.$nextTick().then(() => {

      this.$refs.addDynamic.tableFrom.parentId = parentId;
      this.$refs.addDynamic.itemCommtents = row; // })
    },
    handlerDelete: function handlerDelete() {
      var _this2 = this;

      if (this.multipleSelection.length === 0) {
        this.$message.error('选中不能为空!');
        return;
      }

      var par = {
        ids: this.multipleSelection
      };
      communityContentCommentDeleteByIds(par.ids).then(function (res) {
        _this2.$message.success('删除成功');

        _this2.getList();
      });
    },
    handlerStatus: function handlerStatus(rowData) {
      var _this3 = this;

      var status = rowData.auditStatus;
      var prm = {
        id: rowData.id
      };

      if (status === 0) {
        this.$confirm("\u662F\u5426\u5BA1\u6838\u901A\u8FC7", '提示', {
          confirmButtonText: '通过',
          cancelButtonText: '不通过',
          type: 'warning'
        }).then(function () {
          prm.auditStatus = 1;

          _this3.changeAudit(prm);
        }).catch(function () {
          prm.auditStatus = 2;

          _this3.changeAudit(prm);
        });
      } else if (status === 1 || status === 2 || status === 3) {
        prm.auditStatus = 0;
        this.changeAudit(prm);
      }
    },
    changeAudit: function changeAudit(prm) {
      var _this4 = this;

      communityContentCommentUpdateById(prm).then(function (res) {
        _this4.$message.success("\u5BA1\u6838".concat(prm.status === 1 ? '通过' : '不通过'));

        _this4.getList();
      });
    },
    // 列表
    getList: function getList() {
      var _this5 = this;

      this.listLoading = true;
      communityContentCommentQueryPage(this.tableFrom).then(function (res) {
        _this5.listData.list = res.list;
        _this5.listData.total = res.totalCount;
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};