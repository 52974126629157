//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { communityContentCommentSave } from '@/api/community/community';
import addIssuer from './addIssuer'; // import { parseTime } from '@/utils'

export default {
  name: 'AddFamous',
  components: {
    addIssuer: addIssuer
  },
  data: function data() {
    return {
      formModal: {
        id: '',
        // id
        // nickname: '', // 昵称
        // header: '', // 头像
        createBy: '',
        // 发布人id
        content: '',
        // 内容
        communityContentId: '',
        // id
        contentMemberId: '',
        memberId: '',
        // 动态发布人id
        likeNum: '' // 点赞数

      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      rules: {
        createBy: [{
          required: true,
          message: '请输入发布人',
          trigger: 'blur'
        }],
        createTime: [{
          required: true,
          message: '请填写创建时间',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }]
      },
      setting: {
        // 弹窗
        title: '添加评论',
        visible: false
      },
      formDisable: '1',
      // disables
      statue: false,
      // 状态1 新增 2 编辑 3 查看
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      coincelebrityData: {},
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  computed: {
    visible: function visible() {
      return this.setting.visible;
    }
  },
  watch: {
    visible: function visible(newValue, oldValue) {
      if (newValue) {
        console.log(newValue, 'Jinlai'); // this.getList()
      }
    }
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // 弹窗标签
    handleTagClose: function handleTagClose(tag) {
      // this.formModal.label.splice(this.formModal.label.indexOf(tag), 1)
      this.coincelebrityData = {};
      this.formModal.createBy = '';
      this.formModal.id = '';
    },
    showInput: function showInput() {
      this.$refs.addIssuer.setting.visible = true;
      this.$refs.addIssuer.modalState = false;
      this.$refs.addIssuer.setting.title = '选择用户';
      this.$refs.addIssuer.coincelebrityRadio = '';
      this.$refs.addIssuer.getList();
    },
    handleList: function handleList(val) {
      // 弹窗回调
      this.coincelebrityData = val;
      this.formModal.createBy = val.id; // this.formModal.memberName = val.nickname
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    // 弹窗标签结束
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      console.log(formData, param.file, 'formData');
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this.$message.success('上传成功');

        _this.formModal.header = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this2 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var formModal = JSON.parse(JSON.stringify(_this2.formModal)); // formModal.createTime = parseTime(this.formModal.createTime.getTime(), '{y}-{m}-{d}')
          // formModal.createTime = formModal.createTime.getTime()

          communityContentCommentSave(formModal).then(function (res) {
            _this2.$message.success('添加成功'); // 重置


            _this2.$refs.formModal.resetFields();

            _this2.handleClose();
          }).catch(function (res) {
            _this2.$message.error('添加失败');
          });
        }
      });
    }
  }
};