var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入标题名称" },
                        model: {
                          value: _vm.formModal.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "title", $$v)
                          },
                          expression: "formModal.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "来源", prop: "sourceId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formModal.sourceId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "sourceId", $$v)
                            },
                            expression: "formModal.sourceId",
                          },
                        },
                        _vm._l(_vm.comeFrom, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "categoryId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formModal.categoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "categoryId", $$v)
                            },
                            expression: "formModal.categoryId",
                          },
                        },
                        _vm._l(_vm.articleType, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "精品", prop: "boutiqueFlag" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formModal.boutiqueFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "boutiqueFlag", $$v)
                            },
                            expression: "formModal.boutiqueFlag",
                          },
                        },
                        _vm._l(_vm.articleSelect, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "置顶", prop: "topFlag" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formModal.topFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "topFlag", $$v)
                            },
                            expression: "formModal.topFlag",
                          },
                        },
                        _vm._l(_vm.articleSelect, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.handerTpye },
                          model: {
                            value: _vm.formModal.contentType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "contentType", $$v)
                            },
                            expression: "formModal.contentType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { disabled: "", label: 3 } },
                            [_vm._v("视频")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { disabled: "", label: 1 } },
                            [_vm._v("图文结合")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "缩略图", prop: "image" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "http-request": _vm.handleUploadForm,
                            "on-change": _vm.imgSaveToUrl,
                            headers: _vm.myHeaders,
                            "show-file-list": false,
                            multiple: "",
                          },
                        },
                        [
                          _vm.formModal.image
                            ? _c("div", { staticClass: "avatar" }, [
                                _c("img", {
                                  attrs: { src: _vm.formModal.image },
                                }),
                              ])
                            : _c("div", { staticClass: "upload" }, [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                                _c("span", [_vm._v("上传图片")]),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "视频", prop: "video" } },
                    [
                      _c("upload-by-oss", {
                        attrs: {
                          "file-size": 0,
                          limit: 0,
                          "file-u-r-l": _vm.formModal.video,
                          "file-array": [],
                          "file-list": _vm.companyUrlList
                            ? _vm.companyUrlList
                            : [],
                        },
                        on: {
                          handleVideoList: _vm.handleVideoList,
                          handleVideo: _vm.handleVideo,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "简介", prop: "synopsis" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入简介" },
                        model: {
                          value: _vm.formModal.synopsis,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "synopsis", $$v)
                          },
                          expression: "formModal.synopsis",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容", prop: "content" } },
                    [
                      _c("ueditor-from", {
                        attrs: { content: _vm.formModal.content },
                        model: {
                          value: _vm.formModal.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "content", $$v)
                          },
                          expression: "formModal.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "虚拟浏览量", prop: "virtualVisit" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.virtualVisit,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "virtualVisit", $$v)
                          },
                          expression: "formModal.virtualVisit",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }