//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberModifyRecordHistory, memberModifyRecordUpd } from '@/api/member/member_zh';
import { formatDateTime2 } from '@/utils';
export default {
  name: 'UserControl',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        state: '',
        page: 1,
        limit: 20
      },
      registerType: [{
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '审核成功'
      }, {
        value: 2,
        label: '审核不通过'
      }],
      listData: {
        list: [],
        total: 0
      },
      //标签弹窗
      dialogIntegral: false,
      dialogIntegral2: false,
      diaTitle: "标签新增",
      type: '',
      label: {
        id: '',
        state: ''
      },
      stateType: [{
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '审核成功'
      }, {
        value: 2,
        label: '审核不通过'
      }],
      tablehis: ""
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    formatDateTime2: formatDateTime2,
    getlableid: function getlableid(data) {
      this.label = data;
      this.dialogIntegral = true;
    },
    updLabel: function updLabel() {
      var _this = this;

      memberModifyRecordUpd(this.label).then(function (res) {
        _this.dialogIntegral = false;

        _this.$message.success('修改成功');

        _this.getList();
      }).catch(function () {
        _this.$message.error('修改失败');
      });
    },
    showdio: function showdio() {
      this.label = {
        id: '',
        labelName: ''
      };
      this.type = 'add';
      this.dialogIntegral = true;
    },
    getList: function getList() {
      var _this2 = this;

      memberModifyRecordHistory(this.tableFrom).then(function (res) {
        _this2.listData.list = res.list;
        _this2.listData.total = res.totalCount;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleReset: function handleReset() {
      this.tableFrom = {
        page: 1,
        limit: 20,
        state: ''
      };
      this.getList();
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    }
  }
};