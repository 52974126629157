//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { userintegrallist, updateCash as _updateCash, integralFollow } from '@/api/member/member';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
import { parseTime } from '@/utils';
export default {
  name: 'UserControl',
  components: {},
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20,
        accountType: 1,
        billBizTypeId: '',
        nickname: '',
        startDate: '',
        endDate: '' // keywords: ''

      },
      registerType: [{
        value: 1,
        label: '积分'
      }, {
        value: 2,
        label: 'chatgpt'
      }, {
        value: 3,
        label: '翻倍卡'
      }],
      registerType2: [{
        value: 'MEM5001-01',
        label: '签到'
      }, {
        value: 'MEM5001-02',
        label: '人工发放'
      }, {
        value: 'MEM5001-04',
        label: '邀请好友'
      }, {
        value: 'MEM5001-08',
        label: '浏览文章'
      }, {
        value: 'MEM5001-09',
        label: '观看视频'
      }, {
        value: 'MEM5001-10',
        label: '点赞'
      }, {
        value: 'MEM5001-11',
        label: '收藏'
      }, {
        value: 'MEM5001-12',
        label: '评论'
      }, {
        value: 'MEM5001-13',
        label: '发布动态'
      }, {
        value: 'MEM5001-14',
        label: '发布问题'
      }, {
        value: 'MEM5001-15',
        label: '发布视频'
      }, {
        value: 'MEM5001-16',
        label: '注册礼'
      }, {
        value: 'MEM5001-50',
        label: '中奖积分'
      }],
      gridData: [],
      gettimedate: '',
      listLoading: false,
      listData: {
        list: [],
        total: 0
      },
      multipleSelection: [],
      tableSelection: '',
      dialogIntegral: false,
      form: {
        memberId: 0,
        amount: 0,
        remark: ''
      },
      Integraldata: {
        accountId: 0,
        page: 1,
        limit: 20,
        total: 0,
        startDate: '',
        endDate: '',
        // accountType: 1,
        remark: ''
      },
      status: 0,
      activityRewardTime: '',
      RewardTime: ''
    };
  },
  mounted: function mounted() {
    this.spiderSourceList();
    this.getList();
  },
  methods: {
    parseTime: parseTime,
    setTime: function setTime(e) {
      if (e == null) {
        this.Integraldata.startDate = '';
        this.Integraldata.endDate = '';
      } else {
        this.Integraldata.startDate = e[0];
        this.Integraldata.endDate = e[1];
      }
    },
    handlerState: function handlerState() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList();
    },
    handleReset: function handleReset() {
      this.gettimedate = '';
      this.tableFrom = {
        page: 1,
        limit: 20,
        accountType: 1,
        nickname: '',
        memberId: '',
        startDate: '',
        endDate: ''
      };
      this.getList();
    },
    getformid: function getformid(id) {
      this.Integraldata.remark = '';
      this.dialogIntegral = true;
      this.Integraldata.accountId = id;
      this.showIntegralList();
    },
    getintegralFollow: function getintegralFollow() {
      var _this = this;

      integralFollow(this.Integraldata).then(function (res) {
        // this.Integraldata.list = res.list
        _this.Integraldata.total = res.totalCount;
        _this.gridData = res.list;
      }).catch(function () {});
    },
    showIntegralList: function showIntegralList() {
      var _this2 = this;

      integralFollow(this.Integraldata).then(function (res) {
        _this2.gridData = res.list;
        _this2.Integraldata.total = res.totalCount;
        _this2.gridData = res.list;
      }).catch(function () {});
    },
    IntegralSizeChange: function IntegralSizeChange(val) {
      this.Integraldata.limit = val;
      this.showIntegralList();
    },
    IntegralpageChange: function IntegralpageChange(page) {
      this.Integraldata.page = page;
      this.showIntegralList();
    },
    updateCash: function updateCash(row) {
      var _this3 = this;

      var upddata = {
        id: row.memberId,
        cashFlag: row.cashFlag
      };

      _updateCash(upddata).then(function (res) {
        _this3.$message({
          message: '设置成功',
          type: 'success'
        });
      });
    },
    gettime: function gettime() {
      this.tableFrom.startDate = this.gettimedate[0];
      this.tableFrom.endDate = this.gettimedate[1];
      this.Integraldata.startDate = this.gettimedate[0];
      this.Integraldata.endDate = this.gettimedate[1];
      var start = String(this.gettimedate[0]) + ' 00:00:00';
      var end = String(this.gettimedate[1]) + ' 00:00:00';
      this.activityRewardTime = [start, end]; // this.$forceUpdate();
    },
    // 列表
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true; // if (this.tableFrom.formatDate) {
      //   this.tableFrom.formatDate = parseTime(
      //     this.tableFrom.formatDate,
      //     '{y}-{m}-{d}'
      //   )
      // }

      userintegrallist(this.tableFrom).then(function (res) {
        _this4.listData.list = res.list;
        _this4.listData.total = res.totalCount;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.getList();
    },
    spiderSourceList: function spiderSourceList() {
      var _this5 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this5.comeFrom = res;
      }).catch(function () {});
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handleFreeze: function handleFreeze(data) {
      console.log(data);
      var par = {
        id: data.memberId
      };
      memberAccountgetById(par).then(function (res) {});
      this.dialogTableVisible = true;
    }
  }
};