var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modoul" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.setting.title,
            visible: _vm.setting.visible,
            width: "600",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.setting, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formModal",
                  attrs: {
                    model: _vm.formModal,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "昵称", prop: "nickname" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入昵称" },
                        model: {
                          value: _vm.formModal.nickname,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "nickname", $$v)
                          },
                          expression: "formModal.nickname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "注册类型", prop: "regType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.formModal.id ? true : false },
                          on: { change: _vm.handleRegTypeChange },
                          model: {
                            value: _vm.formModal.regType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "regType", $$v)
                            },
                            expression: "formModal.regType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("邮箱注册"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("手机注册"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("苹果注册"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否虚拟用户", prop: "virtualFlag" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.formModal.id ? true : false },
                          on: { change: _vm.handleVirtualFlag },
                          model: {
                            value: _vm.formModal.virtualFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "virtualFlag", $$v)
                            },
                            expression: "formModal.virtualFlag",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            "prefix-icon": "el-icon-mobile",
                            placeholder: "请输入手机号码",
                          },
                          model: {
                            value: _vm.formModal.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "mobile", $$v)
                            },
                            expression: "formModal.mobile",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { slot: "prepend", size: "medium" },
                              on: { change: _vm.handlePhoneCodeChange },
                              slot: "prepend",
                              model: {
                                value: _vm.formModal.countryCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formModal, "countryCode", $$v)
                                },
                                expression: "formModal.countryCode",
                              },
                            },
                            _vm._l(_vm.phoneCodes, function (code) {
                              return _c(
                                "el-option",
                                {
                                  key: code.value,
                                  attrs: {
                                    label: code.label,
                                    value: code.value,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(code.label) +
                                        " " +
                                        _vm._s(code.value)
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邮箱", prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入邮箱" },
                        model: {
                          value: _vm.formModal.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "email", $$v)
                          },
                          expression: "formModal.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头像", prop: "header" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "http-request": _vm.handleUploadForm,
                            "on-change": _vm.imgSaveToUrl,
                            headers: _vm.myHeaders,
                            "show-file-list": false,
                            multiple: "",
                          },
                        },
                        [
                          _vm.formModal.header
                            ? _c("div", { staticClass: "avatar" }, [
                                _c("img", {
                                  attrs: { src: _vm.formModal.header },
                                }),
                              ])
                            : _c("div", { staticClass: "upload" }, [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                                _c("span", [_vm._v("图片地址组合")]),
                              ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "个性签名", prop: "sign" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.sign,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "sign", $$v)
                          },
                          expression: "formModal.sign",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户类型", prop: "memberType" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formModal.memberType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "memberType", $$v)
                            },
                            expression: "formModal.memberType",
                          },
                        },
                        [_vm._v("普通用户")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.formModal.memberType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "memberType", $$v)
                            },
                            expression: "formModal.memberType",
                          },
                        },
                        [_vm._v("官方用户")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.formModal.memberType,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "memberType", $$v)
                            },
                            expression: "formModal.memberType",
                          },
                        },
                        [_vm._v("名人用户")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否热门", prop: "hotEnable" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formModal.hotEnable,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "hotEnable", $$v)
                            },
                            expression: "formModal.hotEnable",
                          },
                        },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.formModal.hotEnable,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "hotEnable", $$v)
                            },
                            expression: "formModal.hotEnable",
                          },
                        },
                        [_vm._v("否")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否白名单", prop: "whiteEnable" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.formModal.whiteEnable,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "whiteEnable", $$v)
                            },
                            expression: "formModal.whiteEnable",
                          },
                        },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.formModal.whiteEnable,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "whiteEnable", $$v)
                            },
                            expression: "formModal.whiteEnable",
                          },
                        },
                        [_vm._v("否")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "来源", prop: "sourceId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formModal.sourceId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "sourceId", $$v)
                            },
                            expression: "formModal.sourceId",
                          },
                        },
                        _vm._l(_vm.comeFrom, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "外部id", prop: "outId" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.formModal.outId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formModal, "outId", $$v)
                          },
                          expression: "formModal.outId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创作者", prop: "hotEnable" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.formModal.creatorFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "creatorFlag", $$v)
                            },
                            expression: "formModal.creatorFlag",
                          },
                        },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.formModal.creatorFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModal, "creatorFlag", $$v)
                            },
                            expression: "formModal.creatorFlag",
                          },
                        },
                        [_vm._v("否")]
                      ),
                    ],
                    1
                  ),
                  _vm.formModal.creatorFlag == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "创作者-权重", prop: "hotEnable" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, label: "创作者排序" },
                            model: {
                              value: _vm.formModal.creatorRank,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModal, "creatorRank", $$v)
                              },
                              expression: "formModal.creatorRank",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formModal.creatorFlag == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "创作者-精选", prop: "hotEnable" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.formModal.creatorSelectedFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formModal,
                                    "creatorSelectedFlag",
                                    $$v
                                  )
                                },
                                expression: "formModal.creatorSelectedFlag",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.formModal.creatorSelectedFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formModal,
                                    "creatorSelectedFlag",
                                    $$v
                                  )
                                },
                                expression: "formModal.creatorSelectedFlag",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formModal.creatorFlag == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "创作者-推荐", prop: "hotEnable" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.formModal.creatorRecommendFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formModal,
                                    "creatorRecommendFlag",
                                    $$v
                                  )
                                },
                                expression: "formModal.creatorRecommendFlag",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.formModal.creatorRecommendFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formModal,
                                    "creatorRecommendFlag",
                                    $$v
                                  )
                                },
                                expression: "formModal.creatorRecommendFlag",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formModal.creatorFlag == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "创作者-文章上限",
                            prop: "hotEnable",
                          },
                        },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, label: "文章上限" },
                            model: {
                              value: _vm.formModal.creatorArticleLimit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formModal,
                                  "creatorArticleLimit",
                                  $$v
                                )
                              },
                              expression: "formModal.creatorArticleLimit",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formModal.creatorFlag == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "创作者-上限周期" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, label: "上限周期" },
                            model: {
                              value: _vm.formModal.creatorArticleLimitDay,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formModal,
                                  "creatorArticleLimitDay",
                                  $$v
                                )
                              },
                              expression: "formModal.creatorArticleLimitDay",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formModal.creatorFlag == 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "创作者-是否免审",
                            prop: "hotEnable",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.formModal.creatorWhiteEnable,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formModal,
                                    "creatorWhiteEnable",
                                    $$v
                                  )
                                },
                                expression: "formModal.creatorWhiteEnable",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.formModal.creatorWhiteEnable,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formModal,
                                    "creatorWhiteEnable",
                                    $$v
                                  )
                                },
                                expression: "formModal.creatorWhiteEnable",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.setting.visible = false
                      _vm.$refs.formModal.resetFields()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingEvent } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }