//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fileImageApi } from '@/api/systemSetting';
import { appVersionQuerySave, appVersionUpdateById } from '@/api/version';
import { spiderSourceQueryList } from '@/api/crawler/crawler';
export default {
  name: 'AddFamous',
  components: {},
  data: function data() {
    var _this = this;

    return {
      formModal: {
        id: '',
        versionCode: '',
        // 昵称
        version: '',
        // 来源
        isForce: '',
        // 外部Id
        description: '',
        // 头像
        downloadUrl: '' // 手机号

      },
      comeFrom: [{
        value: '1',
        label: 'facebook'
      }, {
        value: '2',
        label: 'twitter'
      }, {
        value: '3',
        label: 'weibo'
      }],
      rules: {
        nickname: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        header: [{
          required: true,
          message: '请上传头像',
          trigger: 'blur'
        }],
        countryCode: [{
          required: true,
          message: '请选择手机区号',
          trigger: 'change'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (/^1[3-9]\d{9}$/.test(value) || !value) {
              callback();
            } else {
              callback(new Error('请输入有效的手机号码'));
            }
          },
          trigger: 'blur'
        }],
        email: [{
          required: false,
          trigger: 'blur',
          message: '请输入邮箱'
        }, {
          validator: function validator(rule, value, callback) {
            if (!value) {
              if (_this.rules.email[0].required) {
                return callback(new Error('邮箱不能为空'));
              }

              return callback();
            } else {
              var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;

              if (reg.test(value)) {
                callback();
              } else {
                return callback(new Error('邮箱格式不正确！'));
              }
            }
          },
          trigger: 'blur'
        }]
      },
      phoneCodes: [{
        value: '86',
        label: '中国大陆'
      }, {
        value: '852',
        label: '中国香港'
      }, {
        value: '853',
        label: '中国澳门'
      }, {
        value: '886',
        label: '中国台湾'
      }, {
        value: '1',
        label: '美国'
      }, {
        value: '44',
        label: '英国'
      }, {
        value: '81',
        label: '日本'
      }, {
        value: '82',
        label: '韩国'
      }, {
        value: '65',
        label: '新加坡'
      }, {
        value: '60',
        label: '马来西亚'
      }, {
        value: '61',
        label: '澳大利亚'
      }, {
        value: '64',
        label: '新西兰'
      }, {
        value: '7',
        label: '俄罗斯'
      }],
      setting: {
        // 弹窗
        title: '添加名人',
        visible: false
      },
      listLoading: true,
      // table Loading
      multipleSelection: [],
      // table 选中集
      // tag 标签
      inputVisible: false,
      inputValue: '' // tag 标签结束

    };
  },
  mounted: function mounted() {
    this.spiderSourceList();
  },
  methods: {
    // 关闭弹窗
    handleClose: function handleClose() {
      this.setting.visible = false; // 调用父组件 刷新页面

      this.$emit('getList');
    },
    // handleHotFlag() {
    //   this.formModal.isForce = this.formModal.isForce ^ 1
    // },
    handleRegTypeChange: function handleRegTypeChange() {
      // 控制是否必填 mobile email rules
      if (this.formModal.regType === 1) {
        this.rules.mobile[0].required = false;
        this.rules.email[0].required = true;
      } else if (this.formModal.regType === 2) {
        this.rules.mobile[0].required = false;
        this.rules.email[0].required = false;
      } else {
        this.rules.email[0].required = false;
        this.rules.mobile[0].required = true;
      }
    },
    handlePhoneCodeChange: function handlePhoneCodeChange() {
      this.$refs.formModal.validateField('countryCode');
    },
    handleInputConfirm: function handleInputConfirm() {
      var inputValue = this.inputValue;

      if (inputValue) {
        this.formModal.selfIntroduction.push(inputValue);
      }

      this.inputVisible = false;
      this.inputValue = '';
    },
    handleUpdate: function handleUpdate() {
      var _this2 = this;

      var formModal = JSON.parse(JSON.stringify(this.formModal));
      appVersionUpdateById(formModal).then(function (res) {
        _this2.$message.success('修改成功'); // 重置


        _this2.$refs.formModal.resetFields();

        _this2.handleClose();
      }).catch(function (res) {
        _this2.$message.error('修改失败');
      });
    },
    // 上传头像
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this3 = this;

      var formData = new FormData(); // if (param.file.size / (1024 * 1024).toFixed(2) >= 1) {
      //   this.$message.error('图片最大不能超过1Mb')
      //   return
      // }

      var data = {
        // model: this.modelName ? this.modelName : this.$route.path.split('/')[1],
        // pid: this.tableData.pid
        fileTypeCode: 'img'
      };
      formData.append('file', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileImageApi(formData, data, undefined).then(function (res) {
        loading.close();

        _this3.$message.success('上传成功');

        _this3.formModal.header = res.url;
      }).catch(function (res) {
        loading.close();
      });
    },
    settingEvent: function settingEvent() {
      var _this4 = this;

      this.$refs.formModal.validate(function (valid) {
        if (valid) {
          var formModal = JSON.parse(JSON.stringify(_this4.formModal));

          if (_this4.formModal.id) {
            _this4.handleUpdate();
          } else {
            appVersionQuerySave(formModal).then(function (res) {
              _this4.$message.success('添加成功'); // 重置


              _this4.$refs.formModal.resetFields();

              _this4.handleClose();

              console.log(res, 'res');
            }).catch(function (res) {
              _this4.$message.error('添加失败');
            });
          }
        }
      });
    },
    spiderSourceList: function spiderSourceList() {
      var _this5 = this;

      spiderSourceQueryList({
        salfFalg: 1
      }).then(function (res) {
        _this5.comeFrom = res;
      }).catch(function () {});
    }
  }
};